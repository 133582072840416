<template>

  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"
        v-bind:default_limit="'1000'"
        v-bind:default_sort="'zipcode'"
        v-bind:default_sort_typ="'ASC'"
        v-bind:extension="''"
        v-bind:export_csv="true"

    ></List>

  </div>


</template>

<script>
import List from "@/components/ListDefault.vue";

export default {
    name: "ListParking",
    components: {List},

    created() {


    },

    data () {
      return {
        search: '',
        formview: 'FormParking',
        table: 'parking',
      }
    },


    computed: {
      headers () {
        return [
          { text: 'Strasse', value: 'street' },
          { text: 'PLZ', value: 'zipcode' },
          { text: 'Stadt', value: 'city' },
          { text: 'LNG', value: 'lng' },
          { text: 'LAT', value: 'lat' },
          { text: '', value: 'edit' },
        ]
      },
    },


  methods: {



  },




}
</script>

<style scoped>

</style>
