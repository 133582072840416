<template>
<div>

  <v-dialog
      v-model="dialog"
      height="900px"
      width="800px"
      @click:outside="closeDialog"

  >

    <v-card
        style="
      height: 900px;
      width: 800px;
      "

    >

      <v-app-bar
          flat
          color="pink"
      >

        <v-toolbar-title class="text-h6 white--text pl-0">
          Kunden Auswahl
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
            color="white"
            icon
            @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

<!--
      <v-card-title class="text-h5 white--text pink">
        Kunden Auswahl
      </v-card-title>
-->

      <!--
      <v-card-text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </v-card-text>
      -->


      <v-data-table
          :headers="headers"
          :items="data"
          item-key="id"
          class="elevation-0"
          :search="search"
          :custom-filter="filterOnlyCapsText"

          :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'pro Seite'
      }"


      >

        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Filter"
              class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.select="{item}">

          <v-row>
            <v-icon color="pink" @click="selectEntry(item.id)">mdi-checkbox-marked-outline</v-icon> <!--{{item.calories}}-->
          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>
        <!--

        <template v-slot:body.append>
          <tr>
            <td></td>
            <td>
              <v-text-field
                  v-model="calories"
                  type="number"
                  label="Weniger als"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>

        -->
      </v-data-table>




    </v-card>


  </v-dialog>

</div>
</template>

<script>


export default {
  name: "SelectCustomer",

  data () {
    return {

      dialog: true,
      search: '',

      data: [],

    }
  },


  created() {

    this.loadData()

  },


  methods:{

    closeDialog(){
      this.$emit('SelectCustomerReset')
    },

    selectEntry(id){

      console.log(id)

      this.$emit('SelectedCustomer', id)
      this.$emit('SelectCustomerReset')

    },


    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.data = result



    },


    filterOnlyCapsText (value, search) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
    },


  },



  computed: {
    headers () {
      return [
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Strasse', value: 'street' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Ort', value: 'city' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: '', value: 'select' },
      ]
    },
  },


}
</script>

<style scoped>

</style>
