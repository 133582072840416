<template>
  <v-card
      :elevation="elevation"
      style="
      margin-top: 40px;
      width: 750px"
  >
<v-card-title>{{ title }}


</v-card-title>
  <v-simple-table>
    <template v-slot:default>

      <thead>
      <tr>
        <th
            v-for="value in values"
            :key="value.name"
            class="text-left">
          {{value.name}}
        </th>
        <th class="text-left">

        </th>
      </tr>

      <tr>
        <th

            v-for="value in values"
            :key="value.name"

            class="text-left">

          <v-text-field
              v-if='value.typ == "text" && value.name == "Stückzahl"'
              type="number"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              autocomplete="false"
              :key="componentKey"
              v-model="form.parent_id[value.value]"
              outlined
          ></v-text-field>

          <v-text-field
              v-if='value.typ == "text" && value.name != "Stückzahl"'
              type="search"
              autocomplete="false"
              :key="componentKey"
              v-model="form.parent_id[value.value]"
              outlined
          ></v-text-field>

          <CustomSelect
              v-if='value.typ == "select"'
              v-bind:table="value.select_table"
              v-bind:field_text="value.select_text"
              v-bind:field_value="value.select_value"
              v-bind:label="value.select_label"
              v-bind:selected="form.parent_id[value.value]"
              :key="componentKey"
              v-model="form.parent_id[value.value]"
          >

          </CustomSelect>
        </th>

        <th class="text-left">
          <v-btn
              v-if="id == ''"
              color="pink"
              fab
              dark
              small
              @click="addEntry()"

          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-btn
              v-if="id != ''"
              color="pink"
              fab
              dark
              small
              @click="addEntry()"

          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn
              v-if="id != ''"
              color="pink"
              fab
              dark
              small
              @click="editEntryReset()"

          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

        </th>
      </tr>

      </thead>

      <tbody

          v-if="data.length > 0">

      <tr
          v-for="item in data"
          :key="item.id"
      >
        <td
            v-for="value in values"
            :key="value.name"

            style="max-width: 350px;"
        >
          <p v-if="item[value.text] != '' && item[value.text] != undefined">{{ item[value.text] }}</p>
          <p v-else>{{ item[value.value] }}</p>
          </td>


        <td>
          <div
              style="
              display: inline;
              white-space: nowrap;"
          >

          <v-icon

        @click="deleteData(item.id)"
        color="pink"
        style="padding:3px;"
        >mdi-delete-outline</v-icon>

          <v-icon style="padding: 3px;" v-if="editable == true" @click="editEntry(item.id)">mdi-pencil-outline</v-icon> <!--{{item.calories}}-->

          </div>
        </td>

      </tr>

      </tbody>





    </template>
  </v-simple-table>

  </v-card>
</template>

<script>



import CustomSelect from "@/components/CustomSelect";

export default {
  name: "GroupList",

  components:{
    CustomSelect,
  },

  data () {
    return {

      data: [],

      form: {
        parent_id: []
      },

      componentKey: '0',

      id: '',



    }
  },


  created() {

    console.log(this.table)
    console.log(this.data.length)

    this.loadData()

  },


  methods:{


    addEntry(){
      console.log("addEntry")
      this.saveData()
    },



    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      if(this.collecting_id != ""){
        param["where"] = "true"
        param["selector"] = "collecting_id"
        param["value"] = this.collecting_id
      }else{
          return
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log("result")

      this.data = []

      this.$nextTick(() => {
        this.data = result
      });

    },

    async saveData(){

      this.componentKey += 1

      var statement


      if (this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {}

        for (var i1 = 0; i1 < this.values.length; i1++) {

          if(this.values[i1].typ == "text"){

            if(this.form.parent_id[this.values[i1].value] == undefined){
              this.form.parent_id[this.values[i1].value] = ""
            }

            if (this.table == "group_tour"){
              data[this.values[i1].value] = this.form.parent_id[this.values[i1].value]
            }else{
              data[this.values[i1].value] = this.form.parent_id[this.values[i1].value]
            }


            if(this.values[i1].required == true && this.form.parent_id[this.values[i1].value] == ""){
              alert("Bitte " + this.values[i1].name + " eingeben!")
              return
            }

          }

          if(this.values[i1].typ == "select"){

            var test =  this.form.parent_id[this.values[i1].value].split(";")

            data[this.values[i1].value] = test[1]
            data[this.values[i1].text] = test[0]

            console.log("test[1]")
            console.log(test[1])

            if(this.values[i1].required == true && test[1] == ""){
              alert("Bitte " + this.values[i1].name + " eingeben!")
              return
            }

          }


          this.form.parent_id[this.values[i1].value] = ""
        }

        data["collecting_id"] = this.collecting_id

        console.log(data)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.id = ""

      this.loadData()


    },


    async editEntryReset(){

      this.id = ""

      for (var i2 = 0; i2 < this.values.length; i2++) {
        this.form.parent_id[this.values[i2].value] = ""
      }

      this.$nextTick(() => {

      });


      this.componentKey++

    },

    async editEntry(id){

    console.log("editEntry")
    console.log(id)


    this.id = id

      console.log("this.table")
      console.log(this.table)


      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log("result")
      console.log(result)




      for (var i2 = 0; i2 < this.values.length; i2++) {
        console.log("Value: " + this.values[i2].value)
        console.log("Wert: " + result[0][this.values[i2].value])

        this.form.parent_id[this.values[i2].value] = result[0][this.values[i2].value]
      }

      this.$nextTick(() => {

      });


      this.componentKey++


    },

    async deleteData(id){

      if (confirm('Wirklich löschen?')) {
        // Save it!
        console.log('OK');
      } else {
        return
      }



      // Einstellungen
      var param = {
        table: this.table,
        statement: "delete",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.loadData()


    },


  },


  props:{

    title: {
      type: String,
      default: 'Title'
    },

    table: String,

    editable: {
      type: Boolean,
      default: false
    },

    onlynumber: {
      type: Boolean,
      default: false,
    },

    collecting_id: {
      type: String,
      default: "",
    },

    group_type_id: {
      type: String,
      default: "",
    },

    values: [],

    elevation:{
      type: String,
      default: "2",
    }


  }


}
</script>

<style scoped>

</style>
