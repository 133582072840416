<template>

<div>

<List
    v-bind:headers="headers"
    v-bind:formview="formview"
    v-bind:table="table"
    v-bind:default_limit="'1000'"
    v-bind:default_sort="'company'"
    v-bind:default_sort_typ="'ASC'"
    v-bind:extension="''"
    v-bind:export_csv="true"

></List>

</div>

</template>

<script>

import List from '@/components/ListDefault'


export default {
  name: "ListCustomer",

  components: {
    List,
  },

  created() {



  },

  data () {
    return {
      search: '',
      formview: 'FormCustomer',
      table: 'customer',
    }
  },


  computed: {
    headers () {
      return [
        { text: 'Kundengruppe', value: 'group_main_text' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Strasse', value: 'street' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Ort', value: 'city' },
        { text: 'Telefon', value: 'phone' },
        { text: '', value: 'edit' },
      ]
    },
  },


  methods: {



  },


}
</script>

<style scoped>

</style>
