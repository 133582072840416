<template>
<div>

    <v-layout column style="
    position: relative;
    top: 30px;
    left: 20px;
    width: 700px;
    height: 350px">
        <v-flex md6 style="overflow: auto">

            SOLL: <b>{{total_soll}}</b> IST: <b>{{total_ist}}</b>

            <table>
                <thead>
                <tr
                style="background: lightgray"
                >
                    <th
                        style="width: 150px;"
                        class="text-center">
                        NAME
                    </th>
                    <th
                        style="width: 150px;"
                        class="text-center">
                        SOLL
                    </th>

                    <th
                        style="width: 150px;"
                        class="text-center">
                        IST VORHER
                    </th>

                    <th
                        style="width: 150px;"
                        class="text-center">
                        IST
                    </th>

                    <th
                        style="width: 150px;"
                        class="text-center">
                        MITARBEITER
                    </th>

                    <th
                        style="width: 150px;"
                        class="text-center">
                        LETZTE ÄNDER.
                    </th>

                    <th
                        style="width: 150px;"
                        class="text-center">
                        BEMERKUNG
                    </th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-for="item in data_items"
                    :key="item.id"

                    :style="item.background_color"

                >
                    <td
                        class="text-center"
                    >{{ item.name }}</td>
                    <td
                        class="text-center"
                    >{{ item.qty_soll }}</td>
                    <td
                        class="text-center"
                    >{{ item.qty_ist_before }}</td>
                    <td
                        class="text-center"
                    >{{ item.qty_ist }}</td>
                    <td
                        class="text-center"
                    >{{ item.worker_edit }}</td>
                    <td
                        class="text-center"
                    >{{ item.date }}</td>
                    <td
                        class="text-center"
                    >{{ item.note }}</td>
                </tr>

                </tbody>
            </table>

        </v-flex>
    </v-layout>


    <div
        style="

        position: absolute;
        left: 60px;
        bottom: 55px;
        "
    ><b>Status</b></div>
    <c-progress

        style="

        position: absolute;
        left: 60px;
        bottom: 20px;

        width: 60%;"
        :progress="progress_in_percent"></c-progress>

</div>

</template>

<script>
import CProgress from "@/components/cProgress.vue";

export default {
    name: "CardCollectingItems",
    components: {CProgress},

    data () {
        return {
        name: '',
        data_items: [],
        progress_in_percent: 0,

            total_soll: 0,
            total_ist: 0,

        }


    },

    created() {
        this.loadData()
    },


    methods:{

        async loadData(){

            // Einstellungen
            var param = {
                table: "collecting",
                statement: "select",
                type: "detail",
                where: "true",
                selector: "id",
                value: this.collecting_id,
                short: "true", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            this.name = result[0]["name"]



            // Einstellungen
             param = {
                table: "collecting_items",
                statement: "select",
                type: "detail",
                where: "true",
                selector: "collecting_id",
                value: this.collecting_id,
                short: "true", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "ORDER BY name ASC",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
             data = {
            }

            result = await this.$func.queryAPI(param, data)
            console.log(result)

            var background_color
            var total_soll = 0
            var total_ist = 0

            for (var i1 = 0; i1 < result.length; i1++) {

                if(Number(result[i1]["qty_ist"]) >= Number(result[i1]["qty_soll"])){

                    background_color = "background: green;"
                }else{
                    background_color = "background: white;"
                    // background_color = "background: red;"
                }

                total_soll = total_soll + Number(result[i1]["qty_soll"])
                total_ist = total_ist + Number(result[i1]["qty_ist"])


                this.data_items.push({id: result[i1]["id"], name: result[i1]["name"], qty_soll: result[i1]["qty_soll"], qty_ist: result[i1]["qty_ist"], qty_ist_before: result[i1]["qty_ist_before"], date: this.$moment(result[i1]["date_timestamp"]).format("DD.MM.YYYY"), worker_edit: result[i1]["worker_edit"], note: result[i1]["note"], background_color: background_color})
            }


            this.data_items.sort((a, b) => {
              const nameA = a.name;
              const nameB = b.name;

              const numA = nameA.includes('-') ? nameA.split('-').map(Number) : [parseInt(nameA, 10)];
              const numB = nameB.includes('-') ? nameB.split('-').map(Number) : [parseInt(nameB, 10)];

              const maxLength = Math.max(numA.length, numB.length);

              for (let i = maxLength - 1; i >= 0; i--) {
                const valA = numA[i] || 0;
                const valB = numB[i] || 0;

                if (valA !== valB) {
                  return valA - valB;
                }
              }

              return 0;
            });



          this.total_soll = total_soll
            this.total_ist = total_ist

            var prozent

            prozent = (total_ist * 100) / total_soll
            this.progress_in_percent = prozent

            console.log(this.data_items)

        }

    },


    props:{

      collecting_id: String,

    }


}
</script>

<style scoped>

</style>
