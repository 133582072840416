import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../views/Dashboard.vue'
import Analytics from '../views/Analytics.vue'

import FormCustomer from "../views/FormCustomer.vue";
import FormDisposal from "../views/FormDisposal.vue";
import FormDriver from "../views/FormDriver.vue";
import FormTask from "../views/FormTask.vue";
import FormRepeatTask from "../views/FormRepeatTask.vue";
import FormTour from "../views/FormTour.vue";
import FormStock from "../views/FormStock.vue";
import FormTransport from "../views/FormTransport.vue";
import FormClearing from "../views/FormClearing.vue";
import FormCollecting from "../views/FormCollecting.vue";
import FormParking from "../views/FormParking.vue";
import FormExchange from "../views/FormExchange.vue";

import ListCustomer from "../views/ListCustomer.vue";
import ListDisposal from "../views/ListDisposal.vue";
import ListDriver from "../views/ListDriver.vue";
import ListRepeatTask from "../views/ListRepeatTask.vue";
import ListTask from "../views/ListTask.vue";
import ListTour from "../views/ListTour.vue";
import ListClearing from "../views/ListClearing.vue";
import ListStock from "../views/ListStock.vue";
import ListTransport from "../views/ListTransport.vue";
import ListCheck from "../views/ListCheck";
import ListCheckGroup from "../views/ListCheckGroup";
import ListExchange from "../views/ListExchange";
import ListCollecting from "../views/ListCollecting";
import ListStockQty from "../views/ListStockQty";
import ListParking from "../views/ListParking.vue";

import Login from "../views/Login.vue";
import Setting from "../views/Setting.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },

  { path: '/Dashboard',  name: 'Dashboard', component: Dashboard },
  { path: '/Analytics',  name: 'Analytics', component: Analytics },
  { path: '/FormCustomer/:id',  name: 'FormCustomer', component: FormCustomer },
  { path: '/FormCustomer',  name: 'FormCustomer', component: FormCustomer },
  { path: '/FormDisposal/:id',  name: 'FormDisposal', component: FormDisposal },
  { path: '/FormDisposal',  name: 'FormDisposal', component: FormDisposal },
  { path: '/FormTransport/:id',  name: 'FormTransport', component: FormTransport },
  { path: '/FormTransport',  name: 'FormTransport', component: FormTransport },
  { path: '/FormDriver/:id',  name: 'FormDriver', component: FormDriver },
  { path: '/FormDriver',  name: 'FormDriver', component: FormDriver },
  { path: '/FormTask/:id',  name: 'FormTask', component: FormTask },
  { path: '/FormTask',  name: 'FormTask', component: FormTask },
  { path: '/FormRepeatTask/:id',  name: 'FormRepeatTask', component: FormRepeatTask },
  { path: '/FormRepeatTask',  name: 'FormRepeatTask', component: FormRepeatTask },
  { path: '/ListExchange',  name: 'ListExchange', component: ListExchange },
  { path: '/ListCollecting',  name: 'ListCollecting', component: ListCollecting },
  { path: '/ListStockQty',  name: 'ListStockQty', component: ListStockQty },
  { path: '/ListParking',  name: 'ListParking', component: ListParking },


  { path: '/FormTour/:id',  name: 'FormTour', component: FormTour },
  { path: '/FormTour',  name: 'FormTour', component: FormTour },
  { path: '/FormStock/:id',  name: 'FormStock', component: FormStock },
  { path: '/FormStock',  name: 'FormStock', component: FormStock },
  { path: '/FormClearing/:id',  name: 'FormClearing', component: FormClearing },
  { path: '/FormCollecting/',  name: 'FormCollecting', component: FormCollecting },
  { path: '/FormCollecting/:id',  name: 'FormCollecting', component: FormCollecting },

  { path: '/FormParking/',  name: 'FormParking', component: FormParking },
  { path: '/FormParking/:id',  name: 'FormParking', component: FormParking },

  { path: '/FormExchange/',  name: 'FormExchange', component: FormExchange },
  { path: '/FormExchange/:id',  name: 'FormExchange', component: FormExchange },

  { path: '/ListCustomer', name: 'ListCustomer', component: ListCustomer },
  { path: '/ListDisposal', name: 'ListDisposal', component: ListDisposal },
  { path: '/ListDriver', name: 'ListDriver', component: ListDriver },
  { path: '/ListRepeatTask', name: 'ListRepeatTask', component: ListRepeatTask },
  { path: '/ListTask', name: 'ListTask', component: ListTask },
  { path: '/ListTour', name: 'ListTour', component: ListTour },
  { path: '/ListClearing', name: 'ListClearing', component: ListClearing },
  { path: '/ListStock', name: 'ListStock', component: ListStock },
  { path: '/ListTransport', name: 'ListTransport', component: ListTransport },
  { path: '/ListCheck/:id', name: 'ListCheck', component: ListCheck },
  { path: '/ListCheck', name: 'ListCheck', component: ListCheck },
  { path: '/ListCheckGroup/:id', name: 'ListCheckGroup', component: ListCheckGroup },

  { path: '/Login', name: 'Login', component: Login },
  { path: '/Setting', name: 'Setting', component: Setting },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
