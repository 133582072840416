<template>

  <v-layout

  >


    <DialogMap
        v-if="map == true"
        v-bind:lat="Number(lat)"
        v-bind:lng="Number(lng)"
        @reset="openMapReset"
    >

    </DialogMap>


    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >


        <v-text-field
            v-model="street"
            sm="12"
            md="4"
            label="Strasse"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="zipcode"
            sm="12"
            md="4"
            label="PLZ"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="city"
            sm="12"
            md="4"
            label="Ort"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>




        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>






        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>

        <v-text-field
            v-model="lat"
            sm="12"
            md="4"
            label="lat (Geographische Breite)"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 300px;"

        ></v-text-field>


        <v-text-field
            v-model="lng"
            sm="12"
            md="4"
            label="lng (Geographische Länge)"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 300px;"

        ></v-text-field>


        <v-btn
            @click="openMap"
            style="margin-right: 10px;"
        ><v-icon>mdi-map</v-icon></v-btn>

        <v-btn
            @click="getGeoData"
        ><v-icon>mdi-map-marker-question-outline</v-icon></v-btn>

        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>





        <div
            style="padding: 10px;"
        ></div>




        <!--
        </v-col>
        -->

        <v-row
            style="padding: 10px"
        >

          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin-right: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>

        <div
            style="padding: 10px;"
        ></div>


      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>

</template>

<script>

import CustomSelect from '../components/CustomSelect'
import DialogMap from "@/components/DialogMap";

import Vue from "vue";


export default {
  name: "FormCustomer",

  components:{
    CustomSelect,
    DialogMap,
  },

  data () {
    return {

      title: 'Sammelstelle',
      mainview: 'ListParking',
      table: 'parking',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 2500,

      map: false,


      id: '',
      street: '',
      zipcode: '',
      city: '',
      note: '',
      lat: '',
      lng: '',

    }
  },

  created() {

    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },


  methods:{


    openMap(){
      console.log("openMap")

      if(this.lat == "" || this.lng == ""){
        this.snackbar_title = "Bitte Geo Daten eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      this.map = true

    },

    openMapReset(){
      this.map = false
    },

    getGeoData(){

      if(this.street == ""){
        this.snackbar_title = "Bitte Adresse eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.city == ""){
        this.snackbar_title = "Bitte Stadt eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.zipcode == ""){
        this.snackbar_title = "Bitte PLZ eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }


      Vue.$geocoder.setDefaultMode('address');      // this is default
      var addressObj = {
        address_line_1: this.street,
        address_line_2: '',
        city:           this.city,
        state:          '',                      // province also valid
        zip_code:       this.zipcode,            // postal_code also valid
        country:        'Deutschland'
      }
      Vue.$geocoder.send(addressObj, response => {

        console.log(response)


        if(response["results"][0]["formatted_address"] == "Deutschland"){
          this.snackbar_title = "Adresse nicht gefunden! Bitte Adresse prüfen!"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }

        if(response["results"][0]["formatted_address"].toString().includes(this.zipcode) == false){
          this.snackbar_title = "Adresse fehlerhaft! Bitte Adresse prüfen!"
          this.snackbar_color = "red"
          this.snackbar = true

          return
        }

        /*
        if(response["results"][0]["address_components"][7] != this.zipcode){
          this.snackbar_title = "Adresse fehlerhaft! Bitte Adresse prüfen!"
          this.snackbar_color = "red"
          this.snackbar = true

          return
        }
        */

        this.lat = response["results"][0]["geometry"]["location"]["lat"]
        this.lng = response["results"][0]["geometry"]["location"]["lng"]

        // geometry


        console.log("lat: " + response["results"][0]["geometry"]["location"]["lat"])
        console.log("lng: " + response["results"][0]["geometry"]["location"]["lng"])
        console.log("formatted_address: " + response["results"][0]["formatted_address"])


        this.saveData()

      });

    },



    btnCancel(){

      this.$router.push({ name: this.mainview })

    },

    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.note = result[0]["note"]
      this.lat = result[0]["lat"]
      this.lng = result[0]["lng"]

    },

    async saveData(){

      if(this.street === ""){
        this.snackbar_title = "Bitte Strasse eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.zipcode === ""){
        this.snackbar_title = "Bitte PLZ eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.city === ""){
        this.snackbar_title = "Bitte Stadt eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }



      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {

        street: this.street,
        zipcode: this.zipcode,
        city: this.city,
        note: this.note,
        lat: this.lat,
        lng: this.lng,

      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true


      if(statement == "insert"){
        this.id = result["value"]
      }


    }




  },


  watch:{



  },




}
</script>

<style scoped>

</style>
