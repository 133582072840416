<template>
  <div
      style="
      width: 100%"
  >
    <div><h2>{{title}}</h2></div>
    <div>{{title2}}</div>

    <v-btn
    style="
    margin-top: 20px;
    "

    @click="customer = []"
    ><v-icon>mdi-delete</v-icon> RESET</v-btn>

    <v-btn
        style="
        margin-left: 20px;
    margin-top: 20px;
    "

        @click="updateAllCustomer()"
    ><v-icon>mdi-calculator</v-icon> REIFEN QUALITÄT NEU BERECHNEN (ALLE KUNDEN)</v-btn>



    <div
        style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
    >

      <DialogDate
          v-bind:label="'Von'"
          v-bind:initial_date="date_from"
          v-bind:clearable="false"
          v-model="date_from"
      ></DialogDate>

      <DialogDate
          v-bind:label="'Bis'"
          v-bind:initial_date="date_to"
          v-bind:clearable="false"
          v-model="date_to"
      ></DialogDate>

    </div>



    <v-expansion-panels

        v-model="panel"
        multiple
    >


    <v-expansion-panel

    style="

    margin-top: 30px"
    >
      <v-expansion-panel-header>Kunden auswählen</v-expansion-panel-header>
      <v-expansion-panel-content>
        <List
            style="
            margin-bottom: 30px;
        "

            v-bind:headers="headers"
            v-bind:formview="''"
            v-bind:height="'500px'"
            v-bind:table="'customer'"
            v-bind:default_limit="'1000'"
            v-bind:default_sort="'company'"
            v-bind:default_sort_typ="'ASC'"
            v-bind:addbutton="false"
            v-bind:extension="''"

            @add="add_customer"


        ></List>

      </v-expansion-panel-content>
    </v-expansion-panel>




    </v-expansion-panels>








    <v-overlay
        :value="overlay"
    >


      <v-progress-circular
          indeterminate
          color="black"
          style="margin: 20px;"
      ></v-progress-circular>


      Daten werden geladen...


    </v-overlay>


    <div
        v-if="renderComponent"
        style="

        margin-top: 50px;
        width: 100%;

"
    >


<CardAnalyticsCustomer
    v-for="item in customer"
    :key="item.id"
    v-bind:customer_id="item.id"
    v-bind:date_from="date_from"
    v-bind:date_to="date_to"

></CardAnalyticsCustomer>


    </div>



    <v-snackbar
        color="pink"
        v-model="snackbar"
        :timeout="2000"
    >
      Kunde wird bereits angezeigt!

    </v-snackbar>



</div>
</template>

<script>


import List from "@/components/ListDefault";
import DialogDate from "@/components/DialogDate";
import CardAnalyticsCustomer from "@/components/CardAnalyticsCustomer";

export default {
  name: "AnalyticsCustomer",

  components:{
    List,
    DialogDate,
    CardAnalyticsCustomer,
  },

  data() {
    return {

      title: "Kunden Info",
      title2: "Anzahl der Abgeholten Reifen, Leerfahrten, Qualität",

      chartData: Object,

      sync: true,
      snackbar: false,

      customer: [],
      renderComponent: true,
      overlay: false,

      default_range_in_days: 350,

      panel: [0],

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },


  async created(){
    this.sync = true


    this.overlay = true

    this.customer = []

    console.log("loadData created: " + this.sync)

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")


    await this.loadData()


    this.overlay = false

    this.sync = false
  },


  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.loadData()
    },




  },


  methods:{


    async updateAllCustomer(){

      this.overlay = true

      // Einstellungen
      var param1 = {
        table: "customer",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

      for (var i99 = 0; i99 < result1.length; i99++) {

        await this.getRating(result1[i99]["id"])

      }

      this.overlay = false

    },


    async getRating(id){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT SUM(rating * pickup_qty) / SUM(pickup_qty) AS gewichteter_durchschnitt FROM task_items WHERE customer_id='" + id + "' AND (wasteno='160103' AND pickup_qty IS NOT NULL) AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("ID:" + id)
      console.log(param1.extension)
      console.log("result1")
      console.log(result1)

      var rating = "???"
      var rating_in_percent = "0"

      if(result1.length != 0){
        // rating = Number(result1[0]["AVG(rating)"]).toFixed(2)
        rating = Number(result1[0]["gewichteter_durchschnitt"]).toFixed(2)

        console.log("gewichteter_durchschnitt: " + rating)
      }



      // Einstellungen
      var param3 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {}

      var result3 = await this.$func.queryAPI(param3, data3)

      console.log("result3")
      console.log(result3)


      switch (Math.round(rating)) {
        case 1:
          rating_in_percent = result3[0]["wert"]
          break;
        case 2:
          rating_in_percent = result3[1]["wert"]
          break;
        case 3:
          rating_in_percent = result3[2]["wert"]
          break;
        case 4:
          rating_in_percent = result3[3]["wert"]
          break;
        case 5:
          rating_in_percent = result3[4]["wert"]
          break;
        default:
          rating_in_percent = "0"
      }





      // Einstellungen
      var param2 = {
        table: "customer",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
        rating: rating,
        rating_in_percent: rating_in_percent,
      }

      var result2 = await this.$func.queryAPI(param2, data2)

      console.log("result2")
      console.log(result2)



    },







    async loadData(){


      this.overlay = true


      // await this.updateAllCustomer()


      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;


      this.overlay = false


    },

    async add_customer(n){
      var hasMatch =false;

      for (var index = 0; index < this.customer.length; ++index) {

        var check = this.customer[index];

        if(check.id == n){
          hasMatch = true;
          break;
        }
      }

      if(hasMatch == false){
        this.customer.push({id: n})
      }else{
        this.snackbar = true
      }


    }



  },




  computed: {
    headers () {
      return [
        { text: 'Kundengruppe', value: 'group_main_text' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Strasse', value: 'street' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Ort', value: 'city' },
        { text: '∅ Gutreifenanteil in %', value: 'rating_in_percent' },
        { text: '', value: 'select' },
      ]
    },
  },




}
</script>

<style scoped>

</style>
