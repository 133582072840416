<template>
<div
>
  <!--:hint="`${select.text} ${select.value}`"-->
  <v-combobox
      v-if="renderComponent"


      outlined
      v-model="select"

      :items="items"
      item-text="text"
      :label="label"
      persistent-hint
      return-object
      clearable

      @input="handleInput"

  ></v-combobox>




</div>
</template>

<script>
export default {
  name: "CustomCombobox",

  data () {
    return {
      items: [],
      select: '',

      renderComponent: true,

      content: this.value
    }
  },

  async created() {


    console.log("this.select: " + this.select)


    this.$emit('input', this.select)

  },

  watch:{

    select: function () {

      this.$emit('input', this.select)

    },

    selected: function (){

      this.select = this.selected

    }


  },



  methods:{

    handleInput () {
      console.log("handleInput")
      console.log(this.select)


      this.$emit('input', this.select)


    },


    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      var data = {}
      var result = await this.$func.queryAPI(param, data)

      console.log(result)
      console.log("this.field_text: " + this.field_text)

      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {
        this.items[i1] = result[i1][this.field_text]
      }

      console.log("this.items: " + this.items)


      // this.select = this.selected

    },

  },


  async mounted() {


    await this.loadData()


    // Remove MyComponent from the DOM
    this.renderComponent = false;


    // Wait for the change to get flushed to the DOM
    await this.$nextTick();

    // Add the component back in
    this.renderComponent = true;



    this.select = this.selected

  },


  props:{

    value: String,

    table: String,

    label: {
      type: String,
      default: 'Bitte auswählen!'
    },

    selected: {
      type: String,
      default: ''
    },


    field_text: String,
    field_value: String,


  },


  model: {
    prop: 'value',
    event: 'input'
  },

}
</script>

<style scoped>

</style>
