<template>

  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"
        v-bind:default_limit="'1000'"
        v-bind:default_sort="'id'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:extension="''"
        v-bind:export_csv="true"

    ></List>

  </div>

</template>

<script>
import List from "@/components/ListDefault.vue";

export default {
  name: "ListExchange",

  components: {List},

  created() {


  },

  data () {
    return {
      search: '',
      formview: 'FormExchange',
      table: 'exchange',
    }
  },


  computed: {
    headers () {
      return [
        { text: 'Datum', value: 'date' },
        { text: 'Eingang', value: 'incoming_company' },
        { text: 'Ausgang', value: 'outgoing_company' },
        { text: 'Typ', value: 'type' },
        { text: 'Gewicht/Stück', value: 'weight_qty' },
        { text: 'EK-Preis', value: 'amount_buy' },
        { text: 'VK-Preis', value: 'amount_sell' },
        { text: 'RE-Eingang', value: 'invoice_incoming' },
        { text: 'RE-Ausgang', value: 'invoice_outgoing' },
        { text: 'Bemerkung', value: 'note' },
        { text: '', value: 'edit' },
      ]
    },
  },


  methods: {



  },



}
</script>

<style scoped>

</style>
