<template>

<div
style="margin: 30px;"
>

    <v-tabs
        v-model="tab"
        background-color="#383083"
        center-active
        dark
    > <v-tab>Fahrer</v-tab>
      <v-tab>Lager</v-tab>
      <v-tab>Kunden</v-tab>
      <v-tab>Kundengruppen</v-tab>
      <v-tab>Reifen Qualität</v-tab>
      <v-tab>Verkäufe</v-tab>
      <v-tab>Entsorgungen</v-tab>
      <v-tab>Transport</v-tab>
    </v-tabs>



    <v-tabs-items
        style="margin: 30px;"
        v-model="tab">

      <v-tab-item>
        <AnalyticsReifen></AnalyticsReifen>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsStock></AnalyticsStock>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsCustomer></AnalyticsCustomer>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsGroup></AnalyticsGroup>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsRating></AnalyticsRating>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsSale></AnalyticsSale>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsDisposal></AnalyticsDisposal>
      </v-tab-item>

      <v-tab-item>
        <AnalyticsTransport></AnalyticsTransport>
      </v-tab-item>

    </v-tabs-items>







</div>


</template>

<script>

import AnalyticsRating from "../components/AnalyticsRating"
import AnalyticsSale from "../components/AnalyticsSale"
import AnalyticsDisposal from "../components/AnalyticsDisposal"
import AnalyticsGroup from "../components/AnalyticsGroup"
import AnalyticsCustomer from "../components/AnalyticsCustomer"
import AnalyticsReifen from "../components/AnalyticsReifen"
import AnalyticsStock from "../components/AnalyticsStock"
import AnalyticsTransport from "../components/AnalyticsTransport.vue"



export default {
  name: "Analytics",

    components:{
      AnalyticsRating,
      AnalyticsSale,
      AnalyticsDisposal,
      AnalyticsGroup,
      AnalyticsCustomer,
      AnalyticsReifen,
      AnalyticsStock,
      AnalyticsTransport,
    },

  data() {
    return {
      tab: null,
    }


  },


  created() {

/*
    this.date_from = this.$moment(Date.now()).subtract(40, "days").format("DD.MM.YYYY");
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")
*/

  },

  watch:{


  },


   methods: {




  }


}
</script>

<style scoped>

</style>
