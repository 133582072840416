<template>

  <v-layout

  >


    <DialogMap
        v-if="map == true"
        v-bind:lat="Number(lat)"
        v-bind:lng="Number(lng)"
        @reset="openMapReset"
    >

    </DialogMap>


    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <!--

        <v-btn @click="SelectCustomer = true"
               style="margin: 30px;"
        >
          <v-icon
              style="padding-right: 5px;"
          >mdi-account-box</v-icon>
          Kunden auswählen</v-btn>

        <v-btn @click="openAddCustomer"
               style="margin: 30px;"
        >
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>

        -->


        <div v-if="customer_id != ''">

          <v-text-field
              v-model="company"
              sm="12"
              md="4"
              label="Firma"
              disabled
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="street"
              sm="12"
              md="4"
              label="Strasse"
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="zipcode"
              sm="12"
              md="4"
              label="PLZ"
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="city"
              sm="12"
              md="4"
              label="Ort"
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

          ></v-text-field>



          <v-text-field
              v-model="email"
              sm="12"
              md="4"
              label="E-Mail"
              outlined
              type="search"
              autocomplete="off"
              style="
      display: table;
      margin: 0 auto;
      width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="phone"
              sm="12"
              md="4"
              label="Telefon"
              required
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

          ></v-text-field>

          <v-textarea
              v-model="customer_note"
              outlined
              disabled
              label="Bemerkungen zum Kunden"
              value=""
              style="
            display: table;
            margin: 30px auto;
            margin-top: 15px;
            width: 350px;"
          ></v-textarea>

        </div>

        <DialogDate
            v-bind:label="'Auftragsdatum'"
            v-bind:initial_date="date"
            v-model="date"
            @change="getExecutionDate"
        ></DialogDate>

        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz / Auftrags Nr. (Optional)"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <DialogDate
            v-bind:label="'Ausführungsfrist'"
            v-bind:initial_date="execution_date"
            v-bind:clearable="true"
            v-model="execution_date"
            :key="execution_date"

        ></DialogDate>


        <GroupList
            v-if="SelectArticle"
            v-bind:title="'Artikel zum abholen'"
            v-bind:table="'task_items'"
            v-bind:temp_task_id="temp_task_id"
            v-bind:values="values_article"
            v-bind:elevation="'0'"
        >
        </GroupList>


        <div
            style="padding: 10px;"
        ></div>

        <v-text-field
            v-model="lat"
            sm="12"
            md="4"
            label="lat (Geographische Breite)"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 300px;"

        ></v-text-field>


        <v-text-field
            v-model="lng"
            sm="12"
            md="4"
            label="lng (Geographische Länge)"
            required
            outlined
            type="search"
            autocomplete="false"
            style="
      display: table;
      margin: 0 auto;
      width: 300px;"

        ></v-text-field>

        <v-btn
            @click="openMap"
            style="margin-right: 10px;"
        ><v-icon>mdi-map</v-icon></v-btn>

        <v-btn
            @click="getGeoData"
        ><v-icon>mdi-map-marker-question-outline</v-icon></v-btn>


        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen zum Auftrag"
            value=""
            style="
            margin-top: 30px;
      display: table;
      margin: 30px auto;
      width: 350px;"
        ></v-textarea>



        <CustomSelect
            v-model="group_interval"
            v-bind:table="'group_interval'"
            v-bind:field_text="'text'"
            v-bind:field_value="'value'"
            v-bind:label="'Intervall'"
            v-bind:selected="group_interval"
            v-bind:extension="''"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></CustomSelect>



        <!--
        </v-col>
        -->

        <v-col
            sm="12"
            md="12"
            class="text-right"
        >

          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>





    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>

    <SelectCustomer
        v-if="SelectCustomer"
        @SelectedCustomer="loadCustomer"
        @SelectCustomerReset="SelectCustomerReset"

    ></SelectCustomer>

  </v-layout>


</template>

<script>
import SelectCustomer from "@/components/SelectCustomer";
import GroupList from "@/components/GroupList";
import DialogDate from "@/components/DialogDate";
import DialogMap from "@/components/DialogMap";
import CustomSelect from "@/components/CustomSelect";
import Vue from "vue";

export default {
  name: "FormRepeatTask",


  data () {
    return {

      title: 'Folgeauftrag',
      mainview: 'ListRepeatTask',
      table: 'task_repeat',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',

      timeout: 2500,

      SelectArticle: false,
      SelectCustomer: false,

      id: '',
      temp_task_id: '',

      map: false,


      group_main_id: '',
      group_main_text: '',

      group_type_id: '',
      group_type_text: '',


      customer_id: '',
      customer_note: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      email: '',
      note: '',
      lat: '',
      lng: '',

      date: '',
      execution_period: '',
      execution_date: '',
      complaint_date: '',

      group_execution_date: '',


      prio: '',

      document_no: '',
      qty: '',
      article: '',

      price_total: '',
      weight_total: '',

      repeattask: false,
      group_interval: '',

      values_article: [

        {
          name: 'Artikel',
          text:  'article_name',
          value:  'article_id',
          required: true,
          typ: 'select',
          select_table: 'article',
          select_text: 'name',
          select_value: 'id',
          select_label: 'Artikel',
        },
        {
          name: 'Stückzahl',
          value:  'order_qty',
          required: true,
          typ: 'text'
        },


      ],




    }
  },

  components:{
    SelectCustomer,
    GroupList,
    DialogDate,
    DialogMap,
    CustomSelect,
  },


  created() {

    // ToDo: Intervall Dauerauftrag anlegen

    // Datum von Heute
    // this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.date = this.$moment(Date.now()).format('DD.MM.YYYY')

    localStorage.createCustomer = "0"

    // console.log("this.$route.params.id: " + this.$route.params.id)


    if(this.$route.params.id != undefined && this.$route.params.id != ""){

      this.id = this.$route.params.id
      this.loadData()

    }


  },


  watch: {

    date(){
      this.getExecutionDate()
    }

  },


  methods:{



    async checkTaskExist(){
      console.log("checkTaskExist")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "list",
        where: "true",
        selector: "customer_id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "AND (pickup_date IS NULL OR pickup_date='')",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      if(result.length == "0"){
        return false
      }else{
        return true
      }


    },

    async checkRepeatTaskExist(){
      console.log("checkRepeatTaskExist")

      // Einstellungen
      var param = {
        table: "task_repeat",
        statement: "select",
        type: "list",
        where: "true",
        selector: "customer_id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      if(result.length == "0"){
        return false
      }else{
        return true
      }


    },





    async saveCustomer(){

      // Parameter
      var param = {
        table: "customer",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        street: this.street,
        zipcode: this.zipcode,
        city: this.city,
        lng: this.lng,
        lat: this.lat,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },


    openMap(){
      console.log("openMap")

      if(this.lat == "" || this.lng == ""){
        this.snackbar_title = "Bitte Geo Daten eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      this.map = true

    },

    openMapReset(){
      this.map = false
    },

    getGeoData(){

      if(this.street == ""){
        this.snackbar_title = "Bitte Adresse eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.city == ""){
        this.snackbar_title = "Bitte Stadt eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.zipcode == ""){
        this.snackbar_title = "Bitte PLZ eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }


      Vue.$geocoder.setDefaultMode('address');      // this is default
      var addressObj = {
        address_line_1: this.street,
        address_line_2: '',
        city:           this.city,
        state:          '',                      // province also valid
        zip_code:       this.zipcode,            // postal_code also valid
        country:        'Deutschland'
      }
      Vue.$geocoder.send(addressObj, response => {

        console.log(response)


        if(response["results"][0]["formatted_address"] == "Deutschland"){
          alert("Adresse nicht gefunden! Bitte Adresse prüfen")
          return
        }

        this.lat = response["results"][0]["geometry"]["location"]["lat"]
        this.lng = response["results"][0]["geometry"]["location"]["lng"]

        // geometry


        console.log("lat: " + response["results"][0]["geometry"]["location"]["lat"])
        console.log("lng: " + response["results"][0]["geometry"]["location"]["lng"])
        console.log("formatted_address: " + response["results"][0]["formatted_address"])



      });

    },

    btnCancel(){
      this.$router.push({ name: this.mainview })
    },


    openAddCustomer(){
      localStorage.createCustomer = "1"
      this.$router.push({ name: "FormCustomer" })
    },

    async loadItemsQty(){
      console.log("loadItemsQty")

      // Einstellungen
      var param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      var order_qty = 0
      var price_total = 0
      var weight_total = 0
      var article_name = ""


      for (var i1 = 0; i1 < result.length; i1++) {

        order_qty = Number(order_qty) + Number(result[i1]["order_qty"])
        price_total = Number(Number(price_total) + (Number(result[i1]["order_qty"] * Number(await this.setTaskItemsDetails(result[i1]["order_qty"], result[i1]["article_id"], result[i1]["id"], this.group_type_id)).toFixed(2)))).toFixed(2)


        if (article_name == ""){
          article_name = result[i1]["article_name"]
        }else{
          article_name = article_name + ", " + result[i1]["article_name"]
        }

      }

      // price_total

      this.price_total = price_total
      this.weight_total = weight_total

      return order_qty + ";" + article_name

    },

    async setTaskItemsDetails(qty, article_id, task_items_id, group_type_id){

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      console.log("group_type_id: " + group_type_id)


      // Einstellungen
      param = {
        table: "group_price",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "group_type_id",
        value: group_type_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "AND article_id='" + article_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      var result2 = await this.$func.queryAPI(param, data)
      console.log(result2)


      var price = 0

      if (result2.length == 0){
        price = 0
      }else{
        price = result2[0]["price"]
      }



      // Einstellungen
      param = {
        table: "task_items",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: task_items_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {

        weight: result[0]["weight"],
        weight_total: Number(Number(qty) * Number(result[0]["weight"])).toFixed(2),
        price: price,

      }

      console.log(data)

      var result3 = await this.$func.queryAPI(param, data)
      console.log(result3)

      return price
    },



    async loadCustomer(id){
      console.log("loadCustomer")

      this.customer_id = id

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.customer_id = result[0]["id"]
      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]
      this.email = result[0]["email"]


      this.customer_note = result[0]["note"]

      this.lat = result[0]["lat"]
      this.lng = result[0]["lng"]

      this.group_main_id =  result[0]["group_main_id"]
      this.group_main_text =  result[0]["group_main_text"]

      this.group_type_id =  result[0]["group_type_id"]
      this.group_type_text =  result[0]["group_type_text"]

      this.group_execution_date = result[0]["group_execution_date"]


      // Ausführungsfrist berechnen
      await this.getExecutionDate()


    },

    async getExecutionDate(){

      console.log("getExecutionDate")

      if(this.group_execution_date == ""){
        return
      }

      // Einstellungen
      var param = {
        table: "group_execution_date",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "group_type_id",
        value: this.group_type_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = []

      result = await this.$func.queryAPI(param, data)
      // console.log(result)

      if(result.length == 0){
        return
      }


      this.execution_period = result[0]["period"]

      console.log("MyDate: " + this.date)
      console.log("execution_period: " + this.execution_period)

      // Datum neu setzen

      if(this.execution_period != ""){
        var dateObject = new Date(this.$moment(this.date, "DD.MM.YYYY").format('YYYY-MM-DD'))

        this.execution_date = this.$moment(this.addDays(dateObject, Number(this.execution_period))).format('DD.MM.YYYY')
        // this.execution_date = new Date(this.addDays(dateObject, Number(this.execution_period)))

        console.log("this.execution_date")
        console.log(this.execution_date)

        // console.log("Ausführungsfrist: " + this.$moment(this.addDays(dateObject, Number(this.execution_period))).format('DD.MM.YYYY'))

      }


    },

    addDays(date, days) {
      var result = new Date(date);



      result.setDate(result.getDate() + days);
      return result;
    },

    SelectCustomerReset(){
      this.SelectCustomer = false
    },

    async saveData(){

      console.log("saveData")
      console.log(this.customer_id)

      if(this.customer_id == ""){
        this.snackbar_title = "Bitte Kunden bzw. Abholadresse auswählen!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if (this.id == ""){
        if(await this.checkTaskExist() == true){
          this.snackbar_title = "Ein offener Auftrag zum Kunden existiert bereits"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }
      }

      if(this.lat == ""){
        this.snackbar_title = "Bitte GPS Daten eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.lng == ""){
        this.snackbar_title = "Bitte GPS Daten eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }




      var order_qty = ""
      var article_name = ""
      const result1 = await this.loadItemsQty()

      order_qty = result1.toString().split(";")[0]
      article_name = result1.toString().split(";")[1]


      console.log(result1.toString().trim())

      if(result1.toString().trim() == "0;"){
        this.snackbar_title = "Bitte Artikel auswählen!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }


      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {

        customer_id: this.customer_id,
        company: this.company,
        street: this.street,
        zipcode: this.zipcode,
        city: this.city,
        phone: this.phone,
        email: this.email,
        note: this.note,

        date: this.date,
        date_timestamp: this.$moment(this.date, "DD.MM.YYYY").format('YYYY-MM-DD'),

        document_no: this.document_no,
        temp_task_id: this.temp_task_id,

        group_main_id: this.group_main_id,
        group_main_text: this.group_main_text,

        group_type_id: this.group_type_id,
        group_type_text: this.group_type_text,

        repeat_interval: this.group_interval.split(";")[1],

        order_qty: order_qty,
        article_name: article_name,

        price_total: this.price_total,
        weight_total: this.weight_total,

        lat: this.lat,
        lng: this.lng,

        worker_add: localStorage.username,

      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      //  Koordinaten des Kunden werden nochmal gespreichert!
      await this.saveCustomer()


      if(statement == "insert"){
        this.id = result["value"]
      }


      this.snackbar_title = "Auftrag gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true
    },



    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "task_repeat",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.customer_id = result[0]["customer_id"]
      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]
      this.email = result[0]["email"]
      this.note = result[0]["note"]

      this.group_type_id = result[0]["group_type_id"]
      this.group_type_text = result[0]["group_type_text"]
      this.group_main_id = result[0]["group_main_id"]
      this.group_main_text = result[0]["group_main_text"]

      this.group_interval = result[0]["repeat_interval"]
      console.log("group_interval: " + result[0]["repeat_interval"])


      this.temp_task_id = result[0]["temp_task_id"]

      this.date = result[0]["date"]
      this.execution_date = result[0]["execution_date"]
      this.execution_period = result[0]["execution_period"]
      this.complaint_date = result[0]["complaint_date"]
      this.document_no = result[0]["document_no"]

      this.lat = result[0]["lat"]
      this.lng = result[0]["lng"]

      this.SelectArticle = true
    }


  }



}
</script>

<style scoped>

</style>
