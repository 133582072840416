<template>
<div>
<v-card
    class="elevation-7"
    width="90%"

style="
margin-top: 30px;
margin-left: 10px;
margin-bottom: 10px;
height: 400px;
"
>


  <v-card-title>{{title}}</v-card-title>
<div
style="
position: absolute;
top: 200px;
left: 250px;
margin: 20px"
>
  <h1>{{Infotext}}</h1>
  </div>

  <div
    style="
    position: absolute;
    top: 40px;
    left: 20px;
"
  >Kundengruppe: {{customer_group_type_text}}</div>

  <div
      style="
    position: absolute;
    top: 65px;
    left: 20px;
"
  >Abgeholte Reifen Gesamt: {{customer_total_qty}} Stück</div>

  <div
      style="
    position: absolute;
    top: 90px;
    left: 20px;
"
  >Leerfahrten: {{customer_total_emptyruns}}</div>

  <div
  style="
  position: absolute;
  top: 20px;
  right: 20px;
"

  ><v-btn
  @click="openInfo"
  >
    <v-icon>mdi-information-outline</v-icon>
    </v-btn></div>


  <v-row
v-if="Chart"
>


  <cBar
      style="
      position: absolute;
      bottom: 0px;
      left: 0px;
      margin: 20px"

      :width="900"
      :height="250"

      :chart-options="chartOptions"
      :chart-data="chartData['chartData']"
      :chart-id="group_type_id + '_1'"

      :annotation_durchschnitt="Number(chartData['durchschnitt'])"
      :annotation_durchschnitt_atage="Number(chartData['durchschnitt_atage'])"
      :annotation_soll="400"

  />


    <cPie
        v-if="renderComponent"

        style="
        position: absolute;
        right: 80px;
        top: 35px;
        "

        :height="100"
        :chartData="chartDataPie"
    >

    </cPie>


    <div
        style="
        position: absolute;
        right: 20px;
        top: 140px;
        "
    >
        <p>PKW Reifen: {{customer_price_pkw}} EUR</p>
        <p>Umsatz: <b>{{customer_price_total}} EUR</b></p>
      <p>∅ Reifen Qualität: <b>{{customer_rating}} %</b></p>
    </div>



<!--
  <cBar

      style="margin: 20px"

      :width="450"
      :height="250"


      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="customer_id + '_2'"

      :annotation_durchschnitt="Number(durchschnitt)"
      :annotation_durchschnitt_atage="Number(durchschnitt_atage)"
      :annotation_soll="400"

  />
-->
</v-row>



</v-card>


</div>
</template>

<script>
import cBar from "@/components/cBar";
import cPie from "@/components/cPie";

export default {
  name: "CardAnalyticsCustomer",

  components:{
    cPie,
    cBar,
  },

  data() {
    return {

      title: "",
      title2: "",


      renderComponent: true,
      overlay: false,

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */
      customer_group_type_id: '',
      customer_group_type_text: '',
      customer_total_qty: 0,
      customer_total_emptyruns: 0,
      customer_price_total: 0,
      customer_price_pkw: 0,
      customer_rating: 0,

      durchschnitt: '',
      durchschnitt_atage: '',

      Infotext: '',

      Chart: false,

      chartData: Object,


      chartDataPie: {
        labels: ['Sehr Gut', 'Gut', 'Neutral', 'Schlecht', 'Sehr Schlecht'],
        datasets: [
          {
            backgroundColor: ['green', 'lime', 'yellow', 'orange', 'red'],
            data: ["0", "0", "0", "0", "0"]
          }
        ]
      },

      chartOptions: {
        responsive: true
      },

    }

  },



 async created() {


   this.chartData = {
     labels: ['January', 'February', 'March'],
     datasets: [{data: [40, 20, 0]}]
   }

   console.log("this.chartData")
   console.log(this.chartData)



   await this.loadData()


   // Remove MyComponent from the DOM
   this.Chart = false;


   // Wait for the change to get flushed to the DOM
   await this.$nextTick();

   // Add the component back in
   this.Chart = true;


  },

  methods:{

    async openInfo(){

      //this.$router.push({ name: "ListCheck", params:{id: this.customer_id} })
      //this.$router.push({ name: "ListCheck", params:{id: this.customer_id} })

     let routeData = this.$router.resolve({ name: "ListCheckGroup", params:{id: this.group_type_id} })
     window.open(routeData.href, '_blank');

    },


    async loadData(){


      // Umwandeln Datum Format

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      var dates = [];


      // Alle Tage innerhalb einer Zeitspanne ermitteln

      var currDate = this.$moment(date_from, "YYYY-MM-DD").startOf('day');
      var lastDate = this.$moment(date_to).startOf('day');

      while (currDate.add(1, 'days').diff(lastDate) < 0) {

        // console.log(this.$moment(currDate.toDate()).format("DD.MM.YYYY"));
        // console.log(this.$moment(currDate.clone().toDate()).format("dddd"))

        if (this.$moment(currDate.clone().toDate()).format("dddd") != "Sunday" && this.$moment(currDate.clone().toDate()).format("dddd") != "Saturday") {
          dates.push(this.$moment(currDate.clone().toDate()).format("DD.MM.YYYY"));
        }

      }





      // Einstellungen
      var param99 = {
        table: "group_type",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT name, group_main_name, rating FROM group_type WHERE id='" + this.group_type_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data99 = {}

      var result99 = await this.$func.queryAPI(param99, data99)


      console.log("result99")
      console.log(result99)


      // this.title = result0[0]["company"] + " " + result0[0]["street"] + " " + result0[0]["zipcode"] + " " + result0[0]["city"]
      this.customer_group_type_text = result99[0]["name"] + " " + "(" + result99[0]["group_main_name"] + ")"
      this.customer_group_type_id = this.group_type_id
      // this.customer_rating= result99[0]["rating"]




      // Einstellungen
      var param98 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data98 = {}

      var result98 = await this.$func.queryAPI(param98, data98)





      switch (Math.round(result99[0]["rating"])) {
        case 1:
          this.customer_rating = result98[0]["wert"]
          break;
        case 2:
          this.customer_rating = result98[1]["wert"]
          break;
        case 3:
          this.customer_rating = result98[2]["wert"]
          break;
        case 4:
          this.customer_rating = result98[3]["wert"]
          break;
        case 5:
          this.customer_rating = result98[4]["wert"]
          break;
        default:
          this.customer_rating = "0"
      }




      // Einstellungen
      var param0 = {
        table: "customer",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT pickup_date, customer_id, pickup_qty, group_type_text, group_type_id, group_main_text FROM task WHERE group_type_id='" + this.group_type_id + "' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data0 = {}

      var result0 = await this.$func.queryAPI(param0, data0)


      for (var i97 = 0; i97 < result0.length; i97++) {

      if(result0[i97]["pickup_qty"] == 0 || result0[i97]["pickup_qty"] == ""){
        this.customer_total_emptyruns = Number(this.customer_total_emptyruns) + 1
      }

      }


      if(result0.length == 0){
        this.Infotext = "Keine Abholungen"
        return
      }








      // Liest alle Aufträge ein

      // Einstellungen
      var param1 = {
        table: "task",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT pickup_date, sum(order_qty), sum(pickup_qty), sum(price_total), company FROM task WHERE pickup_date IS NOT NULL AND group_type_id='" + this.group_type_id + "' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' GROUP BY pickup_date ORDER BY date_timestamp ASC",
      }


      console.log(param1.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

      if (result1.length == 0){
        this.Infotext = "Keine Abholungen in diesem Zeitraum"
        this.Chart = false
        return
      }

      for (var i98 = 0; i98 < result1.length; i98++) {
        this.customer_price_total = Math.abs(this.customer_price_total) + Math.abs(result1[i98]["sum(price_total)"])
      }

      this.customer_price_total = this.customer_price_total.toLocaleString("de-DE")






      // Einstellungen
      var param2 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT pickup_timestamp, rating, pickup_qty, article_name, article_id FROM task_items WHERE pickup_qty IS NOT NULL AND group_type_id='" + this.group_type_id + "' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' ORDER BY pickup_timestamp ASC",
      }


      console.log(param2.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

      console.log("result2")
      console.log(result2)





      // Einstellungen
      var param3 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT rating, COUNT(*) FROM task_items WHERE pickup_qty <> '0' AND group_type_id='" + this.group_type_id + "' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' GROUP BY rating ORDER BY rating DESC",
      }


      console.log(param3.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {}

      var result3 = await this.$func.queryAPI(param3, data3)

      console.log("result3")
      console.log(result3)


      console.log(this.chartDataPie)


      for (var i99 = 0; i99 < result3.length; i99++) {

        if(result3[i99]["rating"] == 5){
          this.chartDataPie["datasets"][0]["data"][0] = result3[i99]["COUNT(*)"]
        }
        if(result3[i99]["rating"] == 4){
          this.chartDataPie["datasets"][0]["data"][1] = result3[i99]["COUNT(*)"]
        }
        if(result3[i99]["rating"] == 3){
          this.chartDataPie["datasets"][0]["data"][2] = result3[i99]["COUNT(*)"]
        }
        if(result3[i99]["rating"] == 2){
          this.chartDataPie["datasets"][0]["data"][3] = result3[i99]["COUNT(*)"]
        }
        if(result3[i99]["rating"] == 1){
          this.chartDataPie["datasets"][0]["data"][4] = result3[i99]["COUNT(*)"]
        }

      }

      console.log(this.chartDataPie)


      /*
            this.chartData = {
              labels: ['January', 'February', 'March'],
                  datasets: [{data: [40, 20, 0]}]
            }
      */









      // Einstellungen
      var param4 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT price FROM group_price WHERE article_name='PKW-Reifen' AND group_type_id='" + this.customer_group_type_id + "'",
      }


      console.log(param2.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data4 = {}

      var result4 = await this.$func.queryAPI(param4, data4)

      console.log("result4")
      console.log(result4)

      this.customer_price_pkw = result4[0]["price"]



      // customer_price_pkw


      var array_values1 = []
      var array_values2 = []


      for (var i1 = 0; i1 < dates.length; i1++) {
        array_values1.push("0")
      }
      for (var i2 = 0; i2 < dates.length; i2++) {
        array_values2.push("0")
      }


      var sub_array = []

      sub_array.push({
        label: "ANGEMELDET",
        backgroundColor: "pink",
        borderColor: "red",
        borderWidth: 1,
        data: array_values1,
      })

      sub_array.push({
        label: "ABGEHOLT",
        backgroundColor: "lightgreen",
        borderColor: "green",
        borderWidth: 1,
        data: array_values2
      })



      this.chartData = {
        name: result1[0]["company"],
        id: this.group_type_id,
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array}
      }






      var all_reifen = 0
      var atage = 0


        this.customer_total_qty = 0

        for (var i7 = 0; i7 < result1.length; i7++) {
            this.customer_total_qty = Number(this.customer_total_qty) + Number(result1[i7]["sum(pickup_qty)"])
        }

        console.log("customer_total_qty")
        console.log(this.customer_total_qty)
        console.log(result1)



      for (var i4 = 0; i4 < this.chartData["chartData"]["labels"].length; i4++) {


        for (var i5 = 0; i5 < result1.length; i5++) {


          if (this.chartData["chartData"]["labels"][i4] == result1[i5]["pickup_date"]) {

            atage = Number(atage) + 1
            all_reifen = Number(all_reifen) + Number(result1[i5]["sum(pickup_qty)"])


            this.chartData["chartData"]["datasets"][0]["data"][i4] = result1[i5]["sum(order_qty)"]
            this.chartData["chartData"]["datasets"][1]["data"][i4] = result1[i5]["sum(pickup_qty)"]

            // Leerfahrt
              if(result1[i5]["sum(pickup_qty)"] == 0){
               // this.customer_total_emptyruns = Number(this.customer_total_emptyruns) + 1
               // this.chartData["chartData"]["datasets"][1]["data"][i4] = 100
              }


          }

        }

        i5 = 0

      }

        this.customer_total_qty = Number(this.customer_total_qty).toLocaleString("de-DE")


      sub_array = []


      var durchschnitt_atage = Number(all_reifen) / Number(atage)
      var durchschnitt = Number(all_reifen) / Number(this.chartData["chartData"]["datasets"][0]["data"].length)


      this.chartData["durchschnitt_atage"] =  Math.round(durchschnitt_atage)
      this.chartData["durchschnitt"] =  Math.round(durchschnitt)


      console.log("this.chartData")
      console.log(this.chartData)







      // Remove MyComponent from the DOM
      this.Chart = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.Chart = true;




    }



  },



  props: {

    group_type_id: {
      type: String,
      default: ''
    },

    date_from:{
      type: String,
      default: ''
    },

    date_to:{
      type: String,
      default: ''
    },

  }


}
</script>

<style scoped>

</style>
