<template>
<div>
  <v-timeline
      align-top
      dense
  >
    <v-timeline-item
        v-for="item in items"
        :key="item.id"
        :color="item.color"
        small
    >
      <div>
        <div class="font-weight-normal">
          <strong>{{ item.company }}</strong> <br>
          {{ item.street }} {{ item.zipcode }} {{ item.city }}
        </div>
        <div v-if="item.pickup_date == null || item.pickup_date == ''">
          Anzahl: {{ item.order_qty }}
        </div>
        <div v-else>
          Angemeldet / Abgeholt: {{ item.order_qty }} / {{ item.pickup_qty }}<br>
          Restmenge: {{ item.rest }}<br>
          Abholzeit: {{ item.pickup_date }} {{ item.pickup_time }}
        </div>

        <div>
          Artikel: {{ item.article_name }}
        </div>


      </div>
    </v-timeline-item>
  </v-timeline>
</div>
</template>

<script>
export default {
  name: "cTimeLine",


  data() {
    return {
      items: [],

    }
  },

  created() {

    this.loadData()

  },

  methods:{


    async loadData(){
      console.log("loadData")


      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "list",
        where: "true",
        selector: "tour_id",
        value: this.tour_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "ORDER BY `tour_position` ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      this.items = result

// Setzt den Punkt grün abgeholt rot = noch nicht abgeholt
      for (var i1 = 0; i1 < this.items.length; i1++) {

        if(this.items[i1].pickup_date == "" || this.items[i1].pickup_date == null){
          this.items[i1].color = "red"
        }else{
          this.items[i1].color = "green"
        }


        if(this.items[i1].rest == "" || this.items[i1].rest == null){
          this.items[i1].rest = "0"
        }

      }



    },



  },

  props: {

    tour_id: {
      type: String,
      default: ''
    },


  }




}
</script>

<style scoped>

</style>
