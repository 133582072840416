<template>

  <div
      style="
        height: 180px;
        width: 100%"
  >
    <div><h2>{{title}}</h2></div>
    <div>{{title2}}</div>


    <div
        style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
    >
      <DialogDate
          v-bind:label="'Von'"
          v-bind:initial_date="date_from"
          v-bind:clearable="false"
          v-model="date_from"
      ></DialogDate>

      <DialogDate
          v-bind:label="'Bis'"
          v-bind:initial_date="date_to"
          v-bind:clearable="false"
          v-model="date_to"
      ></DialogDate>



      <div

      >
        <v-card
            class="mx-auto"
            width="300"
            height="500"
            style="margin: 15px;"

        >
          <v-card-title>ZUSAMMENFASSUNG</v-card-title>
<div
style="margin: 20px;"
>
          <p style="color: green; font-size: larger">Eingang/Einkäufe:</p>
  <p style="color: green; font-size: larger"><b>{{total_stock_in}}</b></p>
          <p style="color: red; font-size: larger">Verkäufe:</p>
  <p style="color: red; font-size: larger"><b>{{total_stock_out}}</b></p>
          <p style="color: red; font-size: larger">Entsorgungen:</p>
  <p style="color: red; font-size: larger"><b>{{total_stock_disposal}}</b></p>
<br>
  <p style="color: black; font-size: larger">Summe:</p>
  <p style="color: black; font-size: larger"><b>{{total_stock_sum}}</b></p>

</div>


        </v-card>


      </div>



    </div>

    <v-overlay
           :value="overlay"
    >

      <v-progress-circular
          indeterminate
          color="black"
          style="margin: 20px;"
      ></v-progress-circular>

      Daten werden geladen...

    </v-overlay>


    <div
        v-if="renderComponent"
    >

      <!--      :dataset-id-key="datasetIdKey"  -->

<div
    style="
            margin: 10px;
            float: left"
>

  <div>Lager Eingang/Einkauf (in Stück / Schrott+Gutanteil)</div>

      <cBar

          :chart-options="chartOptions"
          :chart-data="stock_in[0].chartData"
          :chart-id="'1'"

          :annotation_durchschnitt="Number(stock_in[0].durchschnitt)"
          :annotation_durchschnitt_atage="Number(stock_in[0].durchschnitt_atage)"
          :annotation_soll="400"

      />


      <div>Lager Verkäufe (in Stück)</div>

      <cBar

          :chart-options="chartOptions"
          :chart-data="stock_out[0].chartData"
          :chart-id="'2'"

          :annotation_durchschnitt="Number(stock_out[0].durchschnitt)"
          :annotation_durchschnitt_atage="Number(stock_out[0].durchschnitt_atage)"
          :annotation_soll="400"

      />




  <div>Lager Entsorgungen (in Tonnen)</div>



  <cBar

      :chart-options="chartOptions"
      :chart-data="stock_disposal[0].chartData"
      :chart-id="'3'"

      :annotation_durchschnitt="Number(stock_disposal[0].durchschnitt)"
      :annotation_durchschnitt_atage="Number(stock_disposal[0].durchschnitt_atage)"
      :annotation_soll="400"

  />


</div>


    </div>




  </div>

</template>

<script>
import cBar from "../components/cBar"
import DialogDate from "../components/DialogDate"


export default {
  name: "AnalyticsReifen",

  components:{
    cBar,
    DialogDate,
  },

  data() {
    return {

      title: "Lager Ein- und Ausgänge",
      title2: "",

      chartData: Object,

      sync: true,


      stock_in: [],
      stock_out: [],
      stock_disposal: [],

      total_stock_in: '0',
      total_stock_out: '0',
      total_stock_disposal: '0',
      total_stock_sum: '0',


      renderComponent: true,
      overlay: false,

      default_range_in_days: 90,


      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },


  async created() {

    this.sync = true

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()

    this.sync = false

  },

  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

     this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

    this.loadData()
    },

  },

  methods:{

    async loadData(){

        console.log("loadData: " + this.sync)

        this.stock_in = []
        this.stock_out = []
        this.stock_disposal = []


      this.overlay = true


      // Remove MyComponent from the DOM
      this.renderComponent = false;

      await this.getStock()
      await this.getStockTotal()

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;


      this.overlay = false


    },



    async getStockTotal(){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD");
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      var weight_out, weight_in, weight_disposal

      // Fahrer werden ausgelesen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(weight), sum(qty) FROM stock WHERE typ='buy' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result_buy = await this.$func.queryAPI(param1, data1)

      this.total_stock_in = Number(result_buy[0]["sum(qty)"]).toLocaleString("de-DE") + " Stück " + Number(result_buy[0]["sum(weight)"]).toFixed(3) + " T"

      weight_in = result_buy[0]["sum(weight)"]



      // Fahrer werden ausgelesen
      var param2 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(weight), sum(qty) FROM stock WHERE typ='sell' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result_sell = await this.$func.queryAPI(param2, data2)

        console.log("result_sell")
        console.log(result_sell)


      this.total_stock_out = Number(result_sell[0]["sum(qty)"]).toLocaleString("de-DE") + " Stück " + Number(result_sell[0]["sum(weight)"]).toFixed(3) + " T"

      weight_out = result_sell[0]["sum(weight)"]



      // Fahrer werden ausgelesen
      var param3 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(weight), sum(qty) FROM stock WHERE typ='disposal' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {}

      var result_disposal = await this.$func.queryAPI(param3, data3)

      this.total_stock_disposal = Number(result_disposal[0]["sum(weight)"]).toFixed(3) + " T"

      weight_disposal = result_disposal[0]["sum(weight)"]



      this.total_stock_sum = Number(weight_in) - Math.abs(Number(weight_out)) - Math.abs(Number(weight_disposal))
      this.total_stock_sum = this.total_stock_sum.toFixed(3) + " T"


    },








    async getStock(){

      // Umwandeln Datum Format

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD");
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      var dates = [];


      // Alle Tage innerhalb einer Zeitspanne ermitteln

      var currDate = this.$moment(date_from, "YYYY-MM-DD").startOf('day');
      var lastDate = this.$moment(date_to, "YYYY-MM-DD").startOf('day');

      console.log(currDate)
      console.log(lastDate)

      if (this.$moment(currDate.clone().toDate()).format("dddd") != "Sunday" && this.$moment(currDate.clone().toDate()).format("dddd") != "Saturday") {
        dates.push(this.$moment(currDate.clone().toDate()).format("DD.MM.YYYY"));
      }

      while (currDate.add(1, 'days').diff(lastDate) < 1) {

        // console.log(this.$moment(currDate.toDate()).format("DD.MM.YYYY"));
        // console.log(this.$moment(currDate.clone().toDate()).format("dddd"))

        if (this.$moment(currDate.clone().toDate()).format("dddd") != "Sunday" && this.$moment(currDate.clone().toDate()).format("dddd") != "Saturday") {
          dates.push(this.$moment(currDate.clone().toDate()).format("DD.MM.YYYY"));
        }

      }


      console.log("dates")
      console.log(dates)

      var array_values1 = []
      var array_values2 = []
      var array_values3 = []

      for (var i1 = 0; i1 < dates.length; i1++) {
        array_values1.push("0")
      }
      for (var i2 = 0; i2 < dates.length; i2++) {
        array_values2.push("0")
      }
      for (var i3 = 0; i3 < dates.length; i3++) {
        array_values3.push("0")
      }

      var sub_array1 = []
      var sub_array2 = []
      var sub_array3 = []

      sub_array1.push({
        label: "EINGANG",
        backgroundColor: "lightgreen",
        borderColor: "green",
        borderWidth: 1,
        data: array_values1,
      })


      sub_array2.push({
        label: "VERKAUF",
        backgroundColor: "pink",
        borderColor: "red",
        borderWidth: 1,
        data: array_values2,
      })


      sub_array3.push({
        label: "ENTSORGUNG",
        backgroundColor: "pink",
        borderColor: "red",
        borderWidth: 1,
        data: array_values3,
      })



      this.stock_in.push({
        name: "stock_in",
        id: "1",
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array1}
      })

      this.stock_out.push({
        name: "stock_out",
        id: "2",
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array2}
      })


      this.stock_disposal.push({
        name: "stock_disposal",
        id: "3",
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array3}
      })




      // Fahrer werden ausgelesen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT date, date_timestamp, sum(qty) FROM stock WHERE typ='buy' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY date_timestamp ORDER BY date_timestamp ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result_buy = await this.$func.queryAPI(param1, data1)

      console.log("Test")
      console.log(result_buy)


      // Fahrer werden ausgelesen
      var param2 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT date, date_timestamp, sum(qty) FROM stock WHERE typ='sell' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY date ORDER BY date_timestamp ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result_sell = await this.$func.queryAPI(param2, data2)




      // Fahrer werden ausgelesen
      var param3 = {
        table: "stock",
        statement: "custom",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT date, date_timestamp, sum(weight) FROM stock WHERE typ='disposal' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY date ORDER BY date_timestamp ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {}

      var result_disposal = await this.$func.queryAPI(param3, data3)

      console.log("result_disposal")
      console.log(result_disposal)








      var all_stock_in = 0
      var all_stock_out = 0
      var all_stock_disposal = 0

      var atage = 0




      for (var i4 = 0; i4 < this.stock_in[0]["chartData"]["labels"].length; i4++) {


        for (var i5 = 0; i5 < result_buy.length; i5++) {


          if (this.stock_in[0]["chartData"]["labels"][i4] == result_buy[i5]["date"]) {

            atage = Number(atage) + 1
            all_stock_in = Number(all_stock_in) + Number(result_buy[i5]["sum(qty)"])

            this.stock_in[0]["chartData"]["datasets"][0]["data"][i4] = result_buy[i5]["sum(qty)"]

          }

        }

        i5 = 0

      }


      var durchschnitt_atage = Number(all_stock_in) / Number(atage)
      var durchschnitt = Number(all_stock_in) / Number(this.stock_in[0]["chartData"]["datasets"][0]["data"].length)

      this.stock_in[0]["durchschnitt_atage"] = Math.round(durchschnitt_atage)
      this.stock_in[0]["durchschnitt"] = Math.round(durchschnitt)



      atage = 0

      for (var i6 = 0; i6 < this.stock_out[0]["chartData"]["labels"].length; i6++) {


        for (var i7 = 0; i7 < result_sell.length; i7++) {


          if (this.stock_out[0]["chartData"]["labels"][i6] == result_sell[i7]["date"]) {

            atage = Number(atage) + 1
            all_stock_out = Number(all_stock_out) + Number(result_sell[i7]["sum(qty)"])

            this.stock_out[0]["chartData"]["datasets"][0]["data"][i6] = result_sell[i7]["sum(qty)"]

          }

        }

        i7 = 0

      }



       durchschnitt_atage = Number(all_stock_out) / Number(atage)
       durchschnitt = Number(all_stock_out) / Number(this.stock_out[0]["chartData"]["datasets"][0]["data"].length)

      this.stock_out[0]["durchschnitt_atage"] =  Math.round(durchschnitt_atage)
      this.stock_out[0]["durchschnitt"] =  Math.round(durchschnitt)










      atage = 0

      for (var i8 = 0; i8 < this.stock_disposal[0]["chartData"]["labels"].length; i8++) {


        for (var i9 = 0; i9 < result_disposal.length; i9++) {


          if (this.stock_disposal[0]["chartData"]["labels"][i8] == result_disposal[i9]["date"]) {

            atage = Number(atage) + 1
            all_stock_disposal = Number(all_stock_disposal) + Math.abs(Number(result_disposal[i9]["sum(weight)"]))

            this.stock_disposal[0]["chartData"]["datasets"][0]["data"][i8] = Math.abs(result_disposal[i9]["sum(weight)"])

          }

        }

        i9 = 0

      }



      durchschnitt_atage = Number(all_stock_disposal) / Number(atage)
      durchschnitt = Number(all_stock_disposal) / Number(this.stock_disposal[0]["chartData"]["datasets"][0]["data"].length)

      this.stock_disposal[0]["durchschnitt_atage"] =  Math.round(durchschnitt_atage)
      this.stock_disposal[0]["durchschnitt"] =  Math.round(durchschnitt)












    },


  },




}
</script>

<style scoped>

</style>
