<template>

  <v-layout

  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <CustomSelect
            v-model="disposer"
            v-bind:table="'disposer'"
            v-bind:field_text="'company'"
            v-bind:field_value="'id'"
            v-bind:label="'Entsorger'"
            v-bind:selected="disposer_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></CustomSelect>



        <v-dialog
            ref="dialog1"
            v-model="modal1"
            :return-value.sync="date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal1 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>


        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz Nr."
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="weight"
            sm="12"
            md="4"
            label="Gewicht in Tonnen"
            required
            outlined
            type="number"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="price"
            sm="12"
            md="4"
            label="Betrag in EUR (Optional)"
            required
            outlined
            :background-color="price_bgcolor"
            type="number"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-checkbox
            v-model="price_positive"
            label="Preis Gutschrift"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-checkbox>


        <CustomSelect
            v-model="article"
            v-bind:table="'article'"
            v-bind:field_text="'name'"
            v-bind:field_value="'id'"
            v-bind:label="'Artikel'"
            v-bind:selected="article_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></CustomSelect>


        <v-text-field
            v-model="wasteno"
            disabled
            sm="12"
            md="4"
            label="Abfallschlüssel"
            required
            outlined
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>


        <v-checkbox
            v-model="checkbox"
            label="Lagerabbuchung (ABBUCHUNG)"
            :disabled="checkbox_disable"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-checkbox>


        <v-text-field
            v-model="stock_id"
            outlined
            label="Lager ID"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>


        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>


        <v-switch
            v-model="invoice"
            label="Rechnung erhalten"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>


        <v-switch
            v-model="invoice_forward"
            label="Weiterberechnen"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>

        <v-text-field
            v-if="invoice_forward == true"
            v-model="invoice_forward_written"
            sm="12"
            md="4"
            label="Weiterberechnen RE-Nr"
            required
            outlined
            type="text"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>


<!--


        <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="invoice_date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoiceDate"
                label="Rechnungsdatum"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal2 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog2.save(invoice_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

-->


<!--


        <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="invoice_payment_deadline"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoicePaymentDeadLine"
                label="Zahlungsfrist am"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_payment_deadline"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal3 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog3.save(invoice_payment_deadline)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>


        <v-dialog
            ref="dialog4"
            v-model="modal4"
            :return-value.sync="invoice_payment_date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoicePaymentDate"
                label="Rechnung bezahlt am"
                prepend-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_payment_date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal4 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog4.save(invoice_payment_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

-->



        <v-col
            sm="12"
            md="12"
            class="text-right"
        >

          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>

</template>

<script>

import CustomSelect from "@/components/CustomSelect";

export default {
  name: "FormDisposal",


  components:{
    CustomSelect,
  },

  data () {
    return {

      title: 'Entsorgung',
      mainview: 'ListDisposal',
      table: 'disposal',

      modal1: '',
      modal2: '',
      modal3: '',
      modal4: '',

      checkbox: false, // Lagerbuchung
      checkbox_disable: false, // Lagerbuchung

      // date: this.$moment(new Date()).format("DD.MM.YYYY").toISOString(),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',
      timeout: 2500,


      id: '',
      disposer: '',
      disposer_id: '',
      disposer_company: '',
      document_no: '',
      article: '',
      article_id: '',
      article_name: '',
      article_weight: '',

      stock_id: '',

      wasteno: '',

      company: '',
      price: '',
      price_bgcolor: 'red',
      price_positive: false,
      weight: '',

      price_per_ton: 0,

      invoice: false,
      invoice_forward: false,
      invoice_forward_written: '',
      invoice_date: '',
      invoice_payment_deadline: '',
      invoice_payment_date: '',
      note: '',

    }
  },

  created() {

   // console.log("TIME")
   // console.log(this.$moment(new Date()).format("DD.MM.YYYY"))


    this.date = this.$moment(Date.now()).format("YYYY-MM-DD")


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },

  watch:{


    price_positive: function(){

      console.log(this.price_positive)

      if(this.price_positive === true ){
        this.price_bgcolor = "green"
      }else{
        this.price_bgcolor = "red"
      }



    },


    disposer: function () {

      if(this.disposer == null){
        return
      }

      if(this.disposer.trim() == ";"){
        this.disposer_id = ""
        this.disposer_company = ""
        this.price_per_ton = ""
      }

      if(this.disposer.trim() != ";" && this.disposer.includes(";") == true){
        this.disposer_id = this.disposer.split(";")[1]
        this.disposer_company = this.disposer.split(";")[0]

        this.loadDisposerPrice()
      }

    },

    weight: function () {

      if(this.price_per_ton == "" || this.price_per_ton.toString() == "0"){
        return
      }

      this.price = Number (Number(this.price_per_ton) * Number(this.weight)).toFixed(2)

    },


    article: function () {

      if(this.article == null){
        this.wasteno = ""
        return
      }

      if(this.article.trim() == ";"){
        this.article_id = ""
        this.article_name = ""
      }

      if(this.article.trim() != ";" && this.article.includes(";") == true){
        this.article_id = this.article.split(";")[1]
        this.article_name = this.article.split(";")[0]
      }else{
        // this.article_id = ""
        // this.article_name = ""
      }

      if(this.article_id == ""){
        this.wasteno = ""
        return
      }
      this.getWeightandWasteno(this.article_id)

    }


  },


  methods:{



    // Liefert das Artikelgewicht - Falls nicht vorhanden dann 0
    async getWeightandWasteno(article_id){
      console.log("getWeightandWasteno")
      console.log(article_id)

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.article_weight = result[0]["weight"]
      this.wasteno = result[0]["wasteno"]

    },









    btnCancel(){

      this.$router.push({ name: this.mainview })

    },

    async loadDisposerPrice(){

      console.log("loadDisposerPrice")

      // Einstellungen
      var param = {
        table: "disposer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.disposer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      this.price_per_ton = result[0]["price_per_ton"]

    },








    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      console.log("loadData")
      console.log(result)


      if (result[0]["date"] == null){
        this.date = this.$moment(Date.now()).format("YYYY-MM-DD")
      }else{
        this.date = this.$moment(result[0]["date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }

      this.disposer = result[0]["disposer_id"]
      this.disposer_id = result[0]["disposer_id"]
      this.disposer_company = result[0]["disposer_company"]

      this.article = result[0]["article_id"]
      this.article_id = result[0]["article_id"]
      this.article_name = result[0]["article_name"]

      this.wasteno = result[0]["wasteno"]

      this.document_no = result[0]["document_no"]
      this.weight = result[0]["weight"]
      this.price = result[0]["price"]


      if (result[0]["price_positive"] == "true"){
        this.price_positive = true
        this.price_bgcolor = "green"
      }else{
        this.price_positive = false
        this.price_bgcolor = "red"
      }


      this.note = result[0]["note"]

      this.stock_id =  result[0]["stock_id"]

          if(this.stock_id != ""){
            this.checkbox = true
            this.checkbox_disable = true
          }

      if(result[0]["invoice"] == "" || result[0]["invoice"] == null){
        this.invoice = false
      }else{
        this.invoice = result[0]["invoice"]
      }


      if(result[0]["invoice_forward"] == "" || result[0]["invoice_forward"] == null){
        this.invoice_forward = false
      }else{
        this.invoice_forward = result[0]["invoice_forward"]
      }

      this.invoice_forward_written = result[0]["invoice_forward_written"]



      // this.checkbox = result[0]["note"]

      if (result[0]["invoice_date"] == null || result[0]["invoice_date"] == ""){
        this.invoice_date = ""
      }else{
        this.invoice_date = this.$moment(result[0]["invoice_date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }
      if (result[0]["invoice_payment_date"] == null || result[0]["invoice_payment_date"] == ""){
        this.invoice_payment_date = ""
      }else{
        this.invoice_payment_date = this.$moment(result[0]["invoice_payment_date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }
      if (result[0]["invoice_payment_deadline"] == null || result[0]["invoice_payment_deadline"] == ""){
        this.invoice_payment_deadline = ""
      }else{
        this.invoice_payment_deadline = this.$moment(result[0]["invoice_payment_deadline"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }



    },

    async saveData(){

      if(this.disposer.trim === ""){
        this.snackbar_title = "Bitte Entsorger eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.disposer.trim() === ";"){
        this.snackbar_title = "Bitte Entsorger eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.invoice == true){
        if(this.note == ""){
          this.snackbar_title = "Bitte Rechnungsnummer eingeben!"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }
      }



      await this.getWeightandWasteno(this.article_id)


      if(this.checkbox == true){
        // Lagerbuchung
            await this.saveDataStock(this.stock_id)
      }


      this.weight = -Math.abs(this.weight)
      this.weight = Number(this.weight).toFixed(2)
      this.weight = this.weight.toString().replace(",",".")


      console.log("before")
      console.log(this.price)


      if(this.price_positive == false){
        this.price = -Math.abs(this.price)
        this.price = Number(this.price).toFixed(2)
        this.price = this.price.toString().replace(",",".")
      }else{
        this.price = +Math.abs(this.price)
        this.price = Number(this.price).toFixed(2)
        this.price = this.price.toString().replace(",",".")
      }


      console.log("after")
      console.log(this.price)


      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {

        disposer_id: this.disposer_id,
        disposer_company: this.disposer_company,

        article_id: this.article_id,
        article_name: this.article_name,

        wasteno: this.wasteno,

        document_no: this.document_no,

        invoice: this.invoice,
        invoice_forward: this.invoice_forward,
        invoice_forward_written: this.invoice_forward_written,
        invoice_date: this.computedInvoiceDate,
        invoice_payment_deadline: this.computedInvoicePaymentDeadLine,
        invoice_payment_date: this.computedInvoicePaymentDate,

        date: this.computedDate,
        date_timestamp: this.$moment(this.computedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),

        weight: this.weight.toString().replace(",","."),
        price: this.price,
        price_positive: this.price_positive.toString(),
        note: this.note,

        stock_id: this.stock_id,
      }


      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true


      if(statement == "insert"){
        this.id = result["value"]
      }


    },


    async saveDataStock(id){

      var statement
      if(id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: "stock",
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Format Weight
      this.weight = this.weight.toString().replace(",",".")
      this.weight = -Math.abs(this.weight)



      // Data
      var data = {

        disposer_id: this.disposer_id,
        disposer_company: this.disposer_company,
        name: this.disposer_company,

        customer_id: this.customer_id,
        document_no: this.document_no,
        typ: "disposal",

        date: this.computedDate,
        date_timestamp: this.$moment(this.computedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
        time: this.$moment(Date.now()).format('HH:mm:ss'),

        average_weight: localStorage.average_weight,

        article_id: this.article_id,
        article_name: this.article_name,
        article_weight: this.article_weight,

        wasteno: this.wasteno,

        weight: this.weight,
        scrap_quantity_weight: this.weight,
        good_quantity_weight: 0,

        price: this.price,
        note: this.note,

        worker: localStorage.username,

      }


      console.log("this.article: " + this.article)

      var result = await this.$func.queryAPI(param, data)

      console.log("ENTSORGUNG --- result")
      console.log(result)

      if(this.stock_id == ""){
        this.stock_id = result["value"]
      }

      this.checkbox = true
      this.checkbox_disable = true

    }







  },


  computed: {
    computedDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      return this.$moment(this.date).format("DD.MM.YYYY")
    },

    computedInvoiceDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      if(this.invoice_date == ""){
        return ""
      }else{
        return this.$moment(this.invoice_date).format("DD.MM.YYYY")
      }
    },

    computedInvoicePaymentDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      if(this.invoice_payment_date == ""){
        return ""
      }else{
        return this.$moment(this.invoice_payment_date).format("DD.MM.YYYY")
      }
    },

    computedInvoicePaymentDeadLine () {
      // return this.$moment(this.date).format('dd.mm.YYYY')

      if(this.invoice_payment_deadline == ""){
        return ""
      }else{
        return this.$moment(this.invoice_payment_deadline).format("DD.MM.YYYY")
      }

    },


  },




}
</script>

<style scoped>

</style>
