<template>
  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:addbutton="false"
        v-bind:default_limit="'500'"
        v-bind:default_sort="'pickup_timestamp'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:filter_clearing="true"
        v-bind:extension="'(pickup_date IS NOT NULL AND pickup_date<>\'\')'"
        v-bind:export_csv="true"

    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListClearing",

  components: {
    List,
  },

  created() {

  },

  data () {
    return {
      search: '',
      formview: 'FormClearing',
      table: 'task',
      filter:{
        selector: 'prio',
        value: '99',
      }

    }
  },

  computed: {
    headers () {
      return [
        { text: 'Tour', value: 'tour_id' },
        { text: 'Nr.', value: 'id' },
        { text: 'Abholdatum', value: 'pickup_timestamp' },
        { text: 'Auftragsnummer', value: 'document_no' },
        { text: 'Kundengruppe', value: 'group_main_text' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Anzahl', value: 'pickup_qty' },
        { text: 'Artikel', value: 'article_name' },
        { text: 'Gesamtbetrag', value: 'price_total' },
        { text: 'Restreifen', value: 'rest' },
        { text: 'Rechnung', value: 'invoice' },
        { text: '', value: 'edit' },
      ]
    },
  },



}
</script>

<style scoped>

</style>
