<template>
  <div
      style="
      width: 100%"
  >
    <div><h2>{{title}}</h2></div>
    <div>{{title2}}</div>

    <v-btn
    style="
    margin-top: 20px;
    "

    @click="customer = []"
    ><v-icon>mdi-delete</v-icon> RESET</v-btn>

<!--
    <div
        style="
        position: absolute;
        left: 400px;
        top: 0px;
        "
    >
      <b>Anteil an abgeholten Reifen</b>
      <cPie
          :height="150"
          :chartData="chartDataPie1"
      >

      </cPie>
    </div>
-->

    <div
        style="
  position: absolute;
  top: 40px;
  left: 350px;
  margin: 20px;"

    ><h1>ca. {{rating_average_in_percent}} %</h1>
      <p>⌀ Reifen Qualität (im Zeitraum)</p>
    </div>



    <div
        style="
  position: absolute;
  top: 40px;
  left: 600px;
  margin: 20px;"

    ><h1>{{total_qty}}</h1>
      <p>Reifen Gesamt Abgeholt (im Zeitraum)</p>
    </div>




    <div
        style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
    >

      <DialogDate
          v-bind:label="'Von'"
          v-bind:initial_date="date_from"
          v-bind:clearable="false"
          v-model="date_from"
      ></DialogDate>

      <DialogDate
          v-bind:label="'Bis'"
          v-bind:initial_date="date_to"
          v-bind:clearable="false"
          v-model="date_to"
      ></DialogDate>

    </div>




    <v-expansion-panels

        v-model="panel"
        multiple
    >


    <v-expansion-panel

    style="

    margin-top: 80px"
    >
      <v-expansion-panel-header>Kunden auswählen</v-expansion-panel-header>
      <v-expansion-panel-content>
        <List
            v-if="list"
            style="
            margin-bottom: 30px;
        "

            v-bind:headers="headers"
            v-bind:formview="''"
            v-bind:height="'500px'"
            v-bind:table="'group_type'"
            v-bind:default_limit="'1000'"
            v-bind:default_sort="'name'"
            v-bind:default_sort_typ="'ASC'"
            v-bind:addbutton="false"
            v-bind:extension="''"

            @add="add_customer"


        ></List>

      </v-expansion-panel-content>
    </v-expansion-panel>


    </v-expansion-panels>








    <v-overlay
        :value="overlay"
    >


      <v-progress-circular
          indeterminate
          color="black"
          style="margin: 20px;"
      ></v-progress-circular>


      Daten werden geladen...


    </v-overlay>


    <div
        v-if="renderComponent"
        style="

        margin-top: 50px;
        width: 100%;

"
    >


<CardAnalyticsGroup
    v-for="item in customer"
    :key="item.id"
    v-bind:group_type_id="item.id"
    v-bind:date_from="date_from"
    v-bind:date_to="date_to"

></CardAnalyticsGroup>


    </div>



    <v-snackbar
        color="pink"
        v-model="snackbar"
        :timeout="2000"
    >
      Kunde wird bereits angezeigt!

    </v-snackbar>



</div>
</template>

<script>


import List from "@/components/ListDefault";
import DialogDate from "@/components/DialogDate";
import CardAnalyticsGroup from "@/components/CardAnalyticsGroup";
import cPie from "@/components/cPie.vue";

export default {
  name: "AnalyticsGroup",

  components:{
    List,
    DialogDate,
    CardAnalyticsGroup,
    cPie,
  },

  data() {
    return {

      title: "Kundengruppe Info",
      title2: "Anzahl der Abgeholten Reifen, Leerfahrten, Qualität",

      chartData: Object,

      sync: true,
      snackbar: false,

      list: false,

      customer: [],
      renderComponent: true,
      overlay: false,

      default_range_in_days: 350,
      rating_average: 0,
      rating_average_in_percent: 0,

      chartDataPie1: '',
      chartDataPie2: '',

      panel: [0],

      total_qty: 0,

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },


  async created(){
    this.sync = true


    this.overlay = true

    this.customer = []

    console.log("loadData created: " + this.sync)

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")


    await this.loadData()

    this.list = true



    this.overlay = false

    this.sync = false
  },


  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.loadData()

    },




  },


  methods:{

    async getRating(id){


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        // extension: "SELECT AVG(rating), sum(pickup_qty), count(*) FROM task_items WHERE (group_type_id='" + id + "' AND wasteno='160103') AND pickup_qty IS NOT NULL AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
        extension: "SELECT SUM(rating * pickup_qty) / SUM(pickup_qty) AS gewichteter_durchschnitt, count(*) FROM task_items WHERE (group_type_id='" + id + "' AND wasteno='160103') AND pickup_qty IS NOT NULL AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }

      console.log(param1.extension)


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)



        // Einstellungen
        var param2 = {
            table: "task",
            statement: "custom",
            type: "",
            where: "false",
            selector: "",
            value: "",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "SELECT sum(pickup_qty), count(*) FROM task WHERE group_type_id='" + id + "' AND pickup_qty IS NOT NULL AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
        }


        // Daten -> Werden nur bei Insert oder Update gesendet
        var data2 = {}

        var result2 = await this.$func.queryAPI(param2, data2)


      var rating = "???"
      var rating_in_percent = "???"
      var pickup_num = "0"
      var pickup_qty = "0"

      if(result1.length != 0){
        // rating = Number(result1[0]["AVG(rating)"]).toFixed(2)
        rating = Number(result1[0]["gewichteter_durchschnitt"]).toFixed(2)
      }



      // Einstellungen
      var param4 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data4 = {}

      var result4 = await this.$func.queryAPI(param4, data4)


      switch (Math.round(rating)) {
        case 1:
          rating_in_percent = result4[0]["wert"] + " %"
          break;
        case 2:
          rating_in_percent = result4[1]["wert"] + " %"
          break;
        case 3:
          rating_in_percent = result4[2]["wert"] + " %"
          break;
        case 4:
          rating_in_percent = result4[3]["wert"] + " %"
          break;
        case 5:
          rating_in_percent = result4[4]["wert"] + " %"
          break;
        default:
          rating_in_percent = "0"
      }




        if(result2.length != 0){
            pickup_qty = Number(result2[0]["sum(pickup_qty)"])
            pickup_num = Number(result2[0]["count(*)"])
        }

      if(pickup_num === "" || pickup_num == null){
        pickup_num = "0"
      }

      if(pickup_qty === "" || pickup_qty == null){
        pickup_qty = "0"
      }

      // Einstellungen
      var param3 = {
        table: "group_type",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {
        rating: rating,
        rating_in_percent: rating_in_percent,
        pickup_num: pickup_num,
        pickup_qty: pickup_qty,
      }

      var result3 = await this.$func.queryAPI(param3, data3)

      console.log("result3")
      console.log(result3)



    },



    async updateAllGroup(){

      // Einstellungen
      var param1 = {
        table: "group_type",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

      for (var i99 = 0; i99 < result1.length; i99++) {

        await this.getRating(result1[i99]["id"])

      }

    },


    async calcAverageRating(){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "task_items",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(rating) FROM task_items WHERE (pickup_qty IS NOT NULL AND wasteno='160103') AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "'",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      this.rating_average = Number(result1[0]["AVG(rating)"]).toFixed(2)



      // Einstellungen
      var param2 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

     // console.log("result2")
     // console.log(result2)


      switch (Math.round(this.rating_average)) {
        case 1:
          this.rating_average_in_percent = result2[0]["wert"]
          break;
        case 2:
          this.rating_average_in_percent = result2[1]["wert"]
          break;
        case 3:
          this.rating_average_in_percent = result2[2]["wert"]
          break;
        case 4:
          this.rating_average_in_percent = result2[3]["wert"]
          break;
        case 5:
          this.rating_average_in_percent = result2[4]["wert"]
          break;
        default:
          this.rating_average_in_percent = "XX"
      }


    },



    async loadPie(){


      this.chartDataPie1 =  {
        labels: [],
        datasets: [
          {
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")



      // Einstellungen
      var param2 = {
        table: "",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT group_type_id, sum(pickup_qty)  FROM task WHERE pickup_qty IS NOT NULL AND group_type_id <> '' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' GROUP BY group_type_id ORDER BY sum(pickup_qty) DESC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}
      var result2 = await this.$func.queryAPI(param2, data2)


      console.log("result2 - Analytics Group")
      console.log(result2)

      this.total_qty = 0


      for (var i99 = 0; i99 < result2.length; i99++) {

        this.total_qty = Number(this.total_qty) + Number(result2[i99]["sum(pickup_qty)"])

        console.log(result2[i99]["sum(pickup_qty)"])

         // this.chartDataPie1["labels"][i99] = await this.getGroupName(result2[i99]["group_type_id"])
         // this.chartDataPie1["datasets"][0]["data"].push(result2[i99]["sum(pickup_qty)"])

      }



      console.log("this.total_qty")
      console.log(this.total_qty)


      // this.total_qty = Number(this.total_qty).toLocaleString('de-DE')


      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

    },



    async getGroupName(id){
      console.log("getGroupName: " + id)

      if(id == "" && id == undefined){
        return ""
      }


      // Einstellungen
      var param1 = {
        table: "group_type",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}
      var result1 = await this.$func.queryAPI(param1, data1)

      console.log(result1)

      if(result1.length == 0){
        return ""
      }

      return result1[0]["name"]

    },






    async loadData(){

      this.overlay = true

      this.list = false



      await this.updateAllGroup()

      await this.calcAverageRating()

      await this.loadPie()

      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

      this.list = true
      this.overlay = false


    },

    async add_customer(n){
      var hasMatch =false;

      for (var index = 0; index < this.customer.length; ++index) {

        var check = this.customer[index];

        if(check.id == n){
          hasMatch = true;
          break;
        }
      }

      if(hasMatch == false){
        this.customer.push({id: n})
      }else{
        this.snackbar = true
      }


    }



  },



  computed: {
    headers () {
      return [
        { text: 'Untergruppe', value: 'name' },
        { text: 'Kundengruppe', value: 'group_main_name' },
        { text: '∅ Gutreifenanteil in %', value: 'rating_in_percent' },
        { text: 'Anzahl der Abholungen (Positionen)', value: 'pickup_num' },
        { text: 'Anzahl der Abgeholten Reifen', value: 'pickup_qty' },
        { text: '', value: 'select' },
      ]
    },
  },




}
</script>

<style scoped>

</style>
