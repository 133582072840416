<template>

  <v-layout

  >


    <!-- Auftrag Details -->

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >


        <v-text-field
              v-model="company"
              sm="12"
              md="4"
              label="Firma"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

        ></v-text-field>

        <v-text-field
              v-model="street"
              sm="12"
              md="4"
              label="Strasse"
              readonly
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

        ></v-text-field>

        <v-text-field
              v-model="zipcode"
              sm="12"
              md="4"
              label="PLZ"
              readonly
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
              v-model="city"
              sm="12"
              md="4"
              label="Ort"
              readonly
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

          <v-text-field
              v-model="email"
              sm="12"
              md="4"
              label="E-Mail"
              readonly
              outlined
              type="search"
              autocomplete="off"
              style="
      display: table;
      margin: 0 auto;
      width: 350px;"

          ></v-text-field>

        <v-text-field
              v-model="phone"
              sm="12"
              md="4"
              label="Telefon"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz / Auftrags Nr. (Optional)"
            readonly
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


<div
style="padding: 10px;"
></div>



        <v-switch
            v-model="invoice"
            label="Rechnung geschrieben"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>

        <v-text-field
            v-model="invoice_no"
            sm="12"
            md="4"
            label="Rechnungsnummer"
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>




        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen zum Auftrag"
            value=""
            style="
            margin-top: 30px;
      display: table;
      margin: 30px auto;
      width: 350px;"
        ></v-textarea>

        <!--
        </v-col>
        -->

        <v-col
            sm="12"
            md="12"
            class="text-right"
        >


          <v-btn
              color="#383083"
              elevation="2"
              outlined
              style="
              float: left;
              margin-right: 40px"
              @click="btnAddTask"
          >Neuer Auftrag</v-btn>




          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>




    <!-- Abhol Details -->

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">Abhol Details</h3>
        </div>
      </v-card-title>



      <v-container
      fluid

      >

        <v-form
            autocomplete="off"
        >

          <v-text-field
              v-model="pickup_date"
              sm="12"
              md="4"
              label="Abholdatum"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="pickup_time"
              sm="12"
              md="4"
              label="Abholzeit"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>


          <v-text-field
              v-model="pickup_qty"
              sm="12"
              md="4"
              label="Abgeholte Stückzahl"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>

          <v-text-field
              v-model="rest"
              label="Restmenge"

              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>


          <v-text-field
              v-model="rest_before"
              label="Restmenge Ursprünglich"

              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>


          <v-text-field
              v-model="price_total"
              label="Gesamtbetrag"
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>


          <v-textarea
              v-model="pickup_note"
              outlined
              label="Bemerkungen vom Fahrer"
              readonly
              value=""
              style="
            margin-top: 30px;
      display: table;
      margin: 30px auto;
      width: 350px;"
          ></v-textarea>

          <v-text-field
              v-model="driver_name"
              sm="12"
              md="4"
              label="Fahrer"
              readonly
              required
              outlined
              type="search"
              autocomplete="off"
              style="
          display: table;
          margin: 0 auto;
          width: 350px;"

          ></v-text-field>


        </v-form>

        <v-row
            class="justify-center"
            align="center"
            justify="center"
        >


          <v-card
            outlined
            elevation="0"

            class="justify-center"
            justify="center"
            style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
        "
            v-for="item in items"
            :key="item.id"
        >
          <v-card-title>{{item.article_name}}</v-card-title>


          <v-card-text>

            <v-row>

              <v-text-field
                  class="centered-input text--darken-3 mt-3"
                  outlined
                  disabled
                  label="Angemeldet"
                  typ="number"
                  :value="item.order_qty"
                  style="
            text-align: center;
            width: 70px;"
              >

              </v-text-field>

              <v-text-field
                  class="centered-input text--darken-3 mt-3"
                  outlined
                  disabled
                  label="Abgeholt"
                  typ="number"
                  style="width: 70px;"
                  :value="item.pickup_qty"
              >

              </v-text-field>


              <v-text-field
                  class="centered-input text--darken-3 mt-3"
                  outlined
                  disabled
                  label="Preis pro Stück"
                  typ="number"
                  style="width: 70px;"
                  :value="Number(item.price).toFixed(2)"
              >

              </v-text-field>

            </v-row>

          </v-card-text>
<div
style="padding: 15px;"
>
            <div v-if="item.rating == 1">Qualität: Sehr Schlecht {{ rating1 }}% <v-icon style="color: red">mdi-emoticon-frown-outline</v-icon></div>
            <div v-if="item.rating == 2">Qualität: Schlecht {{ rating2 }}% <v-icon style="color: orange">mdi-emoticon-sad-outline</v-icon></div>
            <div v-if="item.rating == 3">Qualität: Neutral {{ rating3 }}% <v-icon style="color: yellow">mdi-emoticon-neutral-outline</v-icon></div>
            <div v-if="item.rating == 4">Qualität: Gut {{ rating4 }}% <v-icon style="color: lime">mdi-emoticon-happy-outline</v-icon></div>
            <div v-if="item.rating == 5">Qualität: Sehr gut {{ rating5 }}% <v-icon  style="color: green">mdi-emoticon-outline</v-icon></div>
</div>
        </v-card>


        </v-row>

      </v-container>



      <v-spacer />


      <div
      style="margin: 30px"
      ></div>

      <v-btn
      outlined
      color="black"
      @click="createUebergabeschein"
      >
        Übergabeschein
      </v-btn>



    </v-card>



    <!-- Bilder -->


    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">Bilder</h3>
        </div>
      </v-card-title>


      <v-card-text>

        <cImageUpload
            v-if="renderComponent"

            v-bind:value="task_id"
            v-bind:selector="'task_id'"
            v-bind:table="'images'"
        ></cImageUpload>

      </v-card-text>

    </v-card>



    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
       :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>



  </v-layout>


</template>

<script>

import cImageUpload from '@/components/cImageUpload'
import Vue from "vue";


export default {
  name: "FormClearing",

  components: {
    cImageUpload,
  },

  data () {
    return {

      title: 'Auftrag Details',
      mainview: 'ListClearing',
      table: 'task',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',

      timeout: 2500,

      renderComponent: true,

      id: '',
      temp_task_id: '',
      task_id: '',

      customer_id: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      email: '',
      note: '',

      date: '',
      time: '',

      items: [],

      driver_id: '',
      driver_name: '',
      driver_sign_text: '',

      pickup_date: '',
      pickup_time: '',
      pickup_qty: '',
      pickup_note: '',

      reported_qty: 0,

      group_type_id: "",
      group_type_text: "",

      img_sign_name: '',

      rest: '',
      rest_before: '',

      document_no: '',
      invoice: '',
      invoice_no: '',
      price_total: '',

      colors: ['red', 'orange', 'yellow', 'lime', 'green'],
      rating: 0,
      rating_in_percent: '',

      rating1: 0,
      rating2: 0,
      rating3: 0,
      rating4: 0,
      rating5: 0,

    }
  },


  async created() {
    console.log("FromClearing")

     await this.getRatingValues()

     this.date = this.$moment(Date.now()).format('DD.MM.YYYY')

    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id
      await this.loadData()
    }else{
      this.$router.push({ name: 'Dashboard' })
    }


  },


  watch: {


  },


  methods:{

    async getRatingValues(){

      // Einstellungen
      var param2 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

      // console.log("result2")
      // console.log(result2)

      this.rating1 = result2[0]["wert"]
      this.rating2 = result2[1]["wert"]
      this.rating3 = result2[2]["wert"]
      this.rating4 = result2[3]["wert"]
      this.rating5 = result2[4]["wert"]

    },




    async createUebergabeschein(){

      var param = {
        vorlage_name: "uebergabeschein.png",
        pdf_autor: "Hüska GmbH",
        pdf_name: "temp.pdf",
        pdf_title: "Übergabeschein",
        pdf_subject: "",
        pdf_name_crypt: "1234.pdf",
      }

      var data = {


        text: {
          0: {
            text: this.company.substring(0,50),
            x: "10",
            y: "40",
            },
          1: {
            text: this.street,
            x: "10",
            y: "50",
          },
          2: {
            text: this.zipcode,
            x: "10",
            y: "55",
          },
          3: {
            text: this.city,
            x: "25",
            y: "55",
          },
          4: {
            text: this.pickup_date,
            x: "80",
            y: "80",
          },

          5: {
            text: "Übergabeschein -Nr.: " + this.id, // Gemeldete Reifen // this.reported_qty
            x: "10",
            y: "23",
          },

          6: {
            text: this.group_type_text,   // Abgeholte Reifen // this.pickup_qty
            x: "135",
            y: "77",
          },



          /*
          5: {
            text: "", // Gemeldete Reifen // this.reported_qty
            x: "80",
            y: "163",
          },

          6: {
            text: "",   // Abgeholte Reifen // this.pickup_qty
            x: "80",
            y: "175",
          },
           */
          7: {
            text: this.rest_before,         // Restreifen
            x: "80",
            y: "187",
          },
          8: {
            text: this.pickup_note,
            x: "10",
            y: "212",
          },
          9: {
            text: "", // Unterschrift Kunde
            x: "10",
            y: "245",
          },
          10: {
              text: "gez. " + this.driver_sign_text, // Unterschrift Fahrer
              x: "150",
              y: "245",
            },
          11: {
            text: "",
            x: "0",
            y: "5",
          },
          12: {
            text: "",
            x: "0",
            y: "5",
          },
          13: {
            text: "",
            x: "0",
            y: "5",
          },
          14: {
            text: "",
            x: "0",
            y: "5",
          },
          15: {
            text: "",
            x: "0",
            y: "5",
          }



    },


        image: {
          0: {
            name: this.img_sign_name,
            x: "10",
            y: "225",
            w: "40",
            h: "25",
          },

        },




      }



      /* Reifen Positionen werden hinzugefügt */

      var y = 110

      for (var i1 = 0; i1 <  this.items.length; i1++) {

        data["text"][11 + i1].text = this.items[i1]["order_qty"] + "                                        " + this.items[i1]["pickup_qty"]  + "                              " + this.items[i1]["article_name"]  + "                     " + "160103"
        data["text"][11 + i1].x = "10"
        data["text"][11 + i1].y = y

        y = Number(y) + 10

      }

      var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));
      console.log(Vue.prototype.$api_pdf_endpoint + "?" + request)

      const config = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }

      try {

        await Vue.prototype.$axios
            .get(Vue.prototype.$api_pdf_endpoint + "?" + request, config)
            .then(response => (this.responseText = response))

        // console.log(this.responseText)

        let blob = new Blob([this.responseText.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)

        window.open(url) // Mostly the same, I was just experimenting with differen


        // var random_string = Math.random().toString(32).substring(2, 5) + Math.random().toString(32).substring(2, 5);
        // return this.responseText["data"]

      }catch{

        return {"status": "404"}

      }

    },


    btnAddTask(){

      console.log("btnAddTask")

      localStorage.loadCustomer = this.customer_id
      this.$route.params.id = ""

            localStorage.rest_qty = this.rest
            localStorage.rest_document_no = this.document_no
            localStorage.rest_task_id = this.id
            localStorage.rest_customer_id = this.customer_id


      this.$router.push({ name: "FormTask" })

    },

    btnCancel(){
      this.$router.push({ name: this.mainview })
    },

    async saveData(){
      console.log("saveData")
      console.log(this.customer_id)



      console.log("invoice: " + this.invoice)
      console.log("invoice_no: " + this.invoice_no)

      if(this.invoice == true){
        if(this.invoice_no == "" || this.invoice_no == null){
          this.snackbar_title = "Bitte Rechnungsnummer eingeben!"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }
      }


      // Parameter
      var param = {
        table: "task",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        invoice: this.invoice,
        invoice_no: this.invoice_no,
        price_total: this.price_total,
        note: this.note,
        rest: this.rest,

        worker_clearing: localStorage.username,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.snackbar_title = "Auftrag gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true

    },

    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      console.log("TASK ID: " + result[0]["id"])

      this.task_id = result[0]["id"]

      // Image Componente neu  laden

      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });


      this.customer_id = result[0]["customer_id"]
      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]
      this.email = result[0]["email"]
      this.note = result[0]["note"]

      this.temp_task_id = result[0]["temp_task_id"]
      this.pickup_date = result[0]["pickup_date"]
      this.pickup_time = result[0]["pickup_time"]
      this.pickup_qty = result[0]["pickup_qty"]
      this.pickup_note = result[0]["pickup_note"]
      this.rest = result[0]["rest"]
      this.rest_before = result[0]["rest_before"]

      this.document_no = result[0]["document_no"]

      this.group_type_id = result[0]["group_type_id"]


      if(result[0]["invoice"] == "" || result[0]["invoice"] == null){
        this.invoice = false
      }else{
        this.invoice = result[0]["invoice"]
      }

      this.invoice_no = result[0]["invoice_no"]
      this.price_total = result[0]["price_total"]

      this.driver_name = result[0]["driver_name"]
      this.driver_id = result[0]["driver_id"]

      if(this.rest == ""){
        this.rest = 0
      }


      // Einstellungen
      param = {
        table: "driver",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.driver_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      this.driver_sign_text = result[0]["sign_text"]



      // Einstellungen
      param = {
        table: "group_type",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.group_type_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.group_type_text = result[0]["text"]


      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {

        this.reported_qty = Number(this.reported_qty) + Number(result[i1]["order_qty"])

        this.items.push({id:  result[i1]["id"], rating: result[i1]["rating"], order_qty: result[i1]["order_qty"], pickup_qty: result[i1]["pickup_qty"], article_name: result[i1]["article_name"], price: result[i1]["price"]})

      }



      // Einstellungen
      param = {
        table: "images",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM images WHERE task_id='" + this.task_id + "' AND type='signatur'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log("images")
      console.log(result)

      if(result.length == 0){
        return
      }


      this.img_sign_name = result[0]["name"]


    },

    genColor (i) {
      return this.colors[i]
    },

    genIcon (i) {

      console.log(i)

      if(i == 0){
        return "mdi-emoticon-outline"
      }
      if(i == 5){
        return "mdi-emoticon-outline"
      }
      if(i == 4){
        return "mdi-emoticon-happy-outline"
      }
      if(i == 3){
        return "mdi-emoticon-neutral-outline"
      }
      if(i == 2){
        return "mdi-emoticon-sad-outline"
      }
      if(i == 1){
        return "mdi-emoticon-frown-outline"
      }

    },


  }


}
</script>

<style scoped>

</style>
