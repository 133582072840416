<template>
<div>

<v-card>

  <v-card-text>
    ERLEDIGTE AUFTRÄGE (NICHT ABGERECHNET) - TOP 4
  </v-card-text>

  <cPie
      v-if="renderComponent"

      :height="300"
      :chartData="chartData"
  >

  </cPie>

</v-card>


</div>
</template>

<script>

import cPie from "../components/cPie"


export default {

  name: "CardSumToPayPie",

  components: {

    cPie,


  },

  data() {

    return {

      renderComponent: false,


      chartData: {
        labels: ['Test1', 'Test2', 'Test3', 'Test4'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [1, 2, 3, 4]
          }
        ]
      },


    }

  },


  created() {

    this.loadData()

  },


  methods:{

    async loadData(){
      console.log("loadData")

      var extension = "SELECT sum(price_total), group_type_text FROM task WHERE price_total<>'NaN' AND pickup_date IS NOT NULL AND (invoice <> '1' OR invoice IS NULL) GROUP BY group_type_text ORDER BY sum(price_total) DESC"


      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: extension,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      console.log(result)

      console.log(this.chartData)


      if(result.length > 4 ){

      this.chartData["labels"][0] = result[0]["group_type_text"]
      this.chartData["labels"][1] = result[1]["group_type_text"]
      this.chartData["labels"][2] = result[2]["group_type_text"]
      this.chartData["labels"][3] = result[3]["group_type_text"]

      this.chartData["datasets"][0]["data"][0] = result[0]["sum(price_total)"]
      this.chartData["datasets"][0]["data"][1] = result[1]["sum(price_total)"]
      this.chartData["datasets"][0]["data"][2] = result[2]["sum(price_total)"]
      this.chartData["datasets"][0]["data"][3] = result[3]["sum(price_total)"]



      this.renderComponent = false;


      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

      }


    }




  }






}
</script>

<style scoped>

</style>
