<template>

  <v-dialog
      ref="dialog1"
      v-model="modal1"
      :return-value.sync="date"
      persistent
      width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="computedDate"
          :label="label"
          prepend-icon="mdi-calendar"
          :clearable="clearable"
          :disabled="disabled"
          readonly
          v-bind="attrs"
          v-on="on"
          style="
                  margin: 0 auto;
                  width: 350px;"


          @click:clear="callItBack()"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        color="pink"
        scrollable
        locale="de-de"
        :first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="pink"
          @click="modal1 = false"
      >
        Abbrechen
      </v-btn>
      <v-btn
          text
          color="pink"
          @click="$refs.dialog1.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>

</template>

<script>
export default {
  name: "DialogDate",

  data () {
    return {

      modal1: '',
      date: '',

    }
  },

  created() {

    // console.log("this.initial_date: " + this.initial_date)

    if(this.initial_date != ""){
      this.date = this.$moment(this.initial_date, "DD.MM.YYYY").format("YYYY-MM-DD")
    }

  },

  methods:{

    callItBack(){
      this.date = ""
      // this.$emit('input', "")
    }

  },


  watch:{


    date(){
      // console.log(this.date)
    },

    initial_date(){
      this.date = this.$moment(this.initial_date, "DD.MM.YYYY").format("YYYY-MM-DD")
    }


  },


  computed: {
    computedDate() {

      if(this.date == "" || this.date == "Invalid date"){
        // console.log("date == Null")
        this.$emit('input', "")
        return
      }

      this.$emit('input', this.$moment(this.date, "YYYY-MM-DD").format("DD.MM.YYYY"))
      return this.$moment(this.date, "YYYY-MM-DD").format("DD.MM.YYYY")
    },
  },


  props:{

    initial_date:{
      type: String,
      default: null,
    },

    label:{
      type: String,
      default: "Datum"
    },

    clearable:{
      type: Boolean,
      default: false
    },

    disabled:{
      type: Boolean,
      default: false
    }



  },

  model: {
    prop: 'value',
    event: 'input'
  },



}
</script>

<style scoped>

</style>
