<template>
  <div
      style="
width: 100%;
height: 2000px"
  >

    <div><h2>{{title}}</h2></div>
    <div>{{title2}}</div>


    <div
        style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
    >

      <DialogDate
          v-bind:label="'Von'"
          v-bind:initial_date="date_from"
          v-bind:clearable="false"
          v-model="date_from"
      ></DialogDate>

      <DialogDate
          v-bind:label="'Bis'"
          v-bind:initial_date="date_to"
          v-bind:clearable="false"
          v-model="date_to"
      ></DialogDate>

      <!--
          <div

          >
            <v-card
                class="mx-auto"
                width="300"
                height="500"
                style="margin: 15px;"

            >
              <v-card-title>ZUSAMMENFASSUNG</v-card-title>
              <div
                  style="margin: 20px;"
              >

              </div>

            </v-card>


          </div>

          -->


    </div>

    <div v-if="renderComponent">

      <div
          style="
        position: absolute;
        left: 80px;
        top: 80px;
        "
      >
        <b>Transport in Tonnen</b>
        <!--
        <cPie
            v-if="Chart"

            :height="250"
            :chartData="chartDataPie1"
        >

        </cPie>
        -->
        <Bar
            v-if="Chart"

            :chart-options="chartOptions"
            :chart-data="chartDataPie1"
            :chart-id="'1'"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="600"
            :height="300"
        />


      </div>

      <div
          style="
        position: absolute;
        left: 80px;
        top: 400px;
        "
      >
        <b>Transportkosten in EUR</b>


        <Bar
            v-if="Chart"

            :chart-options="chartOptions"
            :chart-data="chartDataPie2"
            :chart-id="'2'"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="600"
            :height="300"
        />



      </div>

    </div>


    <div
        style="
  position: absolute;
  left: 60px;
  top: 800px;
  "
    >

      <div
          style="margin: 20px;"
      >
        <p><b>Zusammenfassung</b></p>
      </div>
      <table
          style="width: 800px"
      >
        <thead>
        <tr>
          <th class="text-center"
              style="width: 400px;"
          >
            Name
          </th>
          <th class="text-center"
              style="width: 200px;"
          >
            Transportkosten in EUR
          </th>
          <th class="text-center"
              style="width: 200px;"
          >
            Anzahl der Transporte
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in disposer_table"
            :key="item.name"
        >
          <td
              class="text-left"
          >{{ item.name }}</td>

          <td
              class="text-center"
          >{{ item.price }}</td>
          <td
              class="text-center"
          >{{ item.num }}</td>
        </tr>


        <tr
        >
          <td
              class="text-left"
          >&nbsp;</td>

          <td
              class="text-center"
          >&nbsp;</td>
          <td
              class="text-center"
          >&nbsp;</td>
        </tr>


        <tr
        >
          <td
              class="text-left"
          ><b>GESAMT:</b></td>
          <td
              class="text-center"
          ><b>{{ total_price }}</b></td>
          <td
              class="text-center"
          ><b>{{ total_num }}</b></td>
        </tr>


        </tbody>
      </table>

    </div>



  </div>
</template>

<script>
import cPie from "@/components/cPie.vue";
import DialogDate from "@/components/DialogDate.vue";
import {Bar} from "vue-chartjs/legacy";

export default {
  name: "AnalyticsTransport",


  components:{
    cPie,
    DialogDate,
    Bar,
  },

  data() {
    return {

      title: "Transport",
      title2: "",

      chartData: Object,

      sync: true,
      snackbar: false,

      disposer: [],
      disposer_table: [],
      disposal: [],
      renderComponent: true,
      overlay: false,

      default_range_in_days: 90,

      panel: [0],

      total_weight: 0,
      total_price: 0,
      total_num: 0,

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      Chart: true,

      chartDataPie1: '',
      chartDataPie2: '',

      chartOptions: {
        responsive: true
      },

      chartId: '1',
      datasetIdKey: '1',
      plugins: null,
      cssClasses: '',
      styles: null,

      date_from: "",
      date_to: "",

    }

  },

  async created() {

    this.sync = true

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()

    this.sync = false

  },


  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.total_price = 0
      this.total_weight = 0
      this.total_num = 0

      this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.total_price = 0
      this.total_weight = 0
      this.total_num = 0

      this.loadData()
    },

  },

  methods:{

    async loadData(){


      this.chartDataPie1 =  {
        labels: [],
        datasets: [
          {
            label: "in TONNEN",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }

      this.chartDataPie2 =  {
        labels: [],
        datasets: [
          {
            label: "in EUR",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")



      // Einstellungen
      var param0 = {
        table: "carrier",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT id, company FROM carrier",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data0 = {}

      var result0 = await this.$func.queryAPI(param0, data0)

      console.log("result0")
      console.log(result0)

      this.disposer = result0



      // Einstellungen
      var param1 = {
        table: "transport",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT id, date, date_timestamp, carrier_id, carrier_company, price, weight  FROM transport WHERE date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

      this.disposal = result1




      // Einstellungen
      var param2 = {
        table: "disposal",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT carrier_id, carrier_company, sum(price), sum(weight), count(*)  FROM transport WHERE date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY carrier_company ORDER BY sum(price) ASC",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)



      console.log("result2")
      console.log(result2)


      this.disposer_table = []

      for (var i97 = 0; i97 < result2.length; i97++){


        this.total_weight = Number(this.total_weight) + Number(result2[i97]["sum(weight)"])
        this.total_price = Number(this.total_price) + Number(result2[i97]["sum(price)"])
        this.total_num = Number(this.total_num) + Number(result2[i97]["count(*)"])



        this.disposer_table.push({id: result2[i97]["carrier_company"], name: result2[i97]["carrier_company"], weight: Number(result2[i97]["sum(weight)"]).toFixed(3), price: Number(result2[i97]["sum(price)"]).toFixed(2), num: result2[i97]["count(*)"]})
      }

      this.total_price = Number(this.total_price).toFixed(2)
      this.total_weight = Number(this.total_weight).toFixed(2)


      for (var i99 = 0; i99 < result2.length; i99++) {
        this.chartDataPie1["labels"].push(result2[i99]["carrier_company"])
        this.chartDataPie1["datasets"][0]["data"].push(result2[i99]["sum(weight)"])
      }

      for (var i98 = 0; i98 < result2.length; i98++) {
        this.chartDataPie2["labels"].push(result2[i98]["carrier_company"])
        this.chartDataPie2["datasets"][0]["data"].push(result2[i98]["sum(price)"])
      }

      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

    }


  }












}
</script>

<style scoped>

</style>
