<template>
<div
style="
width: 100%;
height: 2000px"
>

  <div><h2>{{title}}</h2></div>
  <div>{{title2}}</div>

  <div
      style="
          position: absolute;
          right: 450px;
          top: 0px;
        "
  >
    <v-select
        label="Abfallschlüssel"
        v-model="filter_wasteno_selected"
        :items="wasteno_list"
        @change="loadData()"
        style="
        display: table;
        margin: 0 auto;
        width: 350px;"
    >

    </v-select>
  </div>

  <div
      style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
  >

    <DialogDate
        v-bind:label="'Von'"
        v-bind:initial_date="date_from"
        v-bind:clearable="false"
        v-model="date_from"
    ></DialogDate>

    <DialogDate
        v-bind:label="'Bis'"
        v-bind:initial_date="date_to"
        v-bind:clearable="false"
        v-model="date_to"
    ></DialogDate>





<!--
    <div

    >
      <v-card
          class="mx-auto"
          width="300"
          height="500"
          style="margin: 15px;"

      >
        <v-card-title>ZUSAMMENFASSUNG</v-card-title>
        <div
            style="margin: 20px;"
        >

        </div>

      </v-card>


    </div>

    -->


  </div>

  <div v-if="renderComponent">

<div
    style="
        position: absolute;
        left: 80px;
        top: 80px;
        "
>
  <b>Entsorgungen in Tonnen</b>

  <!--
  <cPie
      v-if="Chart"

      :height="250"
      :chartData="chartDataPie1"
  >

  </cPie>
  -->

  <Bar
      v-if="Chart"

      :chart-options="chartOptions"
      :chart-data="chartDataPie1"
      :chart-id="'1'"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="600"
      :height="300"
  />

</div>

  <div
      style="
        position: absolute;
        left: 80px;
        top: 400px;
        "
  >
   <b>Entsorgungsgebühren in EUR</b>


    <Bar
        v-if="Chart"

        :chart-options="chartOptions"
        :chart-data="chartDataPie2"
        :chart-id="'2'"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="600"
        :height="300"
    />



  </div>


    <div
        style="
        position: absolute;
        left: 800px;
        top: 140px;
        "
    >
      <b>Ø ENTSORGUNGSKOSTEN PRO TONNE</b>
      <div
      style="margin-top: 40px;"
      ></div>
      <p>Einnahmen: {{average_cost_positive}}</p>
      <p>Ausgaben: {{average_cost_negative}}</p>





    </div>

  </div>


  <div
      style="
  position: absolute;
  left: 60px;
  top: 800px;
  "
  >

    <div
        style="margin: 20px;"
    >
      <p><b>Zusammenfassung</b></p>
    </div>
    <table
        style="width: 800px"
    >
      <thead>
      <tr>
        <th class="text-center"
            style="width: 400px;"
        >
          Name
        </th>
        <th class="text-center"
            style="width: 200px;"
        >
          Entsorgung in Tonnen
        </th>
        <th class="text-center"
            style="width: 200px;"
        >
          Entsorgungskosten
        </th>
        <th class="text-center"
            style="width: 200px;"
        >
          Anzahl der Entsorgungen
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in disposer_table"
          :key="item.name"
      >
        <td
            class="text-left"
        >{{ item.name }}</td>
        <td
            class="text-center"
        >{{ item.weight }}</td>
        <td
            class="text-center"
        >{{ item.price }}</td>
        <td
            class="text-center"
        >{{ item.num }}</td>
      </tr>


      <tr
      >
        <td
            class="text-left"
        >&nbsp;</td>
        <td
            class="text-center"
        >&nbsp;</td>
        <td
            class="text-center"
        >&nbsp;</td>
        <td
            class="text-center"
        >&nbsp;</td>
      </tr>


      <tr
      >
        <td
            class="text-left"
        ><b>GESAMT:</b></td>
        <td
            class="text-center"
        ><b>{{ total_weight }}</b></td>
        <td
            class="text-center"
        ><b>EINNAHMEN: {{ total_price_postive }} AUSGABEN: {{ total_price_negative }} GESAMT: {{ total_price_gesamt }}</b></td>
        <td
            class="text-center"
        ><b>{{ total_num }}</b></td>
      </tr>


      </tbody>
    </table>

  </div>



</div>
</template>

<script>

import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)





import cPie from "@/components/cPie.vue";
import DialogDate from "@/components/DialogDate";

export default {
  name: "AnalyticsDisposal",

  components:{
    cPie,
    DialogDate,
    Bar,
  },

  data() {
    return {

      title: "Entsorgungen",
      title2: "",

      chartData: Object,

      sync: true,
      snackbar: false,

      disposer: [],
      disposer_table: [],
      disposal: [],
      renderComponent: true,
      overlay: false,

      default_range_in_days: 90,

      panel: [0],

      total_weight: 0,
      total_price: 0,
      total_price_postive: 0,
      total_price_negative: 0,
      total_price_gesamt: 0,
      total_num: 0,

      filter_wasteno_selected: "",
      wasteno_list: [],

      average_cost_positive: 0,
      average_cost_negative: 0,

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      Chart: true,

      chartDataPie1: '',
      chartDataPie2: '',

      chartOptions: {
        responsive: true
      },

      chartId: '1',
      datasetIdKey: '1',
      plugins: null,
      cssClasses: '',
      styles: null,


      date_from: "",
      date_to: "",

    }

  },

  async created() {

    this.sync = true

    await this.getAllWasteNo()
    this.filter_wasteno_selected = "160103"


    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()

    await this.loadAverageCost()

    this.sync = false



  },




  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

      this.total_price = 0
      this.total_weight = 0
      this.total_num = 0

      this.total_price_postive = 0
      this.total_price_negative = 0

      this.loadData()
    },

    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

      this.total_price = 0
      this.total_weight = 0
      this.total_num = 0

      this.total_price_postive = 0
      this.total_price_negative = 0

      this.loadData()
    },

  },

  methods:{


    // Ermittel alle Abfallnummern
    async getAllWasteNo(){

      console.log("getAllWasteNo")

      // Einstellungen
      var param = {
        table: "article",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT wasteno  FROM `article` GROUP BY wasteno"

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      for (var i1 = 0; i1 < result.length; i1++) {


        if(result[i1]["wasteno"] != "0"){
          this.wasteno_list.push(result[i1]["wasteno"])
        }


      }

      console.log(this.wasteno_list)


    },




    async loadAverageCost(){

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      // Einstellungen
      var param1 = {
        table: "disposal",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(price), SUM(price), SUM(weight) FROM disposal WHERE wasteno='" + this.filter_wasteno_selected + "' AND price_positive='true' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }

      // console.log(param1.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      var test1


      this.average_cost_positive = "0.00 EUR / T " + "- GEWICHT: 0.000 TONNEN - GUTSCHRIFT: 0.00 EUR"



      if(result1[0]["AVG(price)"] === ""){
        result1[0]["AVG(price)"] = 0
      }

      if(result1[0]["SUM(price)"] === null){
        result1[0]["SUM(price)"] = 0
        test1 = 0
      }else{
        test1 = Number(Math.abs(result1[0]["SUM(price)"])).toFixed(2) / Number(Math.abs(result1[0]["SUM(weight)"])).toFixed(3)

      }

      if(result1[0]["SUM(weight)"] === ""){
        result1[0]["SUM(weight)"] = 0
      }


      this.average_cost_positive = Number(test1).toFixed(2) + " EUR / T " + "- GEWICHT: " + Number(Math.abs(result1[0]["SUM(weight)"])).toFixed(3) + " TONNEN - GUTSCHRIFT: " + Number(Math.abs(result1[0]["SUM(price)"])).toFixed(2) + " EUR"


      // Einstellungen
      var param2 = {
        table: "disposal",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT AVG(price), SUM(price), SUM(weight) FROM disposal WHERE wasteno='" + this.filter_wasteno_selected + "' AND price_positive='false' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }

      console.log(param1.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

      console.log("result2")
      console.log(result2)

      this.average_cost_negative = "0.00" + " EUR / T " + "- GEWICHT: 0.000 TONNEN - KOSTEN: 0.00 EUR"



      console.log("AVG price NEGATIVE: " + result2[0]["AVG(price)"])
      console.log("SUM price NEGATIVE: " + result2[0]["SUM(price)"])


      if(result2[0]["AVG(price)"] == null){
        result2[0]["AVG(price)"] = 0
      }else{
        if(result2[0]["SUM(price)"] == null){
          result2[0]["SUM(price)"] = 0
        }

        if(result2[0]["SUM(weight)"] == null){
          result2[0]["SUM(weight)"] = 0
        }

        var test2
        test2 = Number(Math.abs(result2[0]["SUM(price)"])).toFixed(2) / Number(Math.abs(result2[0]["SUM(weight)"])).toFixed(3)
        this.average_cost_negative = Number(test2).toFixed(2) + " EUR / T " + "- GEWICHT: " + Number(Math.abs(result2[0]["SUM(weight)"])).toFixed(3) + " TONNEN - KOSTEN: " + Number(Math.abs(result2[0]["SUM(price)"])).toFixed(2) + " EUR"


      }







      // console.log("AVG price NEGATIVE: " + result2[0]["AVG(price)"])
      // this.average_cost_negative = Number(result2[0]["AVG(price)"]).toFixed(2)



    },

    async loadData(){

      this.total_weight = 0
      this.total_price = 0
      this.total_num = 0



      this.chartDataPie1 =  {
        labels: [],
        datasets: [
          {
            label: "in TONNEN",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }

      this.chartDataPie2 =  {
        labels: [],
        datasets: [
          {
            label: "in EUR",
            backgroundColor: ['green', 'blue', 'red', 'brown', 'orange', 'gray'],
            data: []
          }
        ]
      }


      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")



      this.loadAverageCost()


      // Einstellungen
      var param0 = {
        table: "disposer",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT id, company FROM disposer",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data0 = {}

      var result0 = await this.$func.queryAPI(param0, data0)

      this.disposer = result0



      // Einstellungen
      var param1 = {
        table: "disposal",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT id, date, date_timestamp, disposer_id, disposer_company, price, price_positive, weight FROM disposal WHERE wasteno='" + this.filter_wasteno_selected + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "'",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      this.disposal = result1




      // Einstellungen
      var param2 = {
        table: "disposal",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT disposer_id, disposer_company, sum(price), sum(weight), count(*)  FROM disposal WHERE wasteno='" + this.filter_wasteno_selected + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY disposer_company ORDER BY sum(price) DESC",
      }


      console.log(param0.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)


      this.disposer_table = []

      for (var i96 = 0; i96 < result2.length; i96++){
        this.disposer_table.push({id: result2[i96]["disposer_company"], name: result2[i96]["disposer_company"], weight: Number(result2[i96]["sum(weight)"]).toFixed(3), price: Number(result2[i96]["sum(price)"]).toFixed(2), num: result2[i96]["count(*)"]})
      }




      for (var i97 = 0; i97 < result1.length; i97++){

        if(result1[i97]["price_positive"] == "true"){
          // console.log("POSITIVE: " + result1[i97]["price"] + " = " + this.total_price_postive)
          this.total_price_postive = Number(this.total_price_postive) + Number(result1[i97]["price"])
        }else{
          // console.log("NEGATIVE: " + result1[i97]["price"] + " = " + this.total_price_negative)
          this.total_price_negative = Number(this.total_price_negative) + Number(result1[i97]["price"])
        }


        this.total_weight = Number(this.total_weight) + Number(result1[i97]["weight"])

        // this.total_weight = this.total_weight + Number(result1[i97]["sum(weight)"])
        // this.total_price = this.total_price + Number(result1[i97]["sum(price)"])
        this.total_num = result1.length


        // this.disposer_table.push({id: result2[i97]["disposer_company"], name: result2[i97]["disposer_company"], weight: Number(result2[i97]["sum(weight)"]).toFixed(3), price: Number(result2[i97]["sum(price)"]).toFixed(2), num: result2[i97]["count(*)"]})
      }

      this.total_price_postive = Number(this.total_price_postive).toFixed(2)
      this.total_price_negative = Number(this.total_price_negative).toFixed(2)
      this.total_weight = Number(this.total_weight).toFixed(2)


      this.total_price_gesamt = Number(this.total_price_postive) - Number(Math.abs(this.total_price_negative))



      for (var i99 = 0; i99 < result2.length; i99++) {
        this.chartDataPie1["labels"].push(result2[i99]["disposer_company"])
        this.chartDataPie1["datasets"][0]["data"].push(result2[i99]["sum(weight)"])
      }

      for (var i98 = 0; i98 < result2.length; i98++) {
        this.chartDataPie2["labels"].push(result2[i98]["disposer_company"])
        this.chartDataPie2["datasets"][0]["data"].push(result2[i98]["sum(price)"])
      }

      // Remove MyComponent from the DOM
      this.renderComponent = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

    }


  }

}
</script>

<style scoped>

</style>
