<template>
<div>
  <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"

      style="margin: 20px"
  />
</div>
</template>

<script>

import { Bar } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, annotationPlugin)



export default {
  name: "cBar",

  components: {Bar},

  data() {
    return {

/*
      chartData: {
 labels: [
    "Absence of OB",
    "Closeness",
    "Credibility",
    "Heritage",
    "M Disclosure",
    "Provenance",
    "Reliability",
    "Transparency"
  ],
  datasets: [
    {
      label: "American Express",
      backgroundColor: "pink",
      borderColor: "red",
      borderWidth: 1,
      data: [300, 500, 600, 400, 500, 600, 300, 200],

    },
    {
      label: "Mastercard",
      backgroundColor: "lightblue",
      borderColor: "blue",
      borderWidth: 1,
      data: [4, 7, 3, 6, 10,7,4,6]
    },
    {
      label: "Paypal",
      backgroundColor: "lightgreen",
      borderColor: "green",
      borderWidth: 1,
      data: [10,7,4,6,9,7,3,10]
    },
    {
      label: "Visa",
      backgroundColor: "yellow",
      borderColor: "orange",
      borderWidth: 1,
      data: [6,9,7,3,10,7,4,6]
    }
  ]
      },
*/

      chartOptions: {
        responsive: true,
        plugins: {
          annotation: {

            annotations: [
                /*
                {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y',
              value: this.annotation_durchschnitt,
              borderColor: 'green',
              borderWidth: 4,
              label: {
                backgroundColor: 'green',
                xAdjust: -200,
                enabled: true,
                content: '⌀ ' + this.annotation_durchschnitt
              }
            },

                 */

                /*
              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: this.annotation_soll,
                borderColor: 'red',
                borderWidth: 4,
                label: {
                  // xAdjust: -200,
                  backgroundColor: 'red',
                  enabled: true,
                  content: 'SOLL'
                }
              },
              */

              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: this.annotation_durchschnitt_atage,
                borderColor: 'green',
                borderWidth: 4,
                label: {
                  xAdjust: 200,
                  backgroundColor: 'green',
                  enabled: true,
                  content: '⌀ ' + this.annotation_durchschnitt_atage
                }
              },



            ],


          },



        legend: {
          display: false
        }
        },


      }
    }
  },


  created() {
    console.log("created")
    // console.log(this.chartData)
  },

  mounted() {




  },

  methods:{

  },

  props: {


    chartData: {
      type: Object,
      default: null,
    },


    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Object,
      default: () => {
      }
    },

    annotation_soll: Number,
    annotation_durchschnitt: Number,
    annotation_durchschnitt_atage: Number,


  }
}
</script>

<style scoped>

</style>
