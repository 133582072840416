<template>
    <div class="progressbar-container">
        <div class="progressbar" :style="{ width: progress + '%', backgroundColor: progressColor }"><div
            style="
        position: absolute;
        margin-top: 4px;
        width: 100%;
        text-align: center;

        "
        ><b>{{ Number(progress).toFixed(0) }} %</b></div></div>
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true
        }
    },
    computed: {
        progressColor() {
            if (this.progress < 33) {
                return 'red';
            } else if (this.progress < 100) {
                return 'orange';
            } else {
                return 'green';
            }
        }
    }
}
</script>

<style scoped>
.progressbar-container {
    width: 100%;
    height: 30px;
    background-color: #f2f2f2;
    border-radius: 15px;
    overflow: hidden;
}

.progressbar {
    height: 100%;
    transition: width 0.5s ease-in-out;
}
.red {
    background-color: #ff4136;
}
.orange {
    background-color: #ff851b;
}
.green {
    background-color: #2ecc40;
}
</style>
