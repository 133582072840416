<template>
  <v-card

      v-if="visibility"

      class="mx-auto"
      width="80%"
      style="margin: 10px;"
  >
    <v-card-text>
      <v-row>
      <v-container
      style="width: 50%"
      >
      <div class="text--primary"><h2>{{company}}</h2></div>
      <div class="text--primary"><h3>{{street}} {{zipcode}} {{city}}</h3></div>
      <div class="text--primary"><h3>Tel: {{phone}} E-Mail: {{email}} </h3></div>
      <div class="text--primary"><h3>&nbsp;</h3></div>
      <div class="text--primary"><h3>{{group_main_text}} || {{group_type_text}}</h3></div>
      <div class="text--primary"><h3>&nbsp;</h3></div>
      <div class="text--primary"><h3>{{pickup_date}} {{pickup_time}} - {{driver_name}}</h3></div>
      <div class="text--primary"><h3>&nbsp;</h3></div>
      <div class=""><h3>Bemerkungen vom Fahrer:</h3></div>
      <div class="" style="color: red"><h3>{{pickup_note}}</h3></div>
      <div class="text--primary"><h3>&nbsp;</h3></div>
      <div class=""><h3>Bemerkungen zum Auftrag:</h3></div>
      <div class="text--primary" style="color: darkgray" ><h3>{{note}}</h3></div>
      </v-container>

      <v-container
          style="width: 50%;
          "
      >

        <v-row
            class="justify-center"
            align="center"
            justify="center"
        >


          <v-card
              outlined
              elevation="0"
              v-bind:style="background"
              class="justify-center"
              justify="center"
              style="
        margin: 5px;
        width: 90%;
        max-width: 350px;

        "
              v-for="item in items"
              :key="item.id"
          >
            <v-card-title>{{item.article_name}}</v-card-title>


            <v-card-text>

              <v-row>

                <v-text-field
                    class="centered-input text--darken-3 mt-3"
                    outlined
                    disabled
                    label="Angemeldet"
                    typ="number"
                    :value="item.order_qty"
                    style="
            text-align: center;
            width: 70px;"
                >

                </v-text-field>

                <v-text-field

                    class="centered-input text--darken-3 mt-3"
                    outlined
                    disabled

                    label="Abgeholt"
                    typ="number"
                    style="
                    width: 70px;
                    height: 53px;
                    margin: 30px;
                    "
                    v-bind:style="item.background"
                    :value="item.pickup_qty"
                >

                </v-text-field>


                <v-text-field
                    class="centered-input text--darken-3 mt-3"
                    outlined
                    disabled
                    label="Preis pro Stück"
                    typ="number"
                    style="width: 70px;"
                    :value="Number(item.price).toFixed(2)"
                >

                </v-text-field>

              </v-row>

            </v-card-text>

            <div
            style="padding: 15px;"
            >
            <div v-if="item.rating == 1">Qualität: Sehr Schlecht {{rating1}}% <v-icon style="color: red">mdi-emoticon-frown-outline</v-icon></div>
            <div v-if="item.rating == 2">Qualität: Schlecht {{rating2}}% <v-icon style="color: orange">mdi-emoticon-sad-outline</v-icon></div>
            <div v-if="item.rating == 3">Qualität: Neutral {{rating3}}% <v-icon style="color: yellow">mdi-emoticon-neutral-outline</v-icon></div>
            <div v-if="item.rating == 4">Qualität: Gut {{rating4}}% <v-icon style="color: lime">mdi-emoticon-happy-outline</v-icon></div>
            <div v-if="item.rating == 5">Qualität: Sehr gut {{rating5}}% <v-icon  style="color: green">mdi-emoticon-outline</v-icon></div>
            </div>


          </v-card>


        </v-row>

      </v-container>
      </v-row>

    </v-card-text>

    <v-btn
        style="margin: 15px;"
        @click="openTask"
    >
      <v-icon style="padding-right: 10px;">mdi-clipboard-text-outline</v-icon> Auftrag
    </v-btn>

    <v-divider></v-divider>

    <cImageUpload
        style="margin: 15px"
        v-bind:value="task_id"
        v-bind:selector="'task_id'"
        v-bind:table="'images'"
        v-bind:upload="false"
    ></cImageUpload>

  </v-card>
</template>

<script>

import cImageUpload from '@/components/cImageUpload'

export default {
  name: "CardPickupQuickView",


  components: {
    cImageUpload,
  },

  data () {
    return {

      temp_task_id: '',

      visibility: false,

      customer_id: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      email: '',
      note: '',

      date: '',

      background: 'background: white;',


      items: [],

      driver_name: '',

      pickup_date: '',
      pickup_time: '',
      pickup_qty: '',
      pickup_note: '',

      rest: '',

      document_no: '',
      invoice: '',
      invoice_no: '',

      colors: ['red', 'orange', 'yellow', 'lime', 'green'],
      rating: 0,
      rating_in_percent: '',

      rating1: 0,
      rating2: 0,
      rating3: 0,
      rating4: 0,
      rating5: 0,

      group_main_text: '',
      group_type_text: '',


    }
  },

  async created() {

    await this.getRatingValues()

    this.loadData()


  },


  methods:{


    async getRatingValues(){


      // Einstellungen
      var param2 = {
        table: "parameter",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {}

      var result2 = await this.$func.queryAPI(param2, data2)

       // console.log("result2")
       // console.log(result2)


          this.rating1 = result2[0]["wert"]
          this.rating2 = result2[1]["wert"]
          this.rating3 = result2[2]["wert"]
          this.rating4 = result2[3]["wert"]
          this.rating5 = result2[4]["wert"]

    },




    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.customer_id = result[0]["customer_id"]
      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]
      this.email = result[0]["email"]
      this.note = result[0]["note"]

      this.temp_task_id = result[0]["temp_task_id"]

      this.date = result[0]["date"]
      this.pickup_date = result[0]["pickup_date"]
      this.pickup_time = result[0]["pickup_time"]
      this.pickup_note = result[0]["pickup_note"]
      this.driver_name = result[0]["driver_name"]

      this.group_main_text = result[0]["group_main_text"]
      this.group_type_text = result[0]["group_type_text"]


      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      console.log("result: task_items")
      console.log(result)

      var background

      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {

        if(Number(result[i1]["order_qty"]) > Number(result[i1]["pickup_qty"])){
          background = "background: rgba(255, 0, 0, 0.1);"
        }
        /*
        else{
          background = "background: rgba(0, 255, 0, 0.1);"
        }
        */

        if(this.type == "all"){
          if(Number(localStorage.check_quality) >= Number(result[i1]["rating"])){
            this.visibility = true
          }
        }else{
            this.visibility = true
        }


        if(result[i1]["rating"] == "1" || result[i1]["rating"] == "2" ){
          this.background = "background: rgba(255, 0, 0, 0.1);"
        }


        this.items.push({id:  result[i1]["id"], rating: result[i1]["rating"], order_qty: Number(result[i1]["order_qty"]), pickup_qty: Number(result[i1]["pickup_qty"]), article_name: result[i1]["article_name"], price: result[i1]["price"], background: background})
      }


    },

    openTask(){
      this.$router.push({ path: '/FormClearing/' + this.task_id })
    }



  },


  props: {

    task_id: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: "all",
    },


  }

}
</script>

<style scoped>

</style>
