<template>
<div>
<v-card
    class="elevation-7"
    width="1000px"

style="
margin-top: 30px;
margin-left: 10px;
margin-bottom: 10px;
height: 400px;
"
>


  <v-card-title>{{title}}</v-card-title>
<div
style="margin: 30px"
>
  <h1>{{Infotext}}</h1>
  </div>


  <v-row
v-if="Chart"
>


  <cBar


      style="
      position: absolute;
      bottom: 0px;
      left: 0px;
      margin: 20px"

      :width="900"
      :height="250"

      :chart-options="chartOptions"
      :chart-data="chartData['chartData']"
      :chart-id="purchaser_name + '_1'"

      :annotation_durchschnitt="Number(chartData['durchschnitt'])"
      :annotation_durchschnitt_atage="Number(chartData['durchschnitt_atage'])"
      :annotation_soll="400"

  />






<!--
  <cBar

      style="margin: 20px"

      :width="450"
      :height="250"


      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="customer_id + '_2'"

      :annotation_durchschnitt="Number(durchschnitt)"
      :annotation_durchschnitt_atage="Number(durchschnitt_atage)"
      :annotation_soll="400"

  />
-->
</v-row>



</v-card>


</div>
</template>

<script>
import cBar from "@/components/cBar";

export default {
  name: "CardAnalyticsCustomer",

  components:{
    cBar,
  },

  data() {
    return {

      title: "",
      title2: "",


      renderComponent: true,
      overlay: false,

      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */
      customer_group_type_id: '',
      customer_group_type_text: '',
      customer_total_qty: 0,
      customer_total_emptyruns: 0,
      customer_price_total: 0,
      customer_price_pkw: 0,

      durchschnitt: '',
      durchschnitt_atage: '',

      Infotext: '',

      Chart: false,

      chartData: Object,


      chartDataPie: {
        labels: ['Sehr Gut', 'Gut', 'Neutral', 'Schlecht', 'Sehr Schlecht'],
        datasets: [
          {
            backgroundColor: ['green', 'lime', 'yellow', 'orange', 'red'],
            data: ["0", "0", "0", "0", "0"]
          }
        ]
      },

      chartOptions: {
        responsive: true
      },

    }

  },



 async created() {


   this.chartData = {
     labels: ['January', 'February', 'March'],
     datasets: [{data: [40, 20, 0]}]
   }

   console.log("this.chartData")
   console.log(this.chartData)



   await this.loadData()


   // Remove MyComponent from the DOM
   this.Chart = false;


   // Wait for the change to get flushed to the DOM
   await this.$nextTick();

   // Add the component back in
   this.Chart = true;


  },

  methods:{




    async loadData(){


      // Umwandeln Datum Format

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD")
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      var dates = [];


      // Alle Tage innerhalb einer Zeitspanne ermitteln

      var currDate = this.$moment(date_from, "YYYY-MM-DD").startOf('day');
      var lastDate = this.$moment(date_to).startOf('day');

      while (currDate.add(1, 'days').diff(lastDate) < 0) {

        // console.log(this.$moment(currDate.toDate()).format("DD.MM.YYYY"));
        // console.log(this.$moment(currDate.clone().toDate()).format("dddd"))

        if (this.$moment(currDate.clone().toDate()).format("dddd") != "Sunday" && this.$moment(currDate.clone().toDate()).format("dddd") != "Saturday") {
          dates.push(this.$moment(currDate.clone().toDate()).format("DD.MM.YYYY"));
        }

      }


      // Einstellungen
      var param1 = {
        table: "stock",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT date, sum(weight), sum(qty), name FROM stock WHERE typ='sell' AND name='" + this.purchaser_name + "' AND date_timestamp >= '" + date_from + "' AND date_timestamp <= '" + date_to + "' GROUP BY date_timestamp ORDER BY date_timestamp ASC",
      }


      console.log(param1.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log("result1")
      console.log(result1)

      if (result1.length == 0){
        this.Infotext = "Keine Verkäufe in diesem Zeitraum"
        this.Chart = false
        return
      }




      /*
            this.chartData = {
              labels: ['January', 'February', 'March'],
                  datasets: [{data: [40, 20, 0]}]
            }
      */









      var array_values1 = []
      var array_values2 = []


      for (var i1 = 0; i1 < dates.length; i1++) {
        array_values1.push("0")
      }
      for (var i2 = 0; i2 < dates.length; i2++) {
        array_values2.push("0")
      }


      var sub_array = []

      sub_array.push({
        label: "VERKAUFT",
        backgroundColor: "lightgreen",
        borderColor: "green",
        borderWidth: 1,
        data: array_values1,
      })

      sub_array.push({
        label: "ABGEHOLT",
        backgroundColor: "lightgreen",
        borderColor: "green",
        borderWidth: 1,
        data: array_values2
      })



      this.chartData = {
        name: result1[0]["name"],
        id: this.customer_id,
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array}
      }






      var all_reifen = 0
      var atage = 0



      for (var i4 = 0; i4 < this.chartData["chartData"]["labels"].length; i4++) {


        for (var i5 = 0; i5 < result1.length; i5++) {


          if (this.chartData["chartData"]["labels"][i4] == result1[i5]["date"]) {

            atage = Number(atage) + 1
            all_reifen = Number(all_reifen) + Number(result1[i5]["sum(qty)"])

            this.customer_total_qty = Number(this.customer_total_qty)  + Number(result1[i5]["sum(qty)"])

            this.chartData["chartData"]["datasets"][0]["data"][i4] = result1[i5]["sum(qty)"]



          }

        }

        i5 = 0

      }



      this.customer_total_qty = this.customer_total_qty.toLocaleString("de-DE")



      sub_array = []


      var durchschnitt_atage = Number(all_reifen) / Number(atage)
      var durchschnitt = Number(all_reifen) / Number(this.chartData["chartData"]["datasets"][0]["data"].length)


      this.title = Number(all_reifen) + " " + "Stück"

      this.chartData["durchschnitt_atage"] =  Math.round(durchschnitt_atage)
      this.chartData["durchschnitt"] =  Math.round(durchschnitt)


      console.log("this.chartData")
      console.log(this.chartData)






















      // Remove MyComponent from the DOM
      this.Chart = false;


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.Chart = true;




    }



  },



  props: {

    purchaser_name: {
      type: String,
      default: ''
    },

    date_from:{
      type: String,
      default: ''
    },

    date_to:{
      type: String,
      default: ''
    },

  }


}
</script>

<style scoped>

</style>
