<template>
<div
style="
width: 100%;
display: flex;
justify-content: center;
"
>
<div
style="position: absolute"
>
    <v-autocomplete
        v-model:value="select_filter"
        clearable
        label="Schnellsuche"
        :items="data_items"
        style="display: inline-block"
    ></v-autocomplete>
    <v-btn
        style="
        display: inline-block;
        margin-left: 10px;
        "
    outlined
    @click="reloadList()"
    >LISTE NEU LADEN</v-btn>
</div>


  <div
      style="
      position: absolute;
      top: 15px;
      left: 80px;
      "
  >
    <v-select
        v-model="quality"
        label="REIFEN QUALITÄT"
        :items="['EXTRA', 'HOCH', 'ENGLAND', 'NORMAL', 'MONTAGE']"
        @change="reloadList()"
    >
     </v-select>

  </div>



  <div
      style="
      position: absolute;
      top: 25px;
      left: 370px;
      "
  >
    <v-btn
        color="red"
    @click="resetTable()"
    >ZURÜCKSETZEN</v-btn>
  </div>





    <table
    style="
    position: absolute;
    top: 80px;
    width: 80%"
    >
        <thead>
        <tr
            style="background: lightgray"
        >
            <th
                style="width: 200px;"
                class="text-center">
                Bezeichnung
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                SOMMER/EINZEL
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                SOMMER/PAARE
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                WINTER/EINZEL
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                WINTER/PAARE
            </th>

            <th
                style="width: 350px;"
                class="text-center">
                Bemerkung
            </th>

            <th
                style="

                width: 150px;"
                class="text-center">
                Geändert von
            </th>

            <th
                style="

                width: 150px;"
                class="text-center">
                Datum/Uhrzeit
            </th>




        </tr>



        <tr
            style="background: lightgray"
        >
            <th
                style="width: 200px;"
                class="text-center">

            </th>
            <th
                style="width: 70px;"
                class="text-center">
                {{total_extra}}
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                {{total_hoch}}
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                {{total_england}}
            </th>
            <th
                style="width: 70px;"
                class="text-center">
                {{total_normal}}
            </th>

            <th
                style="width: 350px;"
                class="text-center">

            </th>

            <th
                style="

                width: 150px;"
                class="text-center">

            </th>

            <th
                style="

                width: 150px;"
                class="text-center">

            </th>




        </tr>

        </thead>


        <tbody>

        <tr
            v-for="(item, index) in data"
            :key="item.id"
        >
            <td
            :id=" item.name"
            >{{ item.name }}</td>
            <td

            >

                <div
                v-if="item.qty1_note != '' && item.qty1_note != null"

                    style="
                background: #e91e63;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                    class="tooltip"

                @click="updateItemNoteClick(index, 1)"

                >
                    <span class="tooltiptext">{{item.qty1_note}}</span>
                </div>
                <div
                   v-else

                    style="
                background: lightgray;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                   @click="updateItemNoteClick(index, 1)"
                >

                </div>

            <v-text-field
                outlined
                hide-details
                type="number"
                class="text-center"
            v-model:value="item.qty1"
            @change="updateItemQty(index)"
            @update="updateItemQty(index)"
                style="
                text-align: center;
                "

            ></v-text-field>




            </td>
            <td>

                <div
                    v-if="item.qty2_note != '' && item.qty2_note != null"

                    style="
                background: #e91e63;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                    class="tooltip"
                    @click="updateItemNoteClick(index, 2)"

                >
                    <span class="tooltiptext">{{item.qty2_note}}</span>
                </div>
                <div
                    v-else

                    style="
                background: lightgray;
                width: 100%;
                height: 7px;
                       cursor: pointer;
                "
                    @click="updateItemNoteClick(index, 2)"
                >

                </div>

                <v-text-field
                    outlined
                    hide-details
                    type="number"
                    class="text-center"
                    v-model:value="item.qty2"
                    @change="updateItemQty(index)"
                    @update="updateItemQty(index)"
                    style="
                text-align: center;
                "


                ></v-text-field>
            </td>
            <td>

                <div
                    v-if="item.qty3_note != '' && item.qty3_note != null"

                    style="
                background: #e91e63;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                    class="tooltip"
                    @click="updateItemNoteClick(index, 3)"

                >
                    <span class="tooltiptext">{{item.qty3_note}}</span>
                </div>
                <div
                    v-else

                    style="
                background: lightgray;
                width: 100%;
                height: 7px;
                       cursor: pointer;
                "
                    @click="updateItemNoteClick(index, 3)"
                >

                </div>

                <v-text-field
                    outlined
                    hide-details
                    type="number"
                    class="text-center"
                    v-model:value="item.qty3"
                    @change="updateItemQty(index)"
                    @update="updateItemQty(index)"
                    style="
                text-align: center;
                "

                ></v-text-field>
            </td>
            <td>

                <div
                    v-if="item.qty4_note != '' && item.qty4_note != null"

                    style="
                background: #e91e63;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                    @click="updateItemNoteClick(index, 4)"
                    class="tooltip"
                >
                    <span class="tooltiptext">{{item.qty4_note}}</span>
                </div>
                <div
                    v-else

                    style="
                background: lightgray;
                width: 100%;
                height: 7px;
                cursor: pointer;
                "
                    @click="updateItemNoteClick(index, 4)"
                >

                </div>

                <v-text-field
                    outlined
                    hide-details
                    type="number"
                    class="text-center"
                    v-model:value="item.qty4"
                    @change="updateItemQty(index)"
                    @update="updateItemQty(index)"
                    style="
                text-align: center;
                "

                ></v-text-field>
            </td>
            <td>

                <div
                style="
                background: #ffffff;
                width: 100%;
                height: 7px;

                "

                >

                </div>


                <v-text-field
                    outlined
                    hide-details
                    v-model:value="item.note"
                    @change="updateItemNote(index)"
                    @update="updateItemNote(index)"
                ></v-text-field>
            </td>
            <td
                class="text-center"
            >{{ item.worker_edit }}</td>
            <td
                class="text-center"
            >{{ $moment(item.last_update, "YYYY-MM-DD HH:mm:ss").format('HH:mm:ss DD.MM.YYYY') }}</td>

        </tr>

        </tbody>


    </table>


    <div
        v-if="showNoteSaveDialog"
    >
<v-card
style="
position: fixed;
height: 200px;
width: 550px;

bottom: 50px;
z-index: 99;
        "

        >

    <v-btn
        style="
        position: absolute;
        right: 20px;
        top: 20px;

        "
    @click="showNoteSaveDialog = false"
    ><v-icon>mdi-close</v-icon></v-btn>

    <v-card-title>{{NoteSaveDialogDesc}}</v-card-title>

    <v-text-field
        outlined
        clearable
        label="Bemerkung"
        style="
        display: inline-block;
        left: 50px;
        top: 20px;
        width: 400px;"
    v-model="new_note"

    ></v-text-field>

    <v-btn
    width="55px"
    height="55px"
    style="
    top: 20px;
    left: 70px;
    display: inline-block"
    @click="btnSaveNote()"
    ><v-icon>mdi-content-save</v-icon></v-btn>


</v-card>

    </div>




        <v-btn
            @click="exportCSV()"
            fab
            style="
    position: absolute;
    top: 5px;
    right: 5px;
"
        ><v-icon>mdi-microsoft-excel</v-icon></v-btn>


</div>
</template>

<script>

import VueJsonToCsv from 'vue-json-to-csv'
import * as XLSX from "xlsx";

export default {
    name: "ListStockQty",

    components:{
        VueJsonToCsv,
    },

    data () {
        return {

            title: 'Sammelliste',
            table: 'group_tires_',
            table_default: 'group_tires_',

            quality: 'EXTRA',

            snackbar: false,
            snackbar_title: 'Gespeichert!',
            snackbar_color: 'red',
            timeout: 4000,

            data: [],
            data_items: [],

            select_filter: null,

            new_note: '',
            showNoteSaveDialog: false,
            NoteSaveDialogID: '',
            NoteSaveDialogNum: '',
            NoteSaveDialogDesc: '',

            total_extra: 0,
            total_hoch: 0,
            total_england: 0,
            total_normal: 0,

            total_column1: 0,
            total_column2: 0,
            total_column3: 0,
            total_column4: 0
        }
    },

    created() {

        this.table = this.table_default + this.quality.toString().toLocaleLowerCase()



        this.loadData()

    },

    watch:{

        select_filter() {

            if(this.select_filter != "" && this.select_filter != null){
                // document.getElementById(this.select_filter).scrollIntoView();

                const id = this.select_filter;
                const yOffset = -60;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});

                document.getElementById(this.select_filter).style.backgroundColor = "yellow";
            }

        }


    },


    methods:{

      test(){
        const nummern = ["88-100-111", "88-200-111", "88-50-111", "88-10-111", "88100111", "88200111", "8850111"];

        nummern.sort((a, b) => {
          const numA = a.includes('-') ? a.split('-').map(Number) : [parseInt(a, 10)];
          const numB = b.includes('-') ? b.split('-').map(Number) : [parseInt(b, 10)];

          const maxLength = Math.max(numA.length, numB.length);

          for (let i = maxLength - 1; i >= 0; i--) {
            const valA = numA[i] || 0; // Verwende 0, wenn das Teil nicht existiert
            const valB = numB[i] || 0;

            if (valA !== valB) {
              return valA - valB;
            }
          }

          return 0; // Wenn alle Teile gleich sind, bleibt die Reihenfolge unverändert
        });

        console.log(nummern);
      },

      async exportCSV(){

        var ws = XLSX.utils.json_to_sheet(this.data)
        XLSX.utils.sheet_add_aoa(ws, [["id", "BEZEICHNUNG", "SOMMER/EINZEL", "SOMMER/PAARE", "WINTER/EINZEL", "WINTER/PAARE", "BEMERKUNG1", "BEMERKUNG2", "BEMERKUNG3", "BEMERKUNG4", "HINWEIS", "DATUM", "MITARBEITER"]], { origin: "A1" });

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, this.table)
        XLSX.writeFile(wb,'export.xlsx')

      },


      async resetTable(){

        const response = confirm("Lagerliste wird neu eingelesen dabei geht der Lagerbestand verloren!");

        if (!response) {
         return
        }

        // Einstellungen
        var param1 = {
          table: this.table,
          statement: "custom",
          type: "",
          where: "",
          selector: "",
          value: "",
          short: "", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "TRUNCATE TABLE " + this.table,
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data1 = {
        }

        var result1 = await this.$func.queryAPI(param1, data1)
        console.log("TRUNCATE TABLE")
        console.log(result1)



        // Einstellungen
        var param2 = {
          table: "group_tires",
          statement: "select",
          type: "list",
          where: "false",
          selector: "",
          value: "",
          short: "", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "ORDER BY name ASC",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data2 = {
        }

        var result2 = await this.$func.queryAPI(param2, data2)
        console.log(result2)


        for (var i1 = 0; i1 < result2.length; i1++) {
            await this.insertNewItem(result2[i1]["name"])
        }

        this.loadData()

      },



      async insertNewItem(name){

        console.log("insertNewItem")

        // Einstellungen
        var param1 = {
          table: this.table,
          statement: "insert",
          type: "",
          where: "",
          selector: "",
          value: "",
          short: "", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data1 = {
          name: name
        }

        var result1 = await this.$func.queryAPI(param1, data1)
        console.log(result1)

      },



        reloadList(){
          this.table = this.table_default + this.quality.toString().toLocaleLowerCase()
          this.loadData()
        },


        async btnSaveNote(){
            this.showNoteSaveDialog = false

            // this.NoteSaveDialogID = id
            // this.NoteSaveDialogNum = num

            // Einstellungen
            var param = {
                table: this.table,
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data[this.NoteSaveDialogID].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = []


            if(this.new_note == null){
                this.new_note = ""
            }


            switch (this.NoteSaveDialogNum) {

                case 1:
                    data = {qty1_note: this.new_note, worker_edit: localStorage.username}
                    break;

                case 2:
                    data = {qty2_note: this.new_note, worker_edit: localStorage.username}
                    break;

                case 3:
                    data = {qty3_note: this.new_note, worker_edit: localStorage.username}
                    break;

                case 4:
                    data = {qty4_note: this.new_note, worker_edit: localStorage.username}
                    break;

            }


            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            await this.loadData()
        },

        async updateItemNoteClick(id, num){
            this.showNoteSaveDialog = true

            console.log("id")
            console.log(id)
            console.log(num)


            this.NoteSaveDialogID = id
            this.NoteSaveDialogNum = num


            // Einstellungen
            var param = {
                table: this.table,
                statement: "select",
                type: "detail",
                where: "true",
                selector: "id",
                value: this.data[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)


            this.NoteSaveDialogDesc = result[0]["name"]

            switch (num) {

                case 1:
                this.new_note = result[0]["qty1_note"]
                    this.NoteSaveDialogDesc = this.NoteSaveDialogDesc + " EXTRA"
                break;

                case 2:
                this.new_note = result[0]["qty2_note"]
                    this.NoteSaveDialogDesc = this.NoteSaveDialogDesc + " HOCH"
                break;

                case 3:
                this.new_note = result[0]["qty3_note"]
                    this.NoteSaveDialogDesc = this.NoteSaveDialogDesc + " ENGLAND"
                break;

                case 4:
                this.new_note = result[0]["qty4_note"]
                    this.NoteSaveDialogDesc = this.NoteSaveDialogDesc + " NORMAL"
                break;


            }



//            await this.loadData()



        },

        async updateItemQty(id){
            console.log("updateItemQty")
            console.log(id)
            console.log(this.data[id].qty)

            // Einstellungen
            var param = {
                table: this.table,
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
                qty1: parseInt(this.data[id].qty1),
                qty2: parseInt(this.data[id].qty2),
                qty3: parseInt(this.data[id].qty3),
                qty4: parseInt(this.data[id].qty4),
                // last_update: this.$moment(Date.now()).format('YYYY-MM-DD HH:MM:SS'),
                worker_edit: localStorage.username
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            await this.loadData()


        },

        async updateItemNote(id){
            console.log("updateItemNote")
            console.log(id)
            console.log(this.data[id].qty)

            // Einstellungen
            var param = {
                table: this.table,
                statement: "update",
                type: "",
                where: "true",
                selector: "id",
                value: this.data[id].id,
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
                note: this.data[id].note,
                // last_update: this.$moment(Date.now()).format('YYYY-MM-DD HH:MM:SS'),
                worker_edit: localStorage.username
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            await this.loadData()


        },

        async loadData(){

            this.data = []

            // Einstellungen
            var param = {
                table: this.table,
                statement: "select",
                type: "list",
                where: "false",
                selector: "",
                value: "",
                short: "", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: "ORDER BY name ASC",
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            var result = await this.$func.queryAPI(param, data)
            console.log(result)

            this.data = result.sort((a, b) => {
              const nameA = a.name;
              const nameB = b.name;

              const numA = nameA.includes('-') ? nameA.split('-').map(Number) : [parseInt(nameA, 10)];
              const numB = nameB.includes('-') ? nameB.split('-').map(Number) : [parseInt(nameB, 10)];

              const maxLength = Math.max(numA.length, numB.length);

              for (let i = maxLength - 1; i >= 0; i--) {
                const valA = numA[i] || 0;
                const valB = numB[i] || 0;

                if (valA !== valB) {
                  return valA - valB;
                }
              }

              return 0;
            });


            this.total_extra = 0
            this.total_hoch = 0
            this.total_england = 0
            this.total_normal = 0


            for (var i1 = 0; i1 < result.length; i1++) {
                this.data_items.push(result[i1]["name"])

                this.total_extra = Number(this.total_extra) + Number(result[i1]["qty1"])
                this.total_hoch = Number(this.total_hoch) + Number(result[i1]["qty2"])
                this.total_england = Number(this.total_england) + Number(result[i1]["qty3"])
                this.total_normal = Number(this.total_normal) + Number(result[i1]["qty4"])


            }


          this.data_items.sort((a, b) => {

            const numA = a.includes('-') ? a.split('-').map(Number) : [parseInt(a, 10)];
            const numB = b.includes('-') ? b.split('-').map(Number) : [parseInt(b, 10)];

            const maxLength = Math.max(numA.length, numB.length);

            for (let i = maxLength - 1; i >= 0; i--) {
              const valA = numA[i] || 0; // Verwende 0, wenn das Teil nicht existiert
              const valB = numB[i] || 0;

              if (valA !== valB) {
                return valA - valB;
              }
            }


            return 0; // Wenn alle Teile gleich sind, bleibt die Reihenfolge unverändert
          });


            console.log(this.data_items)





        }


    }


}
</script>

<style lang="scss" scoped>


/* Tooltip container */
.tooltip {
    position: relative;
    display: block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;

    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    // padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

</style>
