<template>

  <div
      style="
        height: 180px;
        width: 100%"
  >
    <div><h2>{{title}}</h2></div>
    <div>{{title2}}</div>


    <div
        style="
          position: absolute;
          right: 20px;
          top: 0px;
        "
    >
      <DialogDate
          v-bind:label="'Von'"
          v-bind:initial_date="date_from"
          v-bind:clearable="false"
          v-model="date_from"
      ></DialogDate>

      <DialogDate
          v-bind:label="'Bis'"
          v-bind:initial_date="date_to"
          v-bind:clearable="false"
          v-model="date_to"
      ></DialogDate>
    </div>



    <v-overlay
           :value="overlay"
    >


      <v-progress-circular
          indeterminate
          color="black"
          style="margin: 20px;"
      ></v-progress-circular>


      Daten werden geladen...


    </v-overlay>




    <div
        v-if="renderComponent"
        style="
        width: 700px;"
    >
    <div

        v-for="driver in drivers"
        :key="driver.id"
        style="
            margin: 10px;
            float: left"
    >

      <div
          v-if="driver.durchschnitt != ''"
      >
      <div><b>{{driver.name}}</b> (Abgeholte Reifen: {{driver.qty}} Stück)</div>



      <!--      :dataset-id-key="datasetIdKey"  -->


      <cBar

          v-if="driver.durchschnitt != ''"

          :chart-options="chartOptions"
          :chart-data="driver.chartData"
          :chart-id="driver.id"

          :annotation_durchschnitt="Number(driver.durchschnitt)"
          :annotation_durchschnitt_atage="Number(driver.durchschnitt_atage)"
          :annotation_soll="400"

      />
    </div>

    </div>

    </div>



  </div>

</template>

<script>
import cBar from "../components/cBar"
import DialogDate from "../components/DialogDate"


export default {
  name: "AnalyticsChartReifen",

  components:{
    cBar,
    DialogDate,
  },

  data() {
    return {

      title: "Reifen pro Tag nach Fahrer",
      title2: "Der Durchschnitt bezieht sich auf die gefahrenen Touren",

      chartData: Object,

      sync: true,


      drivers: [],
      renderComponent: true,
      overlay: false,

      default_range_in_days: 20,


      /*
      chartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{data: [40, 20, 0]}]
      },
      */

      chartOptions: {
        responsive: true
      },

      date_from: "",
      date_to: "",

    }

  },


  async created() {

    this.sync = true


    console.log("loadData created: " + this.sync)

    this.date_from = this.$moment(Date.now()).subtract(this.default_range_in_days, "days").format("DD.MM.YYYY")
    this.date_to = this.$moment(Date.now()).format("DD.MM.YYYY")

    await this.loadData()

    this.sync = false

  },

  watch:{

    date_from(){
      console.log("date_from: " + this.date_from)

      if (this.sync === true){
        return
      }

     this.loadData()
    },
    date_to(){
      console.log("date_to: " + this.date_to)

      if (this.sync === true){
        return
      }

    this.loadData()
    },

  },

  methods:{

    async loadData(){

        console.log("loadData: " + this.sync)

        this.drivers = []


      this.overlay = true


      // Remove MyComponent from the DOM
      this.renderComponent = false;

        await this.getDriverIds()


      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;


      this.overlay = false


    },


    async getDriverIds() {

      // Fahrer werden ausgelesen
      var param = {
        table: "driver",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      // await this.getDriverData(1, result[1]["id"],result[1]["name"])

      for (var i1 = 0; i1 < result.length; i1++) {
        await this.getDriverData(i1, result[i1]["id"],result[i1]["sign_text"])
      }


      // console.log(this.drivers[1]["chartData"])
      // console.log(this.chartData)

      // this.drivers = result


    },

    async getDriverData(num, id, name) {

      // Umwandeln Datum Format

      var date_from = this.$moment(this.date_from, "DD.MM.YYYY").format("YYYY-MM-DD");
      var date_to = this.$moment(this.date_to, "DD.MM.YYYY").format("YYYY-MM-DD")

      var dates = [];


      // Alle Tage innerhalb einer Zeitspanne ermitteln

      var currDate = this.$moment(date_from, "YYYY-MM-DD").startOf('day');
      var lastDate = this.$moment(date_to).startOf('day');

      while (currDate.add(1, 'days').diff(lastDate) < 0) {

        // console.log(this.$moment(currDate.toDate()).format("DD.MM.YYYY"));
        // console.log(this.$moment(currDate.clone().toDate()).format("dddd"))

        if (this.$moment(currDate.clone().toDate()).format("dddd") != "Sunday" && this.$moment(currDate.clone().toDate()).format("dddd") != "Saturday") {
          dates.push(this.$moment(currDate.clone().toDate()).format("DD.MM.YYYY"));
        }

      }


      // Liest alle Aufträge ein

      // Einstellungen
      var param = {
        table: "task",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT tour_id, pickup_date, sum(order_qty), sum(pickup_qty) FROM task WHERE pickup_date IS NOT NULL AND driver_id='" + id + "' AND pickup_timestamp >= '" + date_from + "' AND pickup_timestamp <= '" + date_to + "' GROUP BY pickup_date ORDER BY date_timestamp ASC",
      }


      // console.log(param.extension)

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      /*
            chartData: {
              labels: ['January', 'February', 'March'],
                  datasets: [{data: [40, 20, 0]}]
            },
      */

      var array_values1 = []
      var array_values2 = []
      var array_values3 = []

      for (var i1 = 0; i1 < dates.length; i1++) {
        array_values1.push("0")
      }
      for (var i2 = 0; i2 < dates.length; i2++) {
        array_values2.push("0")
      }
      for (var i3 = 0; i3 < dates.length; i3++) {
        array_values3.push("0")
      }

      var sub_array = []

      sub_array.push({
        label: "ABGEHOLT",
        backgroundColor: "lightgreen",
        borderColor: "green",
        borderWidth: 1,
        data: array_values1,
      })

      sub_array.push({
        label: "GEPLANT",
        backgroundColor: "#ADD8E6",
        borderColor: "blue",
        borderWidth: 1,
        data: array_values2
      })

      sub_array.push({
        label: "TRANSPORTKAPAZITÄT",
        backgroundColor: "pink",
        borderColor: "red",
        borderWidth: 1,
        data: array_values3
      })




      this.drivers.push({
        name: name,
        id: id,
        qty: '0',
        durchschnitt: "",
        durchschnitt_atage: "",
        chartData: {labels: dates, datasets: sub_array}
      })




      var all_reifen = 0
      var atage = 0



/*
      await Promise.all(result.map(async (test) => {

        if (this.drivers[num]["chartData"]["labels"][i3] == test["pickup_date"]) {
          sub_array[1]["data"][i3] = await this.getTourCapacity(test["tour_id"])
          console.log(test)
        }


      }));
*/




    for (var i4 = 0; i4 < this.drivers[num]["chartData"]["labels"].length; i4++) {


            for (var i5 = 0; i5 < result.length; i5++) {


              if (this.drivers[num]["chartData"]["labels"][i4] == result[i5]["pickup_date"]) {

                atage = Number(atage) + 1
                all_reifen = Number(all_reifen) + Number(result[i5]["sum(pickup_qty)"])

                this.drivers[num]["chartData"]["datasets"][0]["data"][i4] = result[i5]["sum(pickup_qty)"]
                this.drivers[num]["chartData"]["datasets"][1]["data"][i4] = result[i5]["sum(order_qty)"]
                this.drivers[num]["chartData"]["datasets"][2]["data"][i4] = await this.getTourCapacity(result[i5]["tour_id"])

              }

            }

        i5 = 0

      }



      // console.log("sub_array_finish")
      // console.log(sub_array)
      console.log("chartData")
      console.log(this.drivers[num]["chartData"])


      // this.drivers[num]["chartData"]["datasets"] = this.sub_array

      // console.log(this.drivers[num]["chartData"]["datasets"])

      sub_array = []


      var durchschnitt_atage = Number(all_reifen) / Number(atage)
      var durchschnitt = Number(all_reifen) / Number(this.drivers[num]["chartData"]["datasets"][0]["data"].length)

      /*
      console.log("alle reifen: " + Number(all_reifen))
      console.log("Tage: " + Number(this.drivers[num]["chartData"]["datasets"][0]["data"].length))
      console.log("durchschnitt: " + durchschnitt)
      */
      this.drivers[num]["qty"] =  Number(all_reifen).toLocaleString("de-DE")
      this.drivers[num]["durchschnitt_atage"] =  Math.round(durchschnitt_atage)
      this.drivers[num]["durchschnitt"] =  Math.round(durchschnitt)



    },

    async getTourCapacity(tour_id){

      // Einstellungen
      var param = {
        table: "tour",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT car_capacity FROM tour WHERE id='" + tour_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      if(result.length == 0){
        return 400
      }

      if(result[0]["car_capacity"] == ""){
        return 400
      }else{
        return result[0]["car_capacity"]
      }

    },





  },




}
</script>

<style scoped>

</style>
