<template>

  <v-layout
      justify-start
      style="
      width: 100%;
      "
  >

    <div
    style="
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    float: left;
    text-align: left"
    >

      <v-row>

  <v-card
      v-for="item in items"
      :key="item.id"

      width="450px"

      style="
          text-align: left;
          margin: 10px;
          "

  >
    <v-card-title>Tour ID: {{item.id}}</v-card-title>

    <v-card-text>
      <div>Fahrer: <b>{{item.driver_name}}</b></div>
      <div>Datum: <b>{{item.planed_date}}</b></div>
      <div>Bemerkungen zur Tour:</div>
      <div><b>{{item.note}}</b></div>
      <div
      style="padding: 20px;"
      ></div>

      <cTimeLine
      v-bind:tour_id="item.id"
      ></cTimeLine>


    </v-card-text>


  </v-card>

      </v-row>

    </div>

  </v-layout>

</template>

<script>

import cTimeLine from '../components/cTimeLine'

export default {
  name: "CardCurrentPickup",

  components: {
    cTimeLine,
  },

  data() {
    return {
      items: [],

    }
  },


  created() {

    this.loadData()

  },

  methods:{


    async loadData(){
      console.log("loadData")

      var planed_date = this.$moment(Date.now()).format("DD.MM.YYYY")

      // Einstellungen
      var param = {
        table: "tour",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE planed_date='" + planed_date + "' and (pickup_date IS NULL OR pickup_date='')",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      this.items = result



    },



  },


  props: {

    extension: {
      type: String,
      default: ''
    },



  }



}
</script>

<style scoped>

</style>
