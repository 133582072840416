<template>

  <v-card
      class="mx-auto"
      width="450"
      style="margin: 10px;"

  >
    <v-card-text>
      <div>AKTUELLER LAGERBESTAND</div>
      <p class="text-h4 text--primary">
        <b>{{total_weight}}</b> Tonnen<br>
        ca. <b>{{total_qty}}</b> Stück

      </p>
     <!-- <p>Letzte Änderung: {{last_timestamp}}</p> -->
      <div class="text--primary">

      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          text
          color="pink accent-3"
          @click="openListStock"
      >
        Details
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "CardCurrentStock",

  data () {
    return {

      total_weight: 0,
      total_qty: 0,

      average_weight: 0,
      last_timestamp: '',


    }
  },

  async created() {

    await this.loadData()

    this.total_weight = await this.calcTotalWeight()

  },



  methods:{


    async calcTotalWeight(){
      console.log("calcTotalWeight")

      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock`",        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log("AUSGABE!")
      console.log(result[0]["SUM(weight)"])
      console.log(Number(result[0]["SUM(weight)"]).toFixed(3))
      this.total_qty = Math.round(((result[0]["SUM(weight)"] * 1000).toFixed(0) / this.average_weight)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")

      return Number(result[0]["SUM(weight)"]).toFixed(3)

    },


    async loadData() {
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      if(result.lenght == 0){
        this.average_weight = ""
        return
      }

      this.average_weight = result[0]["wert"]

    },


    openListStock(){
      this.$router.push({ name: 'ListStock'})
    }

  }
}
</script>

<style scoped>

</style>
