<template>
  <div>

    <List

        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:default_limit="'1000'"
        v-bind:default_sort="'id'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:filter_clearing="true"
        v-bind:extension="''"

    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListDisposal",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: 'FormDisposal',
      table: 'disposal',

    }
  },
  computed: {
    headers () {
      return [
        { text: 'Datum', align: 'start', sortable: true, value: 'date_timestamp' },
        { text: 'Entsorger', value: 'disposer_company' },
        { text: 'Referenz Nr.', value: 'document_no' },
        { text: 'Bemerkung', value: 'note' },
        { text: 'Gewicht in T', value: 'weight' },
        { text: 'Abfallschluessel', value: 'wasteno' },
        { text: 'Betrag', value: 'price' },
        { text: 'Rechnung erhalten', value: 'invoice' },
        { text: 'Weiterberechnen', value: 'invoice_forward' },
        { text: 'Lager ID', value: 'stock_id' },

          /*
        { text: 'Rechnung bezahlt', value: 'invoice_payment_date' },
        { text: 'Zahlungsfrist', value: 'invoice_payment_deadline' },
           */
        { text: '', value: 'edit' },
      ]
    },
  },
  methods: {

  },


}
</script>

<style scoped>

</style>
