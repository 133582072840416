<template>
  <div>

    <List
        v-if="renderComponent"
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"
        v-bind:addbutton="false"


        v-bind:default_limit="'500'"
        v-bind:default_sort="'id'"
        v-bind:default_sort_typ="'ASC'"
        v-bind:extension="''"

    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListDriver",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: '',
      table: 'driver',
      renderComponent: true,

      driver: [],
    }
  },

  async created(){
    await this.checkDriver()

    // Remove my-component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // Add the component back in
      this.renderComponent = true;
    });
  },

  computed: {
    headers () {
      return [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Passwort', value: 'password' },
        { text: 'Anzahl Touren', value: 'qty_tours' },
        { text: 'Anzahl Reifen', value: 'qty_articles' },
        { text: 'Aktuelle Tour', value: 'tour_id' },
        { text: 'Letzte Aktivität', value: 'last_activity' },
      ]
    },
  },
  methods: {

    async checkDriver() {
      this.driver = []

      // Einstellungen
      var param = {
        table: "driver",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.driver = result


      for (var i1 = 0; i1 < this.driver.length; i1++) {

        await this.getTour(i1, this.driver[i1].id)

      }


    },


    async getTour(id, driver_id) {
      // Einstellungen
      var param = {
        table: "tour",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT count(id), sum(qty) FROM tour WHERE driver_id='" + driver_id + "' and pickup_date IS NULL",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      var qty_tours = result[0]["count(id)"]
      var qty_articles = result[0]["sum(qty)"]


      // Einstellungen
       param = {
        table: "driver",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: driver_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        qty_tours: qty_tours,
        qty_articles: qty_articles,
      }

      await this.$func.queryAPI(param, data)







    },

  }

}
</script>

<style scoped>

</style>
