<template>
  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:default_limit="'500'"
        v-bind:default_sort="'id'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:filter_clearing="true"
        v-bind:extension="''"

    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListTransport",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: 'FormTransport',
      table: 'transport',

    }
  },
  computed: {
    headers () {
      return [
        { text: 'Datum', value: 'date_timestamp' },
        { text: 'Spedition', value: 'carrier_company' },
        { text: 'Referenz Nr.', value: 'document_no' },
        { text: 'Bemerkung', value: 'note' },
        { text: 'Preis', value: 'price' },
        { text: 'Rechnung erhalten', value: 'invoice' },
        { text: 'Weiterberechnen', value: 'invoice_forward' },
          /*
        { text: 'Rechnung bezahlt', value: 'invoice_payment_date' },
        { text: 'Zahlungsfrist', value: 'invoice_payment_deadline' },
           */
        { text: '', value: 'edit' },
      ]
    },
  },
  methods: {

  },

}
</script>

<style scoped>

</style>
