var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"height":"900px","width":"800px"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"height":"900px","width":"800px"}},[_c('v-app-bar',{attrs:{"flat":"","color":"pink"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(" Kunden Auswahl ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'pro Seite'
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.select",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('v-icon',{attrs:{"color":"pink"},on:{"click":function($event){return _vm.selectEntry(item.id)}}},[_vm._v("mdi-checkbox-marked-outline")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }