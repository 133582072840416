<template>
  <div>


    <div>

      <input type="file" ref="file"  accept=".csv" @change="parseFile()" style="display: none" />


<v-card
        v-for="item in data"
        :key="item.id"

        style="
        margin: 30px;
        width: 750px;
        height: 400px;
        float: left;
        "

>
    <v-card-title>{{item.name}}</v-card-title>


    <div
            style="
            position: absolute;
            right: 20px;
            top: 10px;
            "
    >
        <v-icon
                style="margin: 15px;"
                @click="createCSV(item.id)" size="xx-large">mdi-microsoft-excel</v-icon>
       <!-- <v-icon  @click="createPDF(item.id)" size="xx-large">mdi-printer</v-icon> -->
    </div>


    <div
            style="
            position: absolute;
            right: 130px;
            top: 15px;
            "
    >
        Liste angelegt am: {{item.date}}
    </div>

    <div
    style="margin-left: 18px"
    >
        {{item.note}}
    </div>


<card-collecting-items

        style="

        "

:collecting_id="item.id"
></card-collecting-items>




    <div
    style="

    position: absolute;
    bottom: 20px;
    right: 20px;
    "
    >
        <div
        style="float: left;
               margin: 3px;
               display: inline-block;
        "
        >
        <v-icon @click="copyData(item.id)" size="xx-large">mdi-content-copy</v-icon>
        </div>



        <div
        style="float: left;
               margin: 3px;
        "
        v-if="status != '3'"


        >
        <v-icon @click="saveVorlage(item.id)" size="xx-large">mdi-content-save</v-icon>
        </div>
      <div
          style="float: left;
               margin: 3px;
        "
          v-if="status != '0'"


      >
        <v-icon @click="editData(item.id)" size="xx-large">mdi-pencil</v-icon>
      </div>
        <div
        style="float: left;
               margin: 3px;
        "
        >
        <v-icon @click="showDialogDelete(item.id, item.name)" size="xx-large" color="red">mdi-delete-outline</v-icon>
        </div>




    </div>

</v-card>


    </div>



      <!--
LÖSCHEN DIALOG
-->

      <v-row justify="center">
          <v-dialog
                  v-model="dialog_delete"
                  persistent
                  max-width="600px"
          >

              <!--
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          Open Dialog
                        </v-btn>
                      </template>
              -->



              <v-card>
                  <v-card-title>
                      <span class="text-h5">Wirklich Löschen</span>
                  </v-card-title>

                  <v-card-text>
                      <v-container>
                          <v-row>
                              Wollen Sie den Datensatz&nbsp;<b>{{dialog_delete_text}}</b>&nbsp;wirklich löschen? (Liste wird ins Archiv verschoben))
                          </v-row>

                          <v-text-field
                                  v-model="dialog_delete_password"
                                  type="password"
                                  placeholder="Passwort"
                          ></v-text-field>

                      </v-container>

                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn

                              text
                              @click="dialog_delete = false"
                      >
                          Abbrechen
                      </v-btn>
                      <v-btn
                              color="red"
                              text
                              @click="deleteData()"
                      >
                          Löschen
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>



      <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              :color="snackbar_color"

      >
          {{ snackbar_title }}
          <!--
                <template v-slot:action="{ attrs }">
                  <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
           -->

      </v-snackbar>

    <v-fab-transition

    >
      <v-btn

          style="margin-top: 160px;"

          color="blue"
          dark
          fixed
          top
          right

          @click="openFileDialog"

      >
        <v-icon>mdi-import</v-icon>

        <div>LISTE EINLESEN</div>

      </v-btn>
    </v-fab-transition>



    <v-fab-transition

    >
      <v-btn

          style="margin-top: 110px;"

          color="green"
          dark
          fixed
          top
          right

          @click="openVorlagen"

      >
        <v-icon>mdi-content-save</v-icon>

        <div>VORLAGEN</div>

      </v-btn>
    </v-fab-transition>


    <v-fab-transition
        v-if="status == 3"
    >
      <v-btn

          style="margin-top: 60px;"

          color="pink"
          dark
          fixed
          top
          right

          @click="status = 1; loadData()"

      >
        <v-icon>mdi-close</v-icon>

        <div></div>

      </v-btn>
    </v-fab-transition>


      <v-fab-transition
      v-if="status == 1"
      >
          <v-btn

                  style="margin-top: 60px;"

                  color="pink"
                  dark
                  fixed
                  top
                  right

                  @click="showArchiv"

          >
              <v-icon>mdi-archive</v-icon>

              <div>AKTUELL</div>

          </v-btn>
      </v-fab-transition>


      <v-fab-transition
              v-if="status == 0"
      >
          <v-btn

                  style="margin-top: 60px;"

                  color="black"
                  dark
                  fixed
                  top
                  right

                  @click="showArchiv"

          >
              <v-icon>mdi-archive</v-icon>
              <div>ARCHIV</div>
          </v-btn>
      </v-fab-transition>








      <v-fab-transition>
      <v-btn
          color="pink"
          dark
          fixed
          bottom
          right
          fab
          @click="openAddView"

      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>


  </div>




  <!--
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col

          style="margin-top: 50px;"
          cols="12" sm="4">
        <v-img
            contain
            max-height="150"


            src="@/assets/images/comingsoon.png"
        ></v-img>

        <div
            style="margin: 10px;"

            justify="center" align="center"
        >In Bearbeitung...</div>

      </v-col>
    </v-row>
   </v-container>

   -->

</template>

<script>

import Vue from "vue";
import CardCollectingItems from "@/components/CardCollectingItems.vue";

export default {
  name: "ListCollecting",
    components: {CardCollectingItems},

    data () {
        return {

            title: 'Sammelliste',
            table: 'collecting',

            snackbar: false,
            snackbar_title: 'Gespeichert!',
            snackbar_color: 'red',
            timeout: 4000,

            componentKey: 0,

            dialog_delete: false,
            dialog_delete_text: '',
            dialog_delete_password: '',
            dialog_delete_id: '',

            data: [],
            data_items: [],

            status: 1,

        }
    },


  created() {

      this.loadData()

  },

  methods:{

    openVorlagen(){

      this.status = 3

      this.loadData()

    },


    async saveVorlage(id){
      console.log("SaveVorlage")

      console.log(name)
      console.log(id)


      var name = prompt("Name der Vorlage eingeben", "");

      if(name == null){
        return
      }

      var note

      // Einstellungen
      var param = {
        table: "collecting",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      note = result[0]["note"]


      // Einstellungen
      param = {
        table: "collecting",
        statement: "insert",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
        name: name,
        note: note,
        date: this.$moment(Date.now()).format('DD.MM.YYYY'),
        date_timestamp: this.$moment(Date.now()).format('YYYY-MM-DD'),
        status: "3",
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      var new_collecting_id = result["value"]


      // Einstellungen
      param = {
        table: "collecting_items",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "collecting_id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.data_items = []

      for (var i1 = 0; i1 < result.length; i1++) {
        this.data_items.push({id: result[i1]["id"], name: result[i1]["name"], qty_soll: result[i1]["qty_soll"], qty_ist: result[i1]["qty_ist"], note: result[i1]["note"]})

        this.addItem(new_collecting_id, this.data_items[i1])
      }

      this.status = "3"

      this.loadData()


    },




      async createCSV(id){

          // Einstellungen
          var param = {
              table: "collecting_items",
              statement: "custom",
              type: "detail",
              where: "true",
              selector: "collecting_id",
              value: id,
              short: "false", // Übermittlung nur von id
              // filter: "false",
              // filterfield: "",
              // filtervalue: "",
              // operator: "true",
              // Filter noch hinzufügen
              extension: "SELECT name, qty_soll, qty_ist, note FROM collecting_items WHERE collecting_id='" + id + "' ORDER BY name ASC",
          }

          // Daten -> Werden nur bei Insert oder Update gesendet
          var data = {
          }

          var result = await this.$func.queryAPI(param, data)

          // console.log("createCSV")
          // console.log(result)


          const downloadCSVFromJson = (filename, arrayOfJson) => {

              console.log("downloadCSVFromJson")
              // convert JSON to CSV
              const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
              const header = Object.keys(arrayOfJson[0])
              let csv = arrayOfJson.map(row => header.map(fieldName =>
                  JSON.stringify(row[fieldName], replacer)).join(';'))
              csv.unshift(header.join(';'))
              csv = csv.join('\r\n')

              console.log(csv)

              // Create link and download
              var link = document.createElement('a');
              link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
              link.setAttribute('download', filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          };

          downloadCSVFromJson("Export.csv", result)

      },



      async createPDF(id){
          console.log(id)

          if(id != ""){
              return
          }

          var param = {
              vorlage_name: "uebergabeschein.png",
              pdf_autor: "Hüska GmbH",
              pdf_name: "temp.pdf",
              pdf_title: "Übergabeschein",
              pdf_subject: "",
              pdf_name_crypt: "1234.pdf",
          }

          var data = {


              text: {
                  0: {
                      text: this.company.substring(0,50),
                      x: "10",
                      y: "40",
                  },
              },
          }



          /* Reifen Positionen werden hinzugefügt */

          var y = 110

          for (var i1 = 0; i1 <  this.items.length; i1++) {

              data["text"][11 + i1].text = this.items[i1]["order_qty"] + "                                        " + this.items[i1]["pickup_qty"]  + "                              " + this.items[i1]["article_name"]  + "                     " + "160103"
              data["text"][11 + i1].x = "10"
              data["text"][11 + i1].y = y

              y = Number(y) + 10

          }

          var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));
          console.log(Vue.prototype.$api_pdf_endpoint + "?" + request)

          const config = {
              method: 'GET',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              responseType: 'blob',
          }

          try {

              await Vue.prototype.$axios
                  .get(Vue.prototype.$api_pdf_endpoint + "?" + request, config)
                  .then(response => (this.responseText = response))

              // console.log(this.responseText)

              let blob = new Blob([this.responseText.data], { type: 'application/pdf' }),
                  url = window.URL.createObjectURL(blob)

              window.open(url) // Mostly the same, I was just experimenting with differen


              // var random_string = Math.random().toString(32).substring(2, 5) + Math.random().toString(32).substring(2, 5);
              // return this.responseText["data"]

          }catch{

              return {"status": "404"}

          }

      },

      showArchiv(){

          if(this.status == 1){
              this.status = 0
          }else{
              this.status = 1
          }

          this.loadData()

      },

   async loadData(){

          this.data = []

        // Einstellungen
        var param = {
            table: this.table,
            statement: "select",
            type: "detail",
            where: "true",
            selector: "status",
            value: this.status,
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data = {
        }

        var result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.data = result



    },

     async copyData(id){
         console.log("copyData")

         console.log(name)
         console.log(id)


         var name = prompt("Neue Listenname eingeben", "");

         if(name == null){
         return
         }

         var note

         // Einstellungen
         var param = {
             table: "collecting",
             statement: "select",
             type: "detail",
             where: "true",
             selector: "id",
             value: id,
             short: "true", // Übermittlung nur von id
             // filter: "false",
             // filterfield: "",
             // filtervalue: "",
             // operator: "true",
             // Filter noch hinzufügen
             extension: "",
         }

         // Daten -> Werden nur bei Insert oder Update gesendet
         var data = {
         }

         var result = await this.$func.queryAPI(param, data)
         console.log(result)


         note = result[0]["note"]


         // Einstellungen
         param = {
             table: "collecting",
             statement: "insert",
             type: "",
             where: "",
             selector: "",
             value: "",
             short: "false", // Übermittlung nur von id
             // filter: "false",
             // filterfield: "",
             // filtervalue: "",
             // operator: "true",
             // Filter noch hinzufügen
             extension: "",
         }

         // Daten -> Werden nur bei Insert oder Update gesendet
         data = {
            name: name,
            note: note,
            date: this.$moment(Date.now()).format('DD.MM.YYYY'),
            date_timestamp: this.$moment(Date.now()).format('YYYY-MM-DD'),
            status: "1",
         }

         result = await this.$func.queryAPI(param, data)
         console.log(result)


         var new_collecting_id = result["value"]


         // Einstellungen
           param = {
              table: "collecting_items",
              statement: "select",
              type: "detail",
              where: "true",
              selector: "collecting_id",
              value: id,
              short: "true", // Übermittlung nur von id
              // filter: "false",
              // filterfield: "",
              // filtervalue: "",
              // operator: "true",
              // Filter noch hinzufügen
              extension: "",
          }

          // Daten -> Werden nur bei Insert oder Update gesendet
          data = {
          }

         result = await this.$func.queryAPI(param, data)
          console.log(result)

         this.data_items = []

         for (var i1 = 0; i1 < result.length; i1++) {
             this.data_items.push({id: result[i1]["id"], name: result[i1]["name"], qty_soll: result[i1]["qty_soll"], qty_ist: result[i1]["qty_ist"], note: result[i1]["note"]})

             this.addItem(new_collecting_id, this.data_items[i1])
         }

         this.status = "1"

         this.loadData()

      },

      async addItem(new_collecting_id, mydata){
        console.log("addItem")

          // Einstellungen
          var param = {
              table: "collecting_items",
              statement: "insert",
              type: "",
              where: "false",
              selector: "",
              value: "",
              short: "true", // Übermittlung nur von id
              // filter: "false",
              // filterfield: "",
              // filtervalue: "",
              // operator: "true",
              // Filter noch hinzufügen
              extension: "",
          }

          // Daten -> Werden nur bei Insert oder Update gesendet
          var data = {
              collecting_id: new_collecting_id,
              name: mydata["name"],
              qty_soll: mydata["qty_soll"],
              note: mydata["note"],
          }

          var result = await this.$func.queryAPI(param, data)
          console.log(result)

      },


      editData(id){

          this.$router.push({ path: "FormCollecting" + "/" + id, params:{id: id} })

      },


      showDialogDelete(id, text){

       this.dialog_delete = true
       this.dialog_delete_id = id
       this.dialog_delete_text = text

      },


      async deleteData(status){


          if(this.dialog_delete_password != Vue.prototype.$password){
              this.snackbar_title = "Password Falsch!"
              this.snackbar_color = "red"
              this.snackbar = true
              return
          }

          var param
          var data
          var result

          if (this.status == 1){
              // Einstellungen
              param = {
                  table: this.table,
                  statement: "update",
                  type: "",
                  where: "true",
                  selector: "id",
                  value: this.dialog_delete_id,
                  short: "true", // Übermittlung nur von id
                  // filter: "false",
                  // filterfield: "",
                  // filtervalue: "",
                  // operator: "true",
                  // Filter noch hinzufügen
                  extension: "",
              }

              // Daten -> Werden nur bei Insert oder Update gesendet
              data = {
                  status: '0'
              }

              result = await this.$func.queryAPI(param, data)
              console.log(result)

          }else{
              // Einstellungen
              param = {
                  table: this.table,
                  statement: "delete",
                  type: "",
                  where: "true",
                  selector: "id",
                  value: this.dialog_delete_id,
                  short: "true", // Übermittlung nur von id
                  // filter: "false",
                  // filterfield: "",
                  // filtervalue: "",
                  // operator: "true",
                  // Filter noch hinzufügen
                  extension: "",
              }

              // Daten -> Werden nur bei Insert oder Update gesendet
              data = {
              }

              result = await this.$func.queryAPI(param, data)
              console.log(result)

          }


          // Datensatz neu laden
          this.loadData()

          this.dialog_delete = false


      },



    openFileDialog(){

      this.$refs.file.click()


    },

    async parseFile(e){

      console.log(this.$refs.file.files[0])

      var file = this.$refs.file.files[0]

      var content = null

      const reader = new FileReader();

        reader.onload = (res) => {
          content = res.target.result;
            this.createNewList(content, file.name)
        };

        reader.onerror = (err) => console.log(err);
        await reader.readAsText(file, 'utf-8')

      },



    async createNewList(e, filename){

      var importFile = e.split("\r\n")

      for (var i1 = 0; i1 < importFile.length; i1++) {

        importFile[i1] = importFile[i1].toString().replaceAll('\"', '')
        importFile[i1] = importFile[i1].toString().replaceAll('"', '')

      }

      // console.log(importFile)


      if (importFile[0] != "name;qty_soll;qty_ist;note"){
        alert("Bitte überprüfen Sie die Import Datei - Format beachten!")
        return
      }


      // Einstellungen
      var param1 = {
        table: "collecting",
        statement: "insert",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
        name: filename,
        note: "",
        date: this.$moment(Date.now()).format('DD.MM.YYYY'),
        date_timestamp: this.$moment(Date.now()).format('YYYY-MM-DD'),
        status: "1",
      }


      var result1 = await this.$func.queryAPI(param1, data1)
      console.log(result1)


      var new_collecting_id = result1["value"]

      this.data_items = []

      var data_importfile = []


      for (var i2 = 1; i2 < importFile.length; i2++) {

        // name;qty_soll;qty_ist;note

        data_importfile = []

        data_importfile = importFile[i2].split(";")

        // console.log(data_importfile)

        this.data_items.push({name: data_importfile[0], qty_soll: data_importfile[1], qty_ist: data_importfile[2], note: data_importfile[3]})

        this.addItem(new_collecting_id, this.data_items[i2 - 1])

      }

      alert("Liste wurde importiert!")

      location.reload()



    },



    openAddView() {
      this.$router.push({ path: "FormCollecting" })
    }


  }



}
</script>

<style scoped>

</style>
