<template>
  <v-dialog
      v-model="modal1"
      persistent
      width="490px"
      height="600px"

  >

      <gmap-map
          :center='center'
          :zoom='16'
          style='width: 490px;  height: 600px;'
      >

        <GmapMarker
            :position="center"
        />

      </gmap-map>


    <v-btn
        text
        color="pink"
        @click="btnClose"
        style=""
    >
      Abbrechen
    </v-btn>

  </v-dialog>
</template>

<script>
export default {
  name: "DialogMap",

  data () {
    return {

      modal1: true,
      center: {lat: Number(this.lat), lng: Number(this.lng)},
     // center: { lat: 50.64661, lng: 8.22819 },

    }
  },

  created() {


  },

  methods:{

    btnClose(){
      this.$emit('reset', '')
    }


  },

  props:{

    lat:{
      type: Number,

    },

    lng:{
      type: Number,

    },




  },


}
</script>

<style scoped>

</style>
