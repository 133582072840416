<template>

  <v-layout

  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <v-dialog
            ref="dialog1"
            v-model="modal1"
            :return-value.sync="date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal1 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>


        <v-select
            v-model="typ"
            :items="items_typ"
            item-text="text"
            item-value="value"
            label="Typ"
            outlined
            selec

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-select>


<!--
        <v-select
            :items="items_article"
            label="Artikel"
            outlined

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-select>
-->


        <CustomSelect
            v-if="typ == 'sell'"

            v-model="sell_typ"
            v-bind:table="'group_sell_typ'"
            v-bind:field_text="'text'"
            v-bind:field_value="'id'"
            v-bind:label="'Verkauf Typ'"
            v-bind:selected="sell_typ_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></CustomSelect>




        <CustomSelect
        v-if="typ == 'disposal'"

            v-model="disposer"
            v-bind:table="'disposer'"
            v-bind:field_text="'company'"
            v-bind:field_value="'id'"
            v-bind:label="'Entsorger'"
            v-bind:selected="disposer_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></CustomSelect>

<!--
        <v-checkbox
            v-if="typ == 'disposal'"
            v-model="checkbox"
            label="Entsorgung anlegen"
            disabled
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-checkbox>
-->


<div

>
        <v-text-field
            v-model="name"
            sm="4"
            md="4"
            label="Name"
            required
            outlined
            clearable
            type="search"
            autocomplete="off"
            style="
  display: inline-block;
  width: 265px;
  margin: 0;
"

        ></v-text-field>
        <v-btn @click="SelectCustomer = true"
               style="
  display: inline-block;
  margin-left: 20px;
  width: 20px;
  height: 50px;

"
        >
          <v-icon

          >mdi-account-box</v-icon>
          </v-btn>
</div>

        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz Nr."
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


<!--
        <CustomSelect
            v-model="article"
            v-bind:table="'article'"
            v-bind:field_text="'name'"
            v-bind:field_value="'id'"
            v-bind:label="'Artikel'"
            v-bind:selected="article_name"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></CustomSelect>
 -->

        <v-select
        outlined
        v-model="article"

        :items="article_list"
        item-text="name"
        item-value="id"
        return-object

        style="
              display: table;
              margin: 0 auto;
              width: 350px;"
        >

        </v-select>

        <v-text-field
            v-model="qty"
            sm="12"
            md="4"
            label="Anzahl"
            required
            outlined
            type="number"
            autocomplete="off"
            @change="CalcWeight"
            @keydown="CalcWeight"

            style="
              display: table;
              margin: 0 auto;
              width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="article_weight"
            disabled
            sm="12"
            md="4"
            label="Artikel Gewicht in KG"
            required
            outlined
            color="pink"
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="weight"
            sm="12"
            md="4"
            label="Gesamtgewicht in T (Gesamt)"
            required
            outlined
            color="pink"
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <div
        v-if="wasteno == '160103'"
        >

        <v-text-field
            v-if="typ != 'sell'"
            v-model="scrap_quantity_weight"
            sm="12"
            md="4"
            label="Schrottanteil in T"
            required
            outlined
            color="pink"
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


          <v-text-field
              v-if="typ != 'disposal'"
              v-model="good_quantity_weight"
              sm="12"
              md="4"
              label="Gutanteil in T"
              required
              outlined
              color="pink"
              type="text"
              autocomplete="off"
              style="
      display: table;
      margin: 0 auto;
      width: 350px;"

          ></v-text-field>

          <!--
          <v-text-field
              v-model="scrap_quantity_qty"
              sm="12"
              md="4"
              label="Schrottanteil in Stück (NUR ZUR INFO)"
              required
              outlined
              color="pink"
              type="number"
              autocomplete="off"
              style="
      display: table;
      margin: 0 auto;
      width: 350px;"

          ></v-text-field>
          -->


          <v-row

              v-if="rating != ''"

              style="
      display: table;
      margin: 0 auto;
      width: 150px;"
          >

        <v-text-field
            v-model="rating"
            sm="12"
            md="4"
            label="Qualitätsbewertung (1-5)"
            required
            outlined
            color="pink"
            type="number"
            autocomplete="off"
            style="

      width: 150px;"
        ></v-text-field>



          <v-text-field
              v-model="rating_value"
              sm="12"
              md="4"
              label="Gutanteil in %"
              required
              outlined
              color="pink"
              type="text"
              autocomplete="off"
              style="
      width: 100px;"
          ></v-text-field>


          </v-row>


<!--
          <div v-if="item.rating == 1">Qualität: Sehr Schlecht 5% <v-icon style="color: red">mdi-emoticon-frown-outline</v-icon></div>
          <div v-if="item.rating == 2">Qualität: Schlecht 15% <v-icon style="color: orange">mdi-emoticon-sad-outline</v-icon></div>
          <div v-if="item.rating == 3">Qualität: Neutral 25% <v-icon style="color: yellow">mdi-emoticon-neutral-outline</v-icon></div>
          <div v-if="item.rating == 4">Qualität: Gut 35% <v-icon style="color: lime">mdi-emoticon-happy-outline</v-icon></div>
          <div v-if="item.rating == 5">Qualität: Sehr gut 45% <v-icon  style="color: green">mdi-emoticon-outline</v-icon></div>
-->


        </div>

        <v-select
        v-model="wasteno"
        outlined
        :items="wasteno_list"
        label="Abfallschlüssel"
        style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        >
        </v-select>


        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>


        <v-text-field

            v-if="driver_name != ''"
            v-model="driver_name"
            disabled
            sm="12"
            md="4"
            label="Fahrer"
            outlined
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>

        <v-text-field
            v-if="tour_id != ''"
            v-model="tour_id"
            disabled
            sm="12"
            md="4"
            label="Tour Nr."
            outlined
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>



        <v-col
            sm="12"
            md="12"
            class="text-right"
        >

          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>



    <SelectCustomer
        v-if="SelectCustomer"
        @SelectedCustomer="loadCustomer"
        @SelectCustomerReset="SelectCustomerReset"
    ></SelectCustomer>


  </v-layout>

</template>

<script>

import CustomSelect from "@/components/CustomSelect";
import SelectCustomer from "@/components/SelectCustomer";

export default {


  name: "FormStock",

  components:{
    CustomSelect,
    SelectCustomer,
  },


  data () {
    return {
      valid: true,

      title: 'Lagerbuchung',
      mainview: 'ListStock',
      table: 'stock',

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      time: this.$moment(Date.now()).format('HH:mm:ss'),

      modal1: '',
      SelectCustomer: false,

      id: '',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',
      timeout: 2500,

      typ: '',
      checkbox: '',

      disposer: '',
      disposer_id: '',
      disposer_company: '',

      sell_typ: '',
      sell_typ_id: '',
      sell_typ_text: '',

      customer_id: '',
      name: '',
      document_no: '',
      qty: '',
      weight: 0,
      rating: '',
      rating_value: '',
      wasteno: '',
      wasteno_list: [],

      driver_name: '',
      tour_id: '',

      good_quantity_weight: 0,
      scrap_quantity_weight: 0,
      scrap_quantity_qty: 0,


      article: {id: '', name: ''}, // {id: , name:}
      article_id: '',
      article_name: '',
      article_weight: '',

      article_list: [],

      note: '',

      items_typ: [
        { text: 'Eingang', value: 'buy' },
        { text: 'Verkauf', value: 'sell' },
        { text: 'Verschrottung', value: 'disposal' },
     //   { text: 'Korrekturbuchung', value: 'inventory' },
      ],

    }
  },


  async created() {

    // console.log("TIME")
    // console.log(this.$moment(new Date()).format("DD.MM.YYYY"))

    this.date = this.$moment(Date.now()).format("YYYY-MM-DD")

    await this.getAllWasteNo()

    await this.getAllArticle()


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }



  },



  methods:{

    // Liefert den Abfallschlüssel - Falls nicht vorhanden dann 0
    async getWasteNo(article_id){
      console.log("getWasteNo")
      console.log(article_id)

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.wasteno = result[0]["wasteno"]

    },

    // Liefert das Artikelgewicht - Falls nicht vorhanden dann 0
    async getWeight(article_id){
      console.log("getWeight")
      console.log(article_id)

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.article_weight = result[0]["weight"]

    },

    // Ermittel alle Abfallnummern
    async getAllWasteNo(){
      console.log("getAllWasteNo")

      // Einstellungen
      var param = {
        table: "article",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT wasteno  FROM `article` GROUP BY wasteno"

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)



      this.wasteno_list.push("")

      for (var i1 = 0; i1 < result.length; i1++) {


        if(result[i1]["wasteno"] != "0"){
          this.wasteno_list.push(result[i1]["wasteno"])
        }


      }

      console.log(this.wasteno_list)

    },

    async getAllArticle(){
      console.log("getAllArticle")

      // Einstellungen
      var param = {
        table: "article",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT id, name  FROM `article` GROUP BY name ORDER BY name ASC"

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.article_list = []


     // this.article_list[0] = { id:'', name: '' }

      for (var i1 = 0; i1 < result.length; i1++) {
        this.article_list[i1] = { id: result[i1]["id"].toString(), name: result[i1]["name"].toString() }
      }

      console.log("this.article_list")
      console.log(this.article_list)


    },


    btnCancel(){
      this.$router.push({ name: this.mainview })
    },

    btnSave(){

      this.$refs.form.validate()

      this.saveData()
    },

    CalcWeight(){

      if(this.qty == ""){
        return
      }

      if(this.article.id == ""){
       // alert("Bitte erst Artikel wählen")
        return
      }

      this.loadWeight(this.article.id)

    },

    async loadCustomer(id){
      console.log("loadCustomer")

      this.customer_id = id

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.customer_id = result[0]["id"]
      this.name = result[0]["company"]


    },

    SelectCustomerReset(){
      this.SelectCustomer = false
    },

    async loadWeight(id) {

      console.log("loadWeight")
      console.log(id)


      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)


      this.weight = (result[0]["weight"] * this.qty) / 1000
      this.weight = this.weight.toString().replace(",",".")

      console.log("this.weight")
      console.log(this.weight)

    },

    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)




      if (result[0]["date"] == null){
        this.date = this.$moment(Date.now()).format("YYYY-MM-DD")
      }else{
        this.date = this.$moment(result[0]["date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }

      if (result[0]["time"] == null){
        this.time = this.$moment(Date.now()).format("HH:mm:ss")
      }else{
        this.time = result[0]["time"]
      }


      this.disposer = result[0]["disposer_id"]
      this.disposer_id = result[0]["disposer_id"]
      this.disposer_company = result[0]["disposer_company"]

      this.sell_typ_id = result[0]["sell_typ_id"]
      this.sell_typ_text = result[0]["sell_typ_text"]

      this.typ = result[0]["typ"]

      this.article = {id: result[0]["article_id"], name: result[0]["article_name"]}
      this.article_id = result[0]["article_id"]
      this.article_name = result[0]["article_name"]
      this.article_weight = result[0]["article_weight"]

      this.customer_id = result[0]["customer_id"]
      this.name = result[0]["name"]
      this.document_no = result[0]["document_no"]
      this.weight = result[0]["weight"]
      this.qty = result[0]["qty"]
      this.note = result[0]["note"]

      this.wasteno = result[0]["wasteno"]
      this.rating = result[0]["rating"]
      this.rating_value = result[0]["rating_value"]

      console.log("this.rating")
      console.log(this.rating)

      if(this.rating == undefined){
        this.rating = ""
      }
      if(this.rating_value == undefined){
        this.rating_value = ""
      }


      if(this.rating_value == "") {

        // Einstellungen
        var param2 = {
          table: "parameter",
          statement: "custom",
          type: "",
          where: "false",
          selector: "",
          value: "",
          short: "false", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
        }


        // Daten -> Werden nur bei Insert oder Update gesendet
        var data2 = {}

        var result2 = await this.$func.queryAPI(param2, data2)

        // console.log("result2")
        // console.log(result2)

        switch (this.rating.toString()) {
          case "1":
            this.rating_value = result2[0]["wert"]
            break;
          case "2":
            this.rating_value = result2[1]["wert"]
            break;
          case "3":
            this.rating_value = result2[2]["wert"]
            break;
          case "4":
            this.rating_value = result2[3]["wert"]
            break;
          case "5":
            this.rating_value = result2[4]["wert"]
            break;
          default:
            this.rating_value = result2[4]["wert"]
        }

      }


      this.driver_name = result[0]["driver_name"]
      this.tour_id = result[0]["tour_id"]

      this.good_quantity_weight = result[0]["good_quantity_weight"] ?? "0"
      this.scrap_quantity_weight = result[0]["scrap_quantity_weight"] ?? "0"

      this.scrap_quantity_weight = this.scrap_quantity_weight.toString().replace(",",".")


      this.scrap_quantity_qty = result[0]["scrap_quantity_qty"] ?? "0"

      if (result[0]["date"] == null || result[0]["date"] == ""){
        this.date = ""
      }else{
        this.date = this.$moment(result[0]["date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }

    },



    async saveData(){

      if(this.typ.trim === ""){
        this.snackbar_title = "Bitte Typ auswählen!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.typ == "disposal"){

        if(this.disposer_company.trim() == ""){
          this.snackbar_title = "Bitte Entsorger auswählen!"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }

        if(this.name == ""){
          this.name = this.disposer_company
        }

      }

      if(this.weight == ""){
        this.snackbar_title = "Bitte Gewicht eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

if (this.wasteno == "160103"){

  if(this.typ == "disposal"){
    if(this.scrap_quantity_weight == ""){
      this.snackbar_title = "Schrottanteil eingeben!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }
    if(this.scrap_quantity_weight == 0){
      this.snackbar_title = "Schrottanteil eingeben!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }
  }


  if(this.typ == "sell"){

    if(this.good_quantity_weight == ""){
      this.snackbar_title = "Gutanteil bei Verkauf eingeben!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

    if(this.good_quantity_weight == 0){
      this.snackbar_title = "Gutanteil bei Verkauf kann nicht 0 sein!"
      this.snackbar_color = "red"
      this.snackbar = true
      return
    }

  }


}else{
    this.scrap_quantity_weight = this.weight
}

      if(this.typ == "buy"){
        if(Number(this.weight) != (Number(this.scrap_quantity_weight) + Number(this.good_quantity_weight))){
          this.snackbar_title = "Bitte prüfen Sie das Gewicht! - Gesamtgewicht muss in Gut und Schrottanteil aufgeteilt werden"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }
      }




      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Format Weight
      this.weight = this.weight.toString().replace(",",".")
      this.scrap_quantity_weight = this.scrap_quantity_weight.toString().replace(",",".")
      this.good_quantity_weight = this.good_quantity_weight.toString().replace(",",".")

      // Gewicht wird negativ
      if(this.typ != "buy"){
        this.weight = -Math.abs(this.weight)
        this.scrap_quantity_weight = -Math.abs(this.scrap_quantity_weight)
        this.good_quantity_weight = -Math.abs(this.good_quantity_weight)
      }





      // Data
      var data = {

        disposer_id: this.disposer_id,
        disposer_company: this.disposer_company,

        sell_typ_id: this.sell_typ_id,
        sell_typ_text: this.sell_typ_text,

        customer_id: this.customer_id,
        name: this.name,
        document_no: this.document_no,
        typ: this.typ,

        average_weight: localStorage.average_weight,

        date: this.computedDate,
        date_timestamp: this.$moment(this.computedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
        time: this.time,

        article_id: this.article.id,
        article_name: this.article.name,
        article_weight: this.article_weight,

        wasteno: this.wasteno,

        scrap_quantity_weight: this.scrap_quantity_weight,
        scrap_quantity_qty: this.scrap_quantity_qty,
        good_quantity_weight: this.good_quantity_weight,

        rating: this.rating,
        rating_value: this.rating_value,

        qty: this.qty,
        weight: this.weight,
        price: this.price,
        note: this.note,

        worker: localStorage.username,

      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true

      if(statement == "insert"){
        this.id = result["value"]
      }

    }


  },


  watch:{

    typ: function () {
      console.log(this.typ)
    },


    disposer: function () {

      console.log(this.disposer)

      if(this.disposer == null){
        return
      }


      if(this.disposer.trim() != ";" && this.disposer.includes(";") == true){
        this.disposer_id = this.disposer.split(";")[1]
        this.disposer_company = this.disposer.split(";")[0]
      }

    },

    sell_typ: function () {

      console.log(this.sell_typ)

      if(this.sell_typ.trim() != ";" && this.sell_typ.includes(";") == true){
        this.sell_typ_id = this.sell_typ.split(";")[1]
        this.sell_typ_text = this.sell_typ.split(";")[0]
      }

    },

    article: function () {
      console.log("watch article")
      console.log(this.article)

      if(this.article.id != ""){
        this.getWeight(this.article.id)
        this.getWasteNo(this.article.id)
      }






    },



    article_name: function () {


    }


  },


  computed: {

    computedDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      return this.$moment(this.date).format("DD.MM.YYYY")
    },





    /*
    checkboxErrors () {
      const errors = []
      if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('You must agree to continue!')
      return errors
    },
    selectErrors () {
      const errors = []
      if (!this.$v.select.$dirty) return errors
      !this.$v.select.required && errors.push('Item is required')
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
*/

  },


  props:{

  }









}
</script>

<style scoped>

</style>
