<template>
  <v-layout

  >



    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"
        elevation-10
    >


      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <v-text-field
            v-model="name"
            sm="12"
            md="4"
            label="Listenname"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-textarea>


          <GroupListCollecting
                  :key="componentKey"
                  v-if="id !== ''"
                  v-bind:title="'Reifen zum sammeln'"
                  v-bind:table="'collecting_items'"
                  v-bind:collecting_id="id"
                  v-bind:values="values_article"
                  v-bind:editable="true"
                  v-bind:elevation="'0'"
          >
          </GroupListCollecting>




        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>


        <!--
        </v-col>
        -->

        <v-row
            style="padding: 10px"
        >


          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin-right: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>

        <div
            style="padding: 10px;"
        ></div>


      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>
import GroupListCollecting from "@/components/GroupListCollecting.vue";
import GroupList from "@/components/GroupList.vue";

export default {
  name: "FormCollecting",
    components: {GroupList, GroupListCollecting},

  data () {
    return {

      title: 'Sammelliste',
      mainview: 'ListCollecting',
      table: 'collecting',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 2500,

      componentKey: 0,

      id: '',
      name: '',
      note: '',


        values_article: [

            {
                name: 'Reifentyp',
                text:  'name',
                value:  'name',
                required: true,
                typ: 'select',
                select_table: 'group_tires',
                select_text: 'name',
                select_value: 'name',
                select_label: 'Reifentyp',
            },
            {
                name: 'Stückzahl SOLL',
                value:  'qty_soll',
                required: true,
                typ: 'text'
            },
            {
                name: 'Stückzahl IST',
                value:  'qty_ist',
                required: false,
                typ: 'text'
            },
            {
                name: 'Bemerkung',
                value:  'note',
                required: false,
                typ: 'text'
            },


        ],

    }
  },

  created() {


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id
      this.loadData()
    }


  },


  methods:{

    async loadData(){

        // Einstellungen
        var param = {
            table: this.table,
            statement: "select",
            type: "detail",
            where: "true",
            selector: "id",
            value: this.id,
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data = {
        }

        var result = await this.$func.queryAPI(param, data)

        console.log(result)

        this.name = result[0]["name"]
        this.note = result[0]["note"]


    },


    async saveData(){

        var statement

        if(this.id == ""){
            statement = "insert"
        }else{
            statement = "update"
        }

        // Parameter
        var param = {
            table: this.table,
            statement: statement,
            type: "",
            where: "true",
            selector: "id",
            value: this.id,
            short: "true", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "",
        }

        // Data
        var data = {
            name: this.name,
            note: this.note,
            worker: localStorage.username,
        }

        if(this.id == ""){
            data["date"] = this.$moment(Date.now()).format('DD.MM.YYYY')
            data["date_timestamp"] = this.$moment(Date.now()).format('YYYY-MM-DD')
        }

        var result = await this.$func.queryAPI(param, data)
        console.log(result)

        this.snackbar_title = "Gespeichert!"
        this.snackbar_color = "green"
        this.snackbar = true


        if(statement == "insert"){
            this.id = result["value"].toString()
            this.forceRerender()
        }




    },

      forceRerender() {
          this.componentKey += 1;
      },


      btnCancel(){

          this.$router.push({ name: "ListCollecting" })

      }


  }

}
</script>

<style scoped>

</style>
