<template>
  <v-layout
      align-center
      justify-center
  >

    <v-container>
      <v-row>

      <CardPickupQuickView
          v-for="item in tasks"
          :key="item.id"
          v-bind:task_id="item.id"
          v-bind:type="type"
      ></CardPickupQuickView>

      </v-row>

    </v-container>
  </v-layout>
</template>

<script>

import CardPickupQuickView from '@/components/CardPickupQuickView'

export default {
  name: "ListCheck",

  components:{
    CardPickupQuickView,
  },

  data () {

    return {
      tasks: [],
      type: "all",
    }

  },

  created() {

    this.loadData()

  },

  methods: {

    async loadData(){


    console.log("loadTasks")


    // Einstellungen
    var param = {
      table: "task",
      statement: "select",
      type: "list",
      where: "false",
      selector: "",
      value: "",
      short: "true", // Übermittlung nur von id
      // filter: "false",
      // filterfield: "",
      // filtervalue: "",
      // operator: "true",
      // Filter noch hinzufügen
      extension: "",
    }

      if(this.$route.params.id != undefined) {
        // this.id = this.$route.params.id
        param.extension = "WHERE group_type_id='" + this.$route.params.id + "' AND (pickup_date IS NOT NULL OR pickup_date<>'') ORDER BY pickup_timestamp DESC LIMIT 200"
        this.type = "customer"
      }else{
        param.extension = "WHERE (pickup_date IS NOT NULL OR pickup_date<>'') ORDER BY pickup_timestamp DESC LIMIT 100"
        this.type = "all"
      }



    // Daten -> Werden nur bei Insert oder Update gesendet
    var data = {}

    var result = await this.$func.queryAPI(param, data)
    console.log(result)


    this.tasks = []

    for (var i1 = 0; i1 < result.length; i1++) {
      this.tasks.push({id:  result[i1]["id"]})
    }

    console.log(this.tasks)

    }
}


}
</script>

<style scoped>

</style>
