<template>
  <v-layout

  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >

        <CustomCombobox
            v-model="carrier"
            v-bind:table="'carrier'"
            v-bind:field_text="'company'"
            v-bind:field_value="'id'"
            v-bind:label="'Spediteur'"
            v-bind:selected="carrier_company"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></CustomCombobox>



        <v-dialog
            ref="dialog1"
            v-model="modal1"
            :return-value.sync="date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              color="pink"
              scrollable
              locale="de-de"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal1 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>


        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz Nr."
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="price"
            sm="12"
            md="4"
            label="Betrag in EUR (Optional)"
            required
            outlined
            type="number"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="weight"
            sm="12"
            md="4"
            label="Gewicht in Tonnen"
            required
            outlined
            type="number"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>



<!--

        <v-checkbox
            v-model="checkbox"
            label="Lagerabbuchung (ZUBUCHUNG)"
            disabled
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-checkbox>
-->


        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>



        <v-switch
            v-model="invoice"
            label="Rechnung erhalten"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>


        <v-switch
            v-model="invoice_forward"
            label="Weiterberechnen"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>

        <v-text-field
            v-if="invoice_forward == true"
            v-model="invoice_forward_written"
            sm="12"
            md="4"
            label="Weiterberechnen RE-Nr"
            required
            outlined
            type="text"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>



<!--


        <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="invoice_date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoiceDate"
                label="Rechnungsdatum"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal2 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog2.save(invoice_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

-->



<!--

        <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="invoice_payment_deadline"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoicePaymentDeadLine"
                label="Zahlungsfrist am"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_payment_deadline"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal3 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog3.save(invoice_payment_deadline)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>









        <v-dialog
            ref="dialog4"
            v-model="modal4"
            :return-value.sync="invoice_payment_date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedInvoicePaymentDate"
                label="Rechnung bezahlt am"
                prepend-icon="mdi-calendar"
                :clearable="true"
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="invoice_payment_date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal4 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog4.save(invoice_payment_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

-->



        <v-col
            sm="12"
            md="12"
            class="text-right"
        >

          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>
import CustomCombobox from "@/components/CustomCombobox";

export default {
  name: "FormTransport",

  components:{
    CustomCombobox,
  },

  data () {
    return {

      title: 'Transport',
      mainview: 'ListTransport',
      table: 'transport',

      modal1: '',
      modal2: '',
      modal3: '',
      modal4: '',

      checkbox: '', // Lagerbuchung
      // date: this.$moment(new Date()).format("DD.MM.YYYY").toISOString(),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',
      timeout: 2500,


      id: '',
      carrier: '',
      carrier_id: '',
      carrier_company: '',
      document_no: '',
      company: '',
      price: '',
      weight: '',

      invoice: false,
      invoice_forward: false,
      invoice_forward_written: '',
      invoice_date: '',
      invoice_payment_deadline: '',
      invoice_payment_date: '',
      note: '',

    }
  },


  created() {

    // console.log("TIME")
    // console.log(this.$moment(new Date()).format("DD.MM.YYYY"))


    this.date = this.$moment(Date.now()).format("YYYY-MM-DD")


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },

  watch:{

    carrier: function () {

      // this.carrier_company = this.carrier

    }


  },


  methods:{

    btnCancel(){

      this.$router.push({ name: this.mainview })

    },

    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      if (result[0]["date"] == null){
        this.date = this.$moment(Date.now()).format("YYYY-MM-DD")
      }else{
        this.date = this.$moment(result[0]["date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }


      // this.carrier = result[0]["carrier_company"]

      this.carrier_company = result[0]["carrier_company"]
      this.document_no = result[0]["document_no"]
      this.weight = result[0]["weight"]
      this.price = result[0]["price"]
      this.note = result[0]["note"]

      if(result[0]["invoice"] == "" || result[0]["invoice"] == null){
        this.invoice = false
      }else{
        this.invoice = result[0]["invoice"]
      }

      if(result[0]["invoice_forward"] == "" || result[0]["invoice_forward"] == null){
        this.invoice_forward = false
      }else{
        this.invoice_forward = result[0]["invoice_forward"]
      }

      this.invoice_forward_written = result[0]["invoice_forward_written"]

      // this.checkbox = result[0]["note"]

      if (result[0]["invoice_date"] == null || result[0]["invoice_date"] == ""){
        this.invoice_date = ""
      }else{
        this.invoice_date = this.$moment(result[0]["invoice_date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }
      if (result[0]["invoice_payment_date"] == null || result[0]["invoice_payment_date"] == ""){
        this.invoice_payment_date = ""
      }else{
        this.invoice_payment_date = this.$moment(result[0]["invoice_payment_date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }
      if (result[0]["invoice_payment_deadline"] == null || result[0]["invoice_payment_deadline"] == ""){
        this.invoice_payment_deadline = ""
      }else{
        this.invoice_payment_deadline = this.$moment(result[0]["invoice_payment_deadline"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }



    },

    async saveData(){

      if(this.carrier == null){
        this.snackbar_title = "Bitte Spediteur eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.carrier.toString().trim() == ""){
        this.snackbar_title = "Bitte Spediteur eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.invoice == true){
        if(this.note == ""){
          this.snackbar_title = "Bitte Rechnungsnummer eingeben!"
          this.snackbar_color = "red"
          this.snackbar = true
          return
        }
      }

      this.price = -Math.abs(this.price)
      this.price = Number(this.price).toFixed(2)
      this.price = this.price.toString().replace(",",".")



      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {

        carrier_id: this.carrier_id,
        carrier_company: this.carrier,

        document_no: this.document_no,

        invoice: this.invoice,
        invoice_forward: this.invoice_forward,
        invoice_forward_written: this.invoice_forward_written,
        invoice_date: this.computedInvoiceDate,
        invoice_payment_deadline: this.computedInvoicePaymentDeadLine,
        invoice_payment_date: this.computedInvoicePaymentDate,

        date: this.computedDate,
        date_timestamp: this.$moment(this.computedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),

        weight: this.weight.toString().replace(",","."),
        price: this.price,
        note: this.note,
      }


      var result = await this.$func.queryAPI(param, data)

      console.log(result)


      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true


      if(statement == "insert"){
        this.id = result["value"]
      }


    }










  },


  computed: {
    computedDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      return this.$moment(this.date).format("DD.MM.YYYY")
    },


    computedInvoiceDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      if(this.invoice_date == ""){
        return ""
      }else{
        return this.$moment(this.invoice_date).format("DD.MM.YYYY")
      }
    },


    computedInvoicePaymentDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      if(this.invoice_payment_date == ""){
        return ""
      }else{
        return this.$moment(this.invoice_payment_date).format("DD.MM.YYYY")
      }
    },


    computedInvoicePaymentDeadLine () {
      // return this.$moment(this.date).format('dd.mm.YYYY')

      if(this.invoice_payment_deadline == ""){
        return ""
      }else{
        return this.$moment(this.invoice_payment_deadline).format("DD.MM.YYYY")
      }

    },


  },





}
</script>

<style scoped>

</style>
