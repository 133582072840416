<template>
  <v-card
      class="mx-auto"
      width="450"
      style="margin: 10px;"
  >
    <v-card-text>
      <div>AUFTRÄGE (Anzahl / Anzahlartikel)</div>

      <p class="text-h4" style="color: red">&blk34; {{task_red}} / {{task_qty_red}}</p>
      <p class="text-h4" style="color: orange">&blk34; {{task_orange}} / {{task_qty_orange}}</p>
      <p class="text-h4" style="color: green">&blk34; {{task_green}} / {{task_qty_green}}</p>

      <div class="text--primary">

      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          text
          color="pink accent-3"
          @click="openPage"
      >
        Details
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "CardPrioInfo",


  data () {
    return {

      task_red: 0,
      task_orange: 0,
      task_green: 0,

      task_qty_red: 0,
      task_qty_orange: 0,
      task_qty_green: 0,

      last_timestamp: '',

    }
  },

  async created() {

    await this.loadData()

  },



  methods:{


    async loadData(){

      console.log("calcTotalWeight")

      // Einstellungen
      var param = {
        table: "task",    // Tabellen Name
        statement: "select",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "list",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "false",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "WHERE pickup_date IS NULL OR pickup_date=''"
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      for (var i1 = 0; i1 < result.length; i1++) {

        if(result[i1]["prio"] == "1"){
        this.task_green = Number(this.task_green) + 1
        this.task_qty_green = Number(this.task_qty_green) + Number(result[i1]["order_qty"])
        }
        if(result[i1]["prio"] == "2"){
          this.task_orange = Number(this.task_orange) + 1
          this.task_qty_orange = Number(this.task_qty_orange) + Number(result[i1]["order_qty"])
        }
        if(result[i1]["prio"] == "3"){
          this.task_red = Number(this.task_red) + 1
          this.task_qty_red = Number(this.task_qty_red) + Number(result[i1]["order_qty"])

        }

      }


    },



    openPage(){
      this.$router.push({ name: "ListTask"})
    }

  },








}
</script>

<style scoped>

</style>
