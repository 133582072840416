<template>

  <v-layout

  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >


        <v-text-field
            v-model="incoming_company"
            sm="12"
            md="4"
            label="Firma / Kunde"
            required
            outlined
            disabled
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>


        <v-btn @click="SelectCustomer = true"
               style="margin-bottom: 40px;"
        >
          <v-icon
              style="padding-right: 5px;"
          >mdi-account-box</v-icon>
          Kunden auswählen</v-btn>


        <v-select
            v-model="type"
            label="Typ"
            outlined
            :items="['', 'ENTSORGUNG', 'TRANSPORT', 'VERMITTLUNG']"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

            @change="reloadComponents(type)"
        ></v-select>

        <CustomSelect
            v-if="type == 'ENTSORGUNG'"
            v-model="disposer"
            v-bind:table="'disposer'"
            v-bind:field_text="'company'"
            v-bind:field_value="'id'"
            v-bind:label="'Entsorger'"
            v-bind:selected="disposer_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></CustomSelect>

        <CustomSelect
            v-if="type == 'TRANSPORT'"
            v-model="transport"
            v-bind:table="'carrier'"
            v-bind:field_text="'company'"
            v-bind:field_value="'id'"
            v-bind:label="'Spedition'"
            v-bind:selected="transport_id"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></CustomSelect>


        <v-text-field
            v-if="type != ''"
            v-model="outgoing_company"
            sm="12"
            md="4"
            label="Firma (Vermittelt an)"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>




        <v-dialog
            ref="dialog1"
            v-model="modal1"
            :return-value.sync="date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                style="
                  margin: 0 auto;
                  width: 350px;"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              color="pink"
              scrollable
              locale="de-de"
              :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="pink"
                @click="modal1 = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                text
                color="pink"
                @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>



        <v-select
            v-model="weight_type"
            label="Einheit"
            outlined
            :items="['TONNE', 'STÜCK']"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-select>


        <v-text-field
            v-model="weight"
            sm="12"
            md="4"
            label="Gewicht/Stück"
            required
            outlined
            type="number"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="amount_buy"
            sm="12"
            md="4"
            label="EK-Preis"
            required
            outlined
            background-color="blue"
            type="number"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="amount_sell"
            sm="12"
            md="4"
            label="VK-Preis"
            required
            outlined
            background-color="green"
            type="number"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-text-field
            v-model="wasteno"
            sm="12"
            md="4"
            label="Abfallschlüssel"
            required
            outlined
            type="text"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>



        <v-text-field
            v-model="document_no"
            sm="12"
            md="4"
            label="Referenz Nr."
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-text-field>


        <v-textarea
            v-model="note"
            outlined
            label="Bemerkungen"
            value=""
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-textarea>



        <v-switch
            v-model="invoice_incoming"
            label="Eingangsrechnung"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>


        <v-text-field
            v-model="invoice_incoming_no"
            sm="12"
            md="4"
            label="Rechnungsnummer"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>




        <v-switch
            v-model="invoice_outgoing"
            label="Ausgangsrechnung"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"

        ></v-switch>


        <v-text-field
            v-model="invoice_outgoing_no"
            sm="12"
            md="4"
            label="Rechnungsnummer"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
      display: table;
      margin: 0 auto;
      width: 350px;"
        ></v-text-field>





        <v-col
            sm="12"
            md="12"
            class="text-right"
        >

          <v-btn
              color="red"
              elevation="2"
              outlined
              style="margin-right: 40px"
              @click="btnCancel"
          >Abbrechen</v-btn>


          <v-btn
              color="green"
              elevation="2"
              outlined
              @click="saveData"
          >Speichern</v-btn>


        </v-col>

      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


    <SelectCustomer
        v-if="SelectCustomer"
        @SelectedCustomer="loadCustomer"
        @SelectCustomerReset="SelectCustomerReset"

    ></SelectCustomer>


  </v-layout>

</template>




<script>

import CustomSelect from "@/components/CustomSelect.vue";
import SelectCustomer from "@/components/SelectCustomer.vue";

export default {
  name: "FormExchange",
  components: {SelectCustomer, CustomSelect},

  data () {
    return {

      title: 'Vermittlung',
      mainview: 'ListExchange',
      table: 'exchange',

      modal1: '',
      modal2: '',
      modal3: '',
      modal4: '',

      checkbox: false, // Lagerbuchung
      checkbox_disable: false, // Lagerbuchung

      // date: this.$moment(new Date()).format("DD.MM.YYYY").toISOString(),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'pink',
      timeout: 2500,


      id: '',

      type: '',

      disposer: '',
      disposer_id: '',
      disposer_company: '',

      transport: '',
      transport_id: '',
      transport_company: '',

      customer: '',
      customer_id: '',
      customer_company: '',

      incoming_company: '',
      outgoing_company: '',


      document_no: '',
      article: '',
      article_id: '',
      article_name: '',
      article_weight: '',

      invoice_incoming: false,
      invoice_incoming_no: '',

      invoice_outgoing: false,
      invoice_outgoing_no: '',


      amount_buy: '',
      amount_sell: '',
      amount_transport: '',

      stock_id: '',

      wasteno: '',

      company: '',
      price: '',
      price_bgcolor: 'red',
      price_positive: false,
      weight: '',
      weight_type: 'TONNE',

      price_per_ton: 0,

      invoice: false,
      invoice_date: '',
      invoice_payment_deadline: '',
      invoice_payment_date: '',
      note: '',

      SelectCustomer: false,

    }
  },

  created() {

    // console.log("TIME")
    // console.log(this.$moment(new Date()).format("DD.MM.YYYY"))


    this.date = this.$moment(Date.now()).format("YYYY-MM-DD")


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },

  watch:{


    disposer: function () {

      if(this.disposer == null){
        return
      }

      if(this.disposer.trim() == ";"){
        this.disposer_id = ""
        this.disposer_company = ""

       //  this.outgoing_company = this.disposer_company
      }

      if(this.disposer.trim() != ";" && this.disposer.includes(";") == true){
        this.disposer_id = this.disposer.split(";")[1]
        this.disposer_company = this.disposer.split(";")[0]

        this.outgoing_company = this.disposer_company

      //  this.loadDisposerPrice()
      }

    },

    transport: function () {

      if(this.transport == null){
        return
      }

      if(this.transport.trim() == ";"){
        this.transport_id = ""
        this.transport_company = ""

      //   this.outgoing_company = this.transport_company
      }

      if(this.transport.trim() != ";" && this.transport.includes(";") == true){
        this.transport_id = this.transport.split(";")[1]
        this.transport_company = this.transport.split(";")[0]

        this.outgoing_company = this.transport_company
        //  this.loadDisposerPrice()
      }

    },


  },


  methods:{

    SelectCustomerReset(){
      this.SelectCustomer = false
    },

    async loadCustomer(id){
      console.log("loadCustomer")

      this.customer_id = id

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.customer_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.incoming_company = result[0]["company"]
      this.customer_id = result[0]["id"]


    },




    btnCancel(){

      this.$router.push({ name: this.mainview })

    },



    async reloadComponents(newtype){

      this.type = ""

      this.$nextTick(() => {
        this.type = newtype
      });



    },




    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      console.log("loadData")
      console.log(result)


      if (result[0]["date"] == null){
        this.date = this.$moment(Date.now()).format("YYYY-MM-DD")
      }else{
        this.date = this.$moment(result[0]["date"], "DD.MM.YYYY").format("YYYY-MM-DD")
      }


      this.type = result[0]["type"]

      this.incoming_company = result[0]["incoming_company"]
      this.outgoing_company = result[0]["outgoing_company"]




      this.customer_id = result[0]["customer_id"]

      this.wasteno = result[0]["wasteno"]

      this.document_no = result[0]["document_no"]
      this.weight = result[0]["weight"]
      this.weight_type = result[0]["weight_type"]

      this.amount_buy = result[0]["amount_buy"]
      this.amount_sell = result[0]["amount_sell"]
      this.amount_transport = result[0]["amount_transport"]


      this.invoice_incoming_no = result[0]["invoice_incoming_no"]
      this.invoice_outgoing_no = result[0]["invoice_outgoing_no"]

      this.note = result[0]["note"]


      if(result[0]["invoice_incoming"] == "0"){
        this.invoice_incoming = false
      }else{
        this.invoice_incoming = true
      }

      if(result[0]["invoice_outgoing"] == "0"){
        this.invoice_outgoing = false
      }else{
        this.invoice_outgoing = true
      }

    },

    async saveData(){


      if(this.incoming_company === ""){
        this.snackbar_title = "Bitte Kunden auswählen!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }

      if(this.outgoing_company === ""){
        this.snackbar_title = "Bitte Firma (Vermittelt an) eingeben!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }



      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Parameter
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      var invoice_incoming
      var invoice_outgoing


      if(this.invoice_incoming == "" || this.invoice_incoming == "0"){
        invoice_incoming = 0
      }else{
        invoice_incoming = 1
      }

      if(this.invoice_outgoing == "" || this.invoice_outgoing == "0"){
        invoice_outgoing = 0
      }else{
        invoice_outgoing = 1
      }


      // Data
      var data = {

        incoming_company: this.incoming_company,
        outgoing_company: this.outgoing_company,

        customer_id: this.customer_id,

        weight: this.weight.toString().replace(",","."),
        weight_type: this.weight_type,
        amount_buy: this.amount_buy,
        amount_sell: this.amount_sell,
        amount_transport: this.amount_transport,

        date: this.computedDate,
        date_timestamp: this.$moment(this.computedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),

        wasteno: this.wasteno,
        document_no: this.document_no,

        invoice_incoming: invoice_incoming,
        invoice_outgoing: invoice_outgoing,

        invoice_incoming_no: this.invoice_incoming_no,
        invoice_outgoing_no: this.invoice_outgoing_no,


        type: this.type,

        note: this.note,

      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true


      if(statement == "insert"){
        this.id = result["value"]
      }


    },



  },


  computed: {
    computedDate () {
      // return this.$moment(this.date).format('dd.mm.YYYY')
      return this.$moment(this.date).format("DD.MM.YYYY")
    },

  },


}
</script>



<style scoped>

</style>
