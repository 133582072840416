<template>
  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:default_limit="'300'"
        v-bind:default_sort="'planed_date_timestamp'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:extension="'(pickup_date IS NULL OR pickup_date=\'\')'"

    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListTour",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: 'FormTour',
      table: 'tour',
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Nummer', align: 'start', sortable: true, value: 'id' },
        { text: 'Fahrer', value: 'driver_name' },
        { text: 'Fahrzeug', value: 'car_description' },
        { text: 'Anzahl', value: 'qty' },
        { text: 'Stops', value: 'stops' },
        { text: 'Sammelstelle', value: 'parking_address' },
        { text: 'Fahrtzeit', value: 'calc_driving_time' },
        { text: 'Wegstrecke in km', value: 'calc_driving_distance' },
        { text: 'Bemerkungen', value: 'note' },
        { text: 'Geplantes Datum', value: 'planed_date' },
        { text: 'Mitarbeiter', value: 'worker' },
        { text: '', value: 'edit' },
      ]
    },
  },
  methods: {

  },

}
</script>

<style scoped>

</style>
