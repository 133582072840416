<template>
<div>

  <List
      v-bind:headers="headers"
      v-bind:formview="formview"
      v-bind:table="table"
      v-bind:addbutton="false"
      v-bind:default_limit="'500'"
      v-bind:default_sort="'id'"
      v-bind:default_sort_typ="'ASC'"
      v-bind:extension="''"
  ></List>

</div>
</template>

<script>
import List from "@/components/ListDefault";

export default {
  name: "ListRepeatTask",

  components: {
    List,
  },

  created() {

  },

  data () {
    return {
      search: '',
      formview: 'FormRepeatTask',
      table: 'task_repeat',
      filter:{
        selector: 'prio',
        value: '99',
      }

    }
  },


  computed: {
    headers () {
      return [
        { text: 'Letzte Abholung', value: 'repeat_last_task_pickup_date' },
        { text: 'Letzter Auftrag Nr.', value: 'repeat_last_task_id' },
        { text: 'Letzter Auftrag Datum', value: 'repeat_last_task_date' },
        { text: 'Intervall in Tagen', value: 'repeat_interval' },
        { text: 'Kundengruppe', value: 'group_main_text' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Strasse',  value: 'street' },
        { text: 'PLZ',  value: 'zipcode' },
        { text: 'Ort',  value: 'city' },
        { text: 'Anzahl', value: 'order_qty' },
        { text: 'Artikel', value: 'article_name' },
        { text: '', value: 'edit' },
      ]
    },
  },



}
</script>

<style scoped>

</style>
