<template>
  <div
  >

    <List
        v-if="renderComponent"
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:default_limit="'300'"
        v-bind:default_sort="'id'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:extension="'(pickup_date IS NULL OR pickup_date=\'\')'"
        v-bind:export_csv="true"
    ></List>


    <v-dialog
        v-model="loading_dialog"

        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Bitte warten...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListTask",

  components: {
    List,
  },

  data () {
    return {
      search: '',
      formview: 'FormTask',
      table: 'task',

      renderComponent: true,
      loading_dialog: false,

      data: [],

    }
  },

  async created() {

    this.loading_dialog = true

    await this.checkPrio()

    // Remove my-component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // Add the component back in
      this.renderComponent = true;
    });

    this.loading_dialog = false


    // Nicht Dauerhaft !!!

    // await this.bugFixAddress()

  },


  computed: {
    headers () {
      return [
        { text: 'Prio', value: 'prio' },
        { text: 'Auftragsdatum', value: 'date_timestamp' },
        { text: 'Ausführungsfrist', value: 'execution_date_timestamp' },
        { text: 'Reklamationsdatum', value: 'complaint_date_timestamp' },
        { text: 'Auftragsnummer', value: 'document_no' },
        { text: 'Kundengruppe', value: 'group_main_text' },
        { text: 'Untergruppe', value: 'group_type_text' },
        { text: 'Kunde', align: 'start', sortable: true, value: 'company' },
        { text: 'Strasse', value: 'street' },
        { text: 'Ort', value: 'city' },
        { text: 'PLZ', value: 'zipcode' },
        { text: 'Bemerkung', value: 'note' },
        { text: 'Anzahl', value: 'order_qty' },
        { text: 'Tour', value: 'tour_id' },
        { text: '', value: 'edit' },
      ]
    },
  },


  methods: {


    async bugFixAddress(){

      // Parameter
      var param1 = {
        table: "task",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log(result1)


      for (var i1 = 0; i1 < result1.length; i1++) {

        if(await this.checkAddress(result1[i1]["customer_id"], result1[i1]["street"]) == false){
          // Bugfix Address
          await this.updateAddress(result1[i1]["id"], result1[i1]["customer_id"])
        }


      }




    },

    async checkAddress(customer_id, street){

      // Parameter
      var param1 = {
        table: "customer",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE id='" + customer_id + "' AND street='" + street +  "'",
      }


      // Data
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)

      if(result1.length == 0){
        return false
      }else{
        return true
      }

    },

    async updateAddress(task_id, customer_id){



      // Parameter
      var param2 = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: customer_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data2 = {
      }

      console.log(data2)

      var result2 = await this.$func.queryAPI(param2, data2)


if (result2.length == 0){
  console.log("FEHLER")
  console.log(result2)

  return
}



      // Parameter
      var param1 = {
        table: "task",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: task_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data1 = {

        street: result2[0]["street"],
        zipcode: result2[0]["zipcode"],
        city: result2[0]["city"],

      }

      console.log(data1)

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log(result1)


    },




    async checkPrio(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE (pickup_date IS NULL OR pickup_date='')",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.data = result

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      var firstDate = new Date(2008, 1, 12);
      var secondDate = new Date(2008, 1, 22);

      // const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      var prio
      var prio_days


      for (var i1 = 0; i1 < this.data.length; i1++) {

        console.log("execution_date: " + this.data[i1]["execution_date"])

        firstDate = new Date(this.$moment(this.data[i1]["execution_date"], "DD.MM.YYYY").format("YYYY-MM-DD"));
        secondDate = new Date(Date.now());


        prio_days = Math.round(Math.abs((firstDate - secondDate) / oneDay))
        prio = "1"

        if(secondDate >= firstDate){

          prio = "3"

        }else{

          if (prio_days < 7) {
            prio = "1"
          }

          if (prio_days < 6) {
            prio = "2"
          }

          if (prio_days < 1) {
            prio = "3"
          }

        }



       // console.log(Math.abs((firstDate - secondDate) / oneDay))
       // console.log("prio_days: " + prio_days)
       // console.log("prio: " + prio)


        this.setPrio(this.data[i1]["id"], prio, prio_days)

      }


    },

    async setPrio(id, prio, prio_days){

        // Parameter
        var param = {
          table: this.table,
          statement: "update",
          type: "",
          where: "true",
          selector: "id",
          value: id,
          short: "true", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Data
        var data = {
          prio: prio,
          prio_days: prio_days,

        }

        var result = await this.$func.queryAPI(param, data)

        console.log(result)


    }



  },

}
</script>

<style scoped>

</style>
