<template>
  <v-card
      class="mx-auto"
      width="450"
      style="margin: 10px;"
  >
    <v-card-text>
      <div>{{title}}</div>
      <p
          v-if="color == 'red'"
          class="text-h4" style="color: red" >
        <b>{{numberWithCommas(total_sum)}}</b> EUR
      </p>
      <p
          v-if="color == 'green'"
          class="text-h4" style="color: green" >
        <b>{{numberWithCommas(total_sum)}}</b> EUR
      </p>

      <div class="text--primary">

      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          text
          color="pink accent-3"
          @click="openPage"
      >
        Details
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "CardSumToPay",

  data () {
    return {

      total_sum: 0,
      last_timestamp: '',

    }
  },

  async created() {

    this.total_sum = await this.calcTotalSum()

  },



  methods:{

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    async calcTotalSum(){

      console.log("calcTotalWeight")

      var extension

      if(this.extension == ""){
        extension = "SELECT SUM(" + this.fieldname + ") FROM " + this.table + " WHERE invoice <> '1' "
      }else{
        extension = this.extension
      }


      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: extension,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


     if(result.length == 0){
       return Number(0).toFixed(2)
     }

     if(result[0]["SUM(" + this.fieldname + ")"] == null){
       return Number(0).toFixed(2)
     }

     //  console.log(result[0]["SUM(" + this.fieldname + ")"])
     // console.log(Number(result[0]["SUM(" + this.fieldname + ")"]).toFixed(2))

      return Number(result[0]["SUM(" + this.fieldname + ")"]).toFixed(2)

    },



    openPage(){
      this.$router.push({ name: this.page})
    }

  },


  props: {

    title: String,

    table: String,

    fieldname: String,


    page: String,



    color: String,

    extension: {
      type: String,
      default: ''
    },



  }





}
</script>

<style scoped>

</style>
