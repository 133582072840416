<template>
  <v-container
      fluid>

    <div>

<div
    v-if="export_csv"
>
      <vue-json-to-csv
          csv-title="Export"
          :json-data="data">
        <v-btn
            fab
            style="
    position: absolute;
    top: 5px;
    right: 5px;
"
        ><v-icon>mdi-microsoft-excel</v-icon></v-btn>
      </vue-json-to-csv>
</div>

      <!-- :custom-sort="customSort" -->

      <v-data-table

          :height="height"
          :headers="headers"
          :items="data"
          :key="key"
          item-key="id"
          :items-per-page="100"
          class="elevation-7"
          :search="search"
          :custom-filter="filterOnlyCapsText"

          :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-options': [50, 100, 200, -1],
           'items-per-page-text':'pro Seite'
      }"


      >


        <template v-slot:item.date="{item}">
          <p v-if="item.worker_add != 'AUTO'" style="">{{ item.date }}</p>
          <p v-if="item.worker_add == 'AUTO'" style="background-color: #e91e63">{{ item.date }}</p>
        </template>

        <template v-slot:item.note="{item}">

          <p class="tooltip">{{ item.note.toString().substring(0, 15) }}
            <span class="tooltiptext">{{ item.note.toString() }}</span>
          </p>

        </template>



        <template v-slot:item.parking_address="{item}">

          <div v-if="item.parking_address != null">
          <p class="tooltip">{{ item.parking_address.toString().substring(0,15) }}
            <span class="tooltiptext">{{ item.parking_address.toString() }}</span>
          </p>
          </div>

        </template>



        <template v-slot:item.date_timestamp="{item}">

          <div v-if='$moment(item.date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") != "Invalid date"'>
            <div v-if="item.worker_add != 'AUTO'" style="">{{ $moment(item.date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") }}</div>
            <div v-if="item.worker_add == 'AUTO'" style="background-color: #e91e63"> {{ $moment(item.date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") }}</div>
          </div>
          <div v-else>
            <div v-if="item.worker_add != 'AUTO'" style="">{{ item.date }}</div>
            <div v-if="item.worker_add == 'AUTO'" style="background-color: #e91e63">{{ item.date }}</div>
          </div>

        </template>


        <template v-slot:item.weight_qty="{item}">
          <p>{{item.weight}} / {{item.weight_type}}</p>
        </template>


        <template v-slot:item.execution_date_timestamp="{item}">
          <div v-if='$moment(item.execution_date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") != "Invalid date"'>
            {{ $moment(item.execution_date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") }}
          </div>
          <div v-else>{{ item.execution_date }}</div>
        </template>


        <template v-slot:item.pickup_timestamp="{item}">

          <div v-if='$moment(item.pickup_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") != "Invalid date"'>
            {{ $moment(item.pickup_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") }}
          </div>
          <div v-else>{{ item.pickup_date }}</div>

        </template>


        <template v-slot:item.complaint_date_timestamp="{item}">
          <div v-if='$moment(item.complaint_date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") == "Invalid date" || $moment(item.complaint_date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") == "0000-00-00"'>

          </div>
          <div v-else>{{ $moment(item.complaint_date_timestamp, "YYYY-MM-DD").format("DD.MM.YYYY") }}</div>
        </template>



        <template v-slot:item.weight="{item}">

          <p style="color: green" v-if="Number(item.weight) >= 0">{{ item.weight }}</p>
          <p style="color: red" v-else>{{ item.weight }}</p>

        </template>

        <template v-slot:item.onlysprinter="{item}">

          <p style="color: green" v-if="item.onlysprinter == 'true'">
             <v-icon color="red" style="margin-top: 5px;">mdi-car-estate</v-icon>
          </p>
          <p style="color: red" v-else></p>

        </template>


        <template v-slot:item.invoice_forward="{item}">

          <p style="color: green" v-if="item.invoice_forward == 1 && item.invoice_forward_written != ''"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-if="item.invoice_forward == 1 && item.invoice_forward_written == ''"><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>




        <template v-slot:item.invoice_incoming="{item}">

          <p style="color: green" v-if="item.invoice_incoming == 1"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>


        <template v-slot:item.invoice_outgoing="{item}">

          <p style="color: green" v-if="item.invoice_outgoing == 1"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>





        <template v-slot:item.invoice="{item}">

          <p style="color: green" v-if="item.invoice == 1"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>



        <template v-slot:item.invoice_payment_date="{item}">

          <p style="color: green" v-if="item.invoice_payment_date != ''"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>



        <template v-slot:item.typ="{item}">

          <p style="color: green" v-if="item.typ == 'buy'">Eingang</p>
          <p style="color: red" v-if="item.typ == 'sell'">Verkauf</p>
          <p style="color: red" v-if="item.typ == 'disposal'">Verschrottung</p>

        </template>



        <template v-slot:item.prio="{item}">

          <p style="color: green" v-if="item.prio == 1">&blk34;</p>
          <p style="color: orange" v-if="item.prio == 2">&blk34;</p>
          <p style="color: red" v-if="item.prio == 3">&blk34;</p>

        </template>



        <template v-slot:item.delete="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id, item.company)">mdi-delete-outline</v-icon>

            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>



        <template v-slot:item.edit="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
          <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id, item.company)">mdi-delete-outline</v-icon>

          <v-icon style="padding: 3px;" @click="editEntry(item.id)">mdi-pencil-outline</v-icon> <!--{{item.calories}}-->
            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>





        <template v-slot:item.price="{item}">

          <p style="color: green" v-if="Number(item.price) >= 0">{{ item.price }}</p>
          <p style="color: red" v-else>{{ item.price }}</p>

        </template>







        <template v-slot:item.select ="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon color="pink" @click="selectEntry(item.id)">mdi-checkbox-marked-outline</v-icon>
            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>






        <template v-slot:item.select_add="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
               <v-icon color="pink" style="padding: 3px;" @click="add2Tour(item.id)">mdi-plus-box</v-icon> <!--{{item.calories}}-->
            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>




        <template v-slot:item.select_delete="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >

              <v-icon color="gray" style="padding: 3px;" @click="posUp(item.id)">mdi-arrow-up-drop-circle</v-icon> <!--{{item.calories}}-->
              <v-icon color="gray" style="padding: 3px;" @click="posDown(item.id)">mdi-arrow-down-drop-circle</v-icon> <!--{{item.calories}}-->


              <v-icon color="#383083" style="padding: 3px;" @click="delete2Tour(item.id)">mdi-minus-box</v-icon> <!--{{item.calories}}-->


            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>








        <template
            v-slot:top>
          <v-text-field
              v-model="search"
              label="Filter"
              clearable
              class="mx-4"
          ></v-text-field>


          <div
              v-if="filter_clearing == true"
              style="margin-left: 30px;"
          >
          <tr>
            <td></td>
            <td colspan="6">

              <v-switch
                  v-model="switch_clearing"
                  label="Nicht abgerechnete Aufträge anzeigen"
              ></v-switch>

            </td>
            <td></td>
          </tr>
          </div>
        </template>


      </v-data-table>

    </div>

    <!--
    LÖSCHEN DIALOG
    -->

    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >

<!--
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Open Dialog
          </v-btn>
        </template>
-->



        <v-card>
          <v-card-title>
            <span class="text-h5">Wirklich Löschen</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                      Wollen Sie den Datensatz&nbsp;<b>{{dialog_text}}</b>&nbsp;wirklich löschen?
              </v-row>

              <v-text-field
                  v-model="dialog_password"
              type="password"
              placeholder="Passwort"
              ></v-text-field>

            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn

                text
                @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="red"
                text
                @click="deleteEntry"
            >
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>





    <v-fab-transition>
      <v-btn
          v-if='addbutton == true'
          color="pink"
          dark
          fixed
          bottom
          right
          fab
          @click="openAddView"

      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>




    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>



  </v-container>
</template>

<script>
import Vue from "vue";
import VueJsonToCsv from 'vue-json-to-csv'


export default {
  name: "ListDefault",

  components:{
    VueJsonToCsv,
  },

  data () {
    return {

      key: 0,

      data: [],
      dialog: false,

      search: '',
      sort: '',
      limit: '',

      dialog_id: '',
      dialog_text: '',
      dialog_password: '',

      switch_clearing: false,

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,
    }
  },


  created() {

    this.filterLoadData(this.search)

  },

  watch: {

    search(){
      console.log("SEARCH: " + this.search)

      this.filterLoadData(this.search)

    },

    switch_clearing(){

      console.log("Toogle switch")

      if(this.switch_clearing == true){
        console.log("true")
        this.search = ""
        this.showNotClearedEntrys()
      }else{
        console.log("false")
        this.filterLoadData(this.search)
      }


    }



  },


  methods: {


    async selectEntry(id){
      console.log(id)
      this.$emit('add', id)
    },


    async showNotClearedEntrys(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE (invoice IS NULL OR invoice='') ORDER BY date_timestamp ASC",
      }

      if(this.table == "task"){
        param.extension = "WHERE (invoice IS NULL OR invoice='') AND (pickup_date IS NOT NULL) ORDER BY pickup_timestamp ASC"
      }



      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.data = result


    },

    async filterLoadData(search){
    console.log("filterLoadData")

    var extension = this.extension


      var sort = " "
      if(this.default_sort != "" || this.sort != ""){
        if(this.sort != ""){
          sort = "ORDER BY `" + this.sort + "` " + this.default_sort_typ + " "
        }else{

            if(this.table == "stock"){
                sort = "ORDER BY `" + this.default_sort + "` " + this.default_sort_typ + ", time DESC " // Time
            }else{
                sort = "ORDER BY `" + this.default_sort + "` " + this.default_sort_typ + " "
            }

        }
      }

      var limit = " "
      if(this.default_limit != ""){
        limit = "LIMIT " + this.default_limit
      }


      if(search != ""){

        var query = ""

        let i = 0;
        while (this.headers[i]) {

          if(this.headers[i].value != "edit"){

            if(this.headers[i].value != "edit" && this.headers[i].value != "select" && this.headers[i].value != "select_add" && this.headers[i].value != "select_delete"  && this.headers[i].value != "delete" && this.headers[i].value != "time" && this.headers[i].value != "typ"){
              query = query + this.headers[i].value + " LIKE '%" + search + "%' OR "
            }


          }

          i++;
        }


        if(this.table == "stock"){
          query = query + "date" + " LIKE '%" + search + "%' OR "
        }

        if(this.table == "task"){
          query = query + "pickup_date" + " LIKE '%" + search + "%' OR "
        }

        query = query.substring(0, query.length - 4)

        if(extension == ""){
          extension = "WHERE (" + query + ") " + sort + limit
        }else{
          extension = "WHERE " + extension + " AND (" + query + ") " + sort + limit
        }

      }else{


        if (extension == ""){
          extension = sort + limit
        }else{
          extension = "WHERE " + extension + " " + sort + limit
        }

      }


      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

        // console.log(param)
        // console.log(extension)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.data = result

      // this.key++


    },

    filterOnlyCapsText (value, search) {

      console.log("filter")

      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
    },


    customSort(){

      console.log("TEST")

    },


    openAddView(){
      this.$router.push({ path: this.formview })
    },

    async deleteEntry(){

      if(this.dialog_password != localStorage.password_delete){

        this.snackbar_title = "Password Falsch!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }




      // Einstellungen
      var param = {
        table: this.table,
        statement: "delete",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.dialog_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.dialog = false


      if(this.table == "tour"){
        // Lösche Tour zuordnung
        await this.deleteMatchTour2Task(this.dialog_id)
      }


      this.filterLoadData(this.search)

      this.$emit('reload', '')


    },

    async deleteMatchTour2Task(id){

      // Parameter
      var param = {
        table: "task",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "UPDATE task SET tour_id='' WHERE tour_id='" + id + "' AND pickup_date IS NULL",
      }

      // Data
      var data = {
      }

      await this.$func.queryAPI(param, data)
      // var result = await this.$func.queryAPI(param, data)
      // console.log(result)

    },





    async posUp(id){
      console.log("posUp")


      var currentPosition = await this.checkCurrentPosition(id)
      var numofposition = await this.checkNumofPosition()

      console.log("Aktuelle Position: " + currentPosition)
      console.log("ALLE Position: " + numofposition)

      if(currentPosition == 1){
        console.log("Bricht ab")
        return
      }

      await this.changePosition(Number(currentPosition) - 1, Number(currentPosition))
      await this.changePositionByID(id, Number(currentPosition) - 1)


      await this.filterLoadData(this.search)

      this.$emit('reload', '')

    },

    async posDown(id){
      console.log("posDown")

      var currentPosition = await this.checkCurrentPosition(id)
      var numofposition = await this.checkNumofPosition()

      console.log("Aktuelle Position: " + currentPosition)
      console.log("ALLE Position: " + numofposition)

      if(currentPosition == numofposition){
        console.log("Bricht ab")
        return
      }

      await this.changePosition(Number(currentPosition) + 1, Number(currentPosition))
      await this.changePositionByID(id, Number(currentPosition) + 1)

      await  this.filterLoadData(this.search)

      this.$emit('reload', '')

    },




    async resortPosition(){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "list",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "ORDER BY `tour_position` ASC",
      }

      // Data
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      if(result.length == 0){
        return
      }

      for (var i1 = 0; i1 < result.length; i1++) {
        await this.changePositionByID(result[i1]["id"], i1 + 1)
      }


      console.log(result)

    },

    async changePosition(pos_old, pos_new){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "AND tour_position='" + pos_old + "'",
      }

      // Data
      var data = {
        tour_position: pos_new,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },

    async changePositionByID(id, pos_new){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {
        tour_position: pos_new,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },

  // Liefert Anzahl der Positionen
   async checkNumofPosition(){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "detail",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

       // Data
       var data = {
       }

       var result = await this.$func.queryAPI(param, data)
       console.log(result)

       return result.length ?? 0

    },

   async checkCurrentPosition(id){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      return result[0]["tour_position"]

    },


   async add2Tour(id){
      // temp_tour_id

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        temp_tour_id: this.temp_tour_id,
        tour_position: Number(await this.checkNumofPosition()) + 1
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      await this.filterLoadData(this.search)

      // Löst ein Reload aus ggf. andere List
      this.$emit('reload', '')


    },

   async delete2Tour(id){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        temp_tour_id: "",
        tour_id: "",
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      await this.resortPosition()

      await this.filterLoadData(this.search)

      // Löst ein Reload aus ggf. andere List
      this.$emit('reload', '')


    },

    showDeleteDialog(id, text){
      this.dialog_id = id
      this.dialog_text = text
      this.dialog = true
    },

    editEntry(id){

      this.$router.push({ path: this.formview + "/" + id, params:{id: id} })

    },

    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: this.extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(this.extension)



      this.data = result

    },


  },

  props: {

    headers: Array,
    formview: String,
    table: String,
    extension: String,



    height: {
      default: "",
      type: String
    },


    default_limit: {
      default: "1000",
      type: String
    },

    default_sort: {
      default: "",
      type: String
    },

    default_sort_typ: {
      default: "DESC",
      type: String
    },

    filter_clearing: {
      default: false,
      type: Boolean
    },

    export_csv: {
      default: false,
      type: Boolean
    },


    addbutton: {
      default: true,
      type: Boolean
    },


    temp_tour_id: String,

  }



}
</script>

<style scoped>

</style>
