import { render, staticRenderFns } from "./DialogMap.vue?vue&type=template&id=4e879092&scoped=true&"
import script from "./DialogMap.vue?vue&type=script&lang=js&"
export * from "./DialogMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e879092",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
