<template>

  <div>

  <v-layout

      style="float: left"
  >
    <v-container

    >
<!--
      <button
      @click="btnCustomerID()"
      >CUSTOMER ID NACHTRAGEN</button>

      -->

    <CardPrioInfo></CardPrioInfo>


      <CardSumToPayPie>

      </CardSumToPayPie>


  </v-container>



    <v-container

    >
    <CardSumToPay
    v-bind:table="'disposal'"
    v-bind:title="'SCHROTTKOSTEN OFFEN'"
    v-bind:page="'ListDisposal'"
    v-bind:fieldname="'price'"
    v-bind:extension="'SELECT SUM(price) FROM disposal WHERE invoice <> \'1\' and price_positive = \'false\''"
    v-bind:color="'red'"
    ></CardSumToPay>

      <CardSumToPay
          v-bind:table="'disposal'"
          v-bind:title="'SCHROTTKOSTEN GUTSCHRIFT'"
          v-bind:page="'ListDisposal'"
          v-bind:fieldname="'price'"
          v-bind:extension="'SELECT SUM(price) FROM disposal WHERE invoice <> \'1\' and price_positive = \'true\''"
          v-bind:color="'green'"
      ></CardSumToPay>

    <CardSumToPay
        v-bind:table="'transport'"
        v-bind:title="'TRANSPORTKOSTEN OFFEN'"
        v-bind:page="'ListTransport'"
        v-bind:fieldname="'price'"
        v-bind:color="'red'"
    ></CardSumToPay>

    <CardSumToPay
        v-bind:table="'task'"
        v-bind:title="'AUFTRÄGE OFFEN (GESCHÄTZT)'"
        v-bind:page="'ListTask'"
        v-bind:fieldname="'price_total'"
        v-bind:extension="'SELECT SUM(price_total) FROM task WHERE price_total<>\'NaN\' AND pickup_date IS NULL'"
        v-bind:color="'green'"
    ></CardSumToPay>

    <CardSumToPay
        v-bind:table="'task'"
        v-bind:title="'ERLEDIGTE AUFTRÄGE (NICHT ABGERECHNET)'"
        v-bind:page="'ListClearing'"
        v-bind:fieldname="'price_total'"
        v-bind:extension="'SELECT SUM(price_total) FROM task WHERE price_total<>\'NaN\' AND pickup_date IS NOT NULL AND (invoice <> \'1\' OR invoice IS NULL)'"
        v-bind:color="'green'"
    ></CardSumToPay>





    </v-container>

    <v-container>
      <CardPastTours></CardPastTours>
    </v-container>







  </v-layout>

  <v-container
      style="width: 100%"
      class="float-left"
  >


    <CardCurrentPickup></CardCurrentPickup>




  </v-container>




  </div>


</template>

<script>


import CardCurrentStock from '../components/CardCurrentStock.vue'
import CardSumToPay from '../components/CardSumToPay'
import CardPrioInfo from '../components/CardPrioInfo'
import CardCurrentPickup from '../components/CardCurrentPickup.vue'
import CardPastTours from '../components/CardPastTours.vue'

import CardSumToPayPie from '../components/CardSumToPayPie.vue'


export default {
  name: "Dashboard",


  components: {
    CardCurrentStock,
    CardSumToPay,
    CardPrioInfo,
    CardCurrentPickup,
    CardPastTours,
    CardSumToPayPie,
    // CardCurrentPickup,
  },


  data () {
    return {

      task_items_old: [],

    }

  },



  async created() {

    if(localStorage.typ == "customer"){
      this.$router.push({ name: 'ListTaskCustomer' })
    }

    await this.getAverageWeight()
    await this.getCheckQuality()


    // Prüft ob Task bereits ausgeführt sind oder Aufträge vorhanden sind
    await this.checkRepeatTask()
    // Legt den Task an
    await this.checkRepeatTaskExecution()

  },


  methods: {


    async btnCustomerID(){


      // Einstellungen
      var param = {
        table: "task_items",
        statement: "custom",
        type: "list",
        where: "true",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM task_items WHERE customer_id IS NULL OR customer_id = ''",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
       console.log(result)


      for (var i1 = 0; i1 < result.length; i1++) {
        await this.addCustomerID(result[i1]["temp_task_id"])
      }

    },


    async addCustomerID(temp_task_id){

      var param1 = {
        table: "task",
        statement: "custom",
        type: "",
        where: "true",
        selector: "temp_task_id",
        value: temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT customer_id FROM task WHERE temp_task_id='" + temp_task_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)

      console.log(result1)

      if(result1.length == 0){
        return
      }

      if(result1.length > 1){
        return
      }


      console.log("customer_id: " + result1[0]["customer_id"])


      // Einstellungen
      var param2 = {
        table: "task_items",
        statement: "update",
        type: "",
        where: "true",
        selector: "temp_task_id",
        value: temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
        customer_id: result1[0]["customer_id"],
      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log(result2)

    },







    async getAverageWeight() {

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "list",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      // console.log(result)

      // console.log("average_weight")
      // console.log(result[0]["wert"])

      localStorage.average_weight = result[0]["wert"]

    },

    async getCheckQuality() {

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "list",
        where: "true",
        selector: "schluessel",
        value: "check_quality",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        localStorage.check_quality = 2
      }else{
        localStorage.check_quality = result[0]["wert"]
      }

      // console.log("average_weight")
      // console.log(result[0]["wert"])



    },

    async checkRepeatTask() {
      console.log("checkRepeatTask")

      // Einstellungen
      var param = {
        table: "task_repeat",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE repeat_last_task_pickup_date IS NULL OR repeat_last_task_pickup_date=''",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      if (result.length == 0) {
        return
      }


      for (var i1 = 0; i1 < result.length; i1++) {
        await this.checkLastPickupDate(result[i1]["repeat_last_task_id"], result[i1]["id"])
      }


    },

    async checkLastPickupDate(repeat_last_task_id, repeat_task_id) {
      // console.log("checkLastPickupDate")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: repeat_last_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      // console.log(result)

      if (result.length == 0) {
       // console.log("Fehler Auftrag Existiert nicht mehr: " + repeat_last_task_id)
        return false
      }

      if (result[0]["pickup_date"] != "" && result[0]["pickup_date"] != null) {

        // Aktualisiere derzeitige TASK ID
        param = {
          table: "task_repeat",
          statement: "update",
          type: "",
          where: "true",
          selector: "id",
          value: repeat_task_id,
          short: "true", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        data = {
          repeat_last_task_pickup_date: result[0]["pickup_date"],
        }

        result = await this.$func.queryAPI(param, data)
        console.log(result)

        if (result.length == 0) {
          return false
        }

        return true

      }


    },

    async checkRepeatTaskExecution() {
      console.log("checkRepeatTaskExecution")

      // Einstellungen
      var param = {
        table: "task_repeat",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE repeat_last_task_pickup_date IS NOT NULL AND repeat_last_task_pickup_date<>''",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if (result.length == 0) {
        return
      }


      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      var firstDate = new Date(2008, 1, 12);
      var secondDate = new Date(2008, 1, 22);
      var past_days


      for (var i1 = 0; i1 < result.length; i1++) {

        firstDate = new Date(this.$moment(result[i1]["repeat_last_task_pickup_date"], "DD.MM.YYYY").format("YYYY-MM-DD"));
        secondDate = new Date(Date.now());


        past_days = Math.round(Math.abs((firstDate - secondDate) / oneDay))


        console.log(result[i1]["repeat_last_task_pickup_date"])
        console.log(firstDate)
        console.log(secondDate)
        console.log("past_days: " + past_days)
        console.log("repeat_interval: " + result[i1]["repeat_interval"])


        if (past_days >= result[i1]["repeat_interval"]) {

          // Prüfen ob offener Auftrag bereits existiert
          if (await this.checkOpenTaskExist(result[i1]["customer_id"], result[i1]["id"]) == false) {
            // wenn false neuen Auftrag anlegen
            console.log("addNewTask")
            await this.addNewTask(result[i1]["id"])
          }else{
            console.log("NO addNewTask")
          }

        }


      }


    },

// Prüft ob bereits ein offner Task exisitiert
    async checkOpenTaskExist(customer_id, task_repeat_id) {
      console.log("checkOpenTaskExist")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE customer_id='" + customer_id + "' AND pickup_date IS NULL",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      // console.log(result)


      if (result.length == 0) {
        // console.log("false")
        return false
      } else {

        // TaskRepeat wird zurückgesetzt
        await this.resetRepeatTask(result[0]["id"], result[0]["date"], task_repeat_id)

        // console.log("true")
        return true
      }


    },

    async resetRepeatTask(task_id, task_date, task_repeat_id) {
      console.log("resetRepeatTask")

      // Einstellungen
      var param = {
        table: "task_repeat",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: task_repeat_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        repeat_last_task_id: task_id,
        repeat_last_task_pickup_date: null,
        repeat_last_task_date: task_date,
      }

      // await this.$func.queryAPI(param, data)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },


// Folgeauftrag wird angelegt
    async addNewTask(task_repeat_id) {
      console.log("addNewTask: " + task_repeat_id)

      // task_repeat auslesen
      var param1 = {
        table: "task_repeat",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: task_repeat_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)
      // console.log(result)

      if (result1.length == 0) {
        return false
      }


      var execution_date

      // Ausführungsfrist wird berechnet
      if (result1[0]["execution_period"] != "") {
        var dateObject = new Date(this.$moment(this.$moment(Date.now()).format('DD.MM.YYYY'), "DD.MM.YYYY").format('YYYY-MM-DD'))
        execution_date = this.$moment(this.addDays(dateObject, Number(result1[0]["execution_period"]))).format('DD.MM.YYYY')

        console.log("execution_date")
        console.log(execution_date)
      }

      var new_temp_task_id = await this.createNewTempTaskID(result1[0]["temp_task_id"])

      // Task anlegen
      var param2 = {
        table: "task",
        statement: "insert",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {

        customer_id: result1[0]["customer_id"],
        company: result1[0]["company"],
        street: result1[0]["street"],
        zipcode: result1[0]["zipcode"],
        city: result1[0]["city"],
        phone: result1[0]["phone"],
        email: result1[0]["email"],
        note: result1[0]["note"],

        onlysprinter: result1[0]["onlysprinter"],

        date: this.$moment(Date.now()).format('DD.MM.YYYY'),
        execution_period: result1[0]["execution_period"],
        execution_date: execution_date,

        date_timestamp: this.$moment(this.$moment(Date.now()).format('DD.MM.YYYY'), "DD.MM.YYYY").format('YYYY-MM-DD'),
        execution_date_timestamp: this.$moment(execution_date, "DD.MM.YYYY").format('YYYY-MM-DD'),

        rest_before: '0',

        document_no: result1[0]["document_no"],
        temp_task_id: new_temp_task_id,

        group_main_id: result1[0]["group_main_id"],
        group_main_text: result1[0]["group_main_text"],

        group_type_id: result1[0]["group_type_id"],
        group_type_text: result1[0]["group_type_text"],

        order_qty: result1[0]["order_qty"],
        article_name: result1[0]["article_name"],

        price_total: result1[0]["price_total"],
        weight_total: result1[0]["weight_total"],

        lat: result1[0]["lat"],
        lng: result1[0]["lng"],

        worker_add: "AUTO",
        worker_edit: "",

      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log("TASK anlegen!!!")
      console.log(result2)


      // Einstellungen
      var param3 = {
        table: "task_repeat",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: result1[0]["id"],
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data3 = {
        repeat_last_task_id: result2["value"],
        repeat_last_task_pickup_date: null,
        repeat_last_task_date: this.$moment(Date.now()).format('DD.MM.YYYY'),
      }

      await this.$func.queryAPI(param3, data3)

      return true
    },

    addDays(date, days) {
      var result = new Date(date);


      result.setDate(result.getDate() + days);
      return result;
    },


    async createNewTempTaskID(id) {
      console.log("createNewTempTaskID")

      // task_items
      var param1 = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {}

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log(result1)

      if (result1.length == 0) {
        return 0
      }

      this.task_items_old = []

      for (var i1 = 0; i1 < result1.length; i1++) {

        this.task_items_old.push({
          customer_id: result1[i1]["customer_id"],
          group_type_id: result1[i1]["group_type_id"],
          article_id: result1[i1]["article_id"],
          article_name: result1[i1]["article_name"],
          order_qty: result1[i1]["order_qty"],
          pickup_qty: result1[i1]["pickup_qty"],
          rating: result1[i1]["rating"],
          price: result1[i1]["price"],
          weight: result1[i1]["weight"],
          weight_total: result1[i1]["weight_total"],
          driver_id: result1[i1]["driver_id"],
          driver_name: result1[i1]["driver_name"],
          temp_task_id: result1[i1]["temp_task_id"],
          tour_id: result1[i1]["tour_id"],
        })

      }

      var new_temp_task_id = 0

      new_temp_task_id = await this.generateTempTaskID()
/*
      while(new_temp_task_id != 0){
        console.log("GEHT HIER REIN!")
        new_temp_task_id = await this.generateTempTaskID()
      }
*/


      for (var i2 = 0; i2 < this.task_items_old.length; i2++) {

        // task_items add
        var param2 = {
          table: "task_items",
          statement: "insert",
          type: "",
          where: "",
          selector: "",
          value: "",
          short: "false", // Übermittlung nur von id
          // filter: "false",
          // filterfield: "",
          // filtervalue: "",
          // operator: "true",
          // Filter noch hinzufügen
          extension: "",
        }

        // Daten -> Werden nur bei Insert oder Update gesendet
        var data2 = {
          customer_id: this.task_items_old[i2]["customer_id"],
          group_type_id: this.task_items_old[i2]["group_type_id"],
          article_id: this.task_items_old[i2]["article_id"],
          article_name: this.task_items_old[i2]["article_name"],
          order_qty: this.task_items_old[i2]["order_qty"],
          pickup_qty: "",
          rating: "",
          price: this.task_items_old[i2]["price"],
          weight: this.task_items_old[i2]["weight"],
          weight_total: this.task_items_old[i2]["weight_total"],
          driver_id: "",
          driver_name: "",
          temp_task_id: new_temp_task_id,
          tour_id: "",
        }

        var result2 = await this.$func.queryAPI(param2, data2)
        console.log("task_items add")
        console.log(result2)

      }

      return new_temp_task_id


    },


    // Search Free Temp Task ID
    async generateTempTaskID(){
      console.log("generateTempTaskID")

      var temp_task_id

      var min = 100000000000000000000
      var max = 999999999999999999999

      temp_task_id = (Math.round(Math.random() * (max - min)) + min).toString();

      // Einstellungen
      var param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: temp_task_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      if(result.length == "0"){
        return temp_task_id
      }else{
        return 0
      }

    },



    async checkTaskItems(){

    },

    async updateTasItems(){

    }












  }



}
</script>

<style scoped>

</style>
