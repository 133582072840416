<template>
  <div>

      <!--

      <v-select
          v-model="wasteno"
          :items="wasteno_list"
          outlined
          label="Abfallschlüssel"
          style="
          margin-left: 10px;
          width: 250px;"
      ></v-select>

      -->


    <v-row

    >

    <v-card

        style="
          width: 400px;
          margin: 20px;"
    >
      <v-card-title>Lagerbestand<br>zum Datum {{date}}</v-card-title>
      <v-card-text>

        <DialogDate
            v-bind:label="'Lagerbestand am'"
            v-bind:initial_date="date"
            v-bind:clearable="false"
            v-model="date"
            :key="date"
        ></DialogDate>
        <br>

        <!-- <h2><b></b> Tonnen (Gutreifen)</h2><br> -->

        <h2><b>{{total_weight_160103_good}}</b> Tonnen (Gutreifen/160103)</h2><br>
<br>
        <h2><b>{{total_weight_160103}}</b> Tonnen (Schrottreifen/160103)</h2><br>
        <h2><b>{{total_weight_191202}}</b> Tonnen (Stahl/191202)</h2><br>
        <h2><b>{{total_weight_191203}}</b> Tonnen (Alu/191203)</h2><br>

        <br>

        <v-btn
        outlined
        @click="csvexport()"
        >CSV EXPORT</v-btn>

      </v-card-text>
      <v-divider></v-divider>


      <v-card-title>KW Abfrage</v-card-title>
      <v-container
      style="margin-left: 30px; margin-top: 10px;"
      >

      <v-select
          v-model="ckw"
          style="width: 200px;"
          :items="kw"
          label="Kalenderwoche"
          outlined
      ></v-select>
      <v-select
          v-model="cyear"
          style="width: 120px;"
          :items="year"
          label="Jahr"
          outlined
      ></v-select>




<div
style="
width: 90%;
text-align: right"
>
  <v-btn
  outlined
  @click="getKWData"
  >Abfrage</v-btn>
  </div>

        <v-row

        >
  <v-container>
    <span v-html="kw_text"></span>
    <v-btn
        v-if="kw_sum != ''"
        class="mx-2"
        fab
        dark
        x-small
        color="pink"

    >
      <v-icon
         dark
         @click="createWochenreport()"
      >
        mdi-printer-outline
      </v-icon>
    </v-btn>

  </v-container>

    </v-row>

      </v-container>


    </v-card>

<!--
    <v-card
        class="mx-auto justify-center"
        width="650"
        elevation-10

        color="white"

    >

      <v-card-text>
        <v-sheet color="#e91e63">
          <v-sparkline
              :value="stock"
              color="rgba(255, 255, 255, .7)"
              height="250"
              padding="24"
              stroke-linecap="round"
              smooth
          >
            <template v-slot:label="item">
              {{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <div class="text-h5 font-weight-thin">
          Lagerbestand Verlauf
        </div>
      </v-card-text>



    </v-card>
-->


      <v-card

          style="
          width: 600px;
          margin: 20px;"
      >

        <v-card-title>Lagerbestand Verlauf<br>(⌀ pro Monat / letzte 12 Monate)</v-card-title>


        <cLine
        v-if="renderComponentLineChart"
        :chartData="chartData"

        >

        </cLine>

        <div
        style="margin: 30px;"
        ></div>

<!--
        <v-btn
            style="

        display: table;
        margin: 0 auto;
        width: 350px;"

            @click="calcSchrottanteil()"

        >
          Schrottanteil zuteilen
        </v-btn>
-->

        <v-btn
            style="

        display: table;
        margin: 0 auto;
        width: 350px;"

            @click="calcStockNew()"

        >
          Lagerbestand neu berechnen
        </v-btn>


        <div
            style="margin: 30px;"
        ></div>

      </v-card>

      <v-card
          style="
          background-color: rgba(245,243,243,0.46);
          width: 400px;
          height: 600px;
          margin: 20px;"
      >

        <v-card-title>Filter für Lagerbuchungen</v-card-title>

        <DialogDate
            v-bind:label="'Datum'"
            v-bind:initial_date="filter_date"
            v-bind:clearable="true"
            v-model="filter_date"
            :key="filter_date"
            style="margin-left: 30px;"
        ></DialogDate>


        <v-select
        label="Typ"
        v-model="filter_typ_selected"
        :items="filter_typ"
        item-text="state"
        item-value="abbr"

        style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >


        </v-select>



        <v-select
            label="Artikel"
            v-model="filter_article_selected"
            :items="filter_article"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >

        </v-select>


        <v-select
            label="Abfallschlüssel"
            v-model="filter_wasteno_selected"
            :items="wasteno_list"

            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        >

        </v-select>





        <v-btn
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

            @click="loadDataFilter"

        >
          Filtern
        </v-btn>
        <div
        style="margin: 10px;"
        ></div>

        <v-btn
            style="

        display: table;
        margin: 0 auto;
        width: 350px;"

            @click="filterReset"

        >
          Reset
        </v-btn>







      </v-card>


    </v-row>



<div
style="margin-left: 20px;"
><h2>Lagerbuchungen</h2></div>
    <List
        v-if="renderComponent"

        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"

        v-bind:default_limit="'700'"
        v-bind:default_sort="'date_timestamp'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:extension="extension"
        v-bind:export_csv="true"
        @reload="reloadTotalWeight"
    ></List>

    <v-overlay
        :value="overlay"
    >

      <v-progress-circular
          indeterminate
          color="black"
          style="margin: 20px;"
      ></v-progress-circular>


      Monatsdurchschnitt wird neu berechnet...


    </v-overlay>


  </div>
</template>

<script>

import cLine from '@/components/cLine'

import List from '@/components/ListDefault'
import DialogDate from "@/components/DialogDate";
import Vue from "vue";

import * as XLSX from 'xlsx';

export default {
  name: "ListStock",

  components: {
    cLine,
    List,
    DialogDate,
  },

  data () {
    return {

      stock: [
        {
          kw: '1', value: '200',
        },
        {
          kw: '2', value: '300',
        }
        ,
        {
          kw: '3', value: '400',
        }
        ,
        {
          kw: '4', value: '300',
        }
        ,
        {
          kw: '5', value: '350',
        }
        ,
        {
          kw: '6', value: '200',
        }
        ,
        {
          kw: '7', value: '600',
        }
        ,
        {
          kw: '8', value: '300',
        }
    ],

        kw: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
        ],

      year: [
        2020,
        2021,
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030,
      ],

      ckw: '',
      cyear: '',


      search: '',
      formview: 'FormStock',
      mainview: 'ListStock',
      table: 'stock',

      total_weight_160103_good: 0, // Gutreifen
      total_weight_160103: 0, // Schrottreifen
      total_weight_191202: 0, // Stahlfelgen
      total_weight_191203: 0, // Alufelgen
      total_weight: 0,
      total_qty: 0,
      average_weight: 0,

      overlay: false,


      filter_date: '',
      filter_select: { state: 'Alle', abbr: '' },

      filter_typ_selected: '',
      filter_article_selected: '',
      filter_wasteno_selected: '',



      filter_typ: [
        { state: 'Alle', abbr: '' },
        { state: 'Eingänge', abbr: 'buy' },
        { state: 'Verkäufe', abbr: 'sell' },
        { state: 'Entsorgungen', abbr: 'disposal' },
      ],

      filter_article: [],
      filter_wasteno: [],

      renderComponent: true,
      renderComponentLineChart: false,

      chartData: Object,

      extension: '',

      report_mo: '',
      report_di: '',
      report_mi: '',
      report_do: '',
      report_fr: '',
      report_sa: '',
      report_so: '',


      wasteno: '160103', // Default wasteno
      wasteno_list: [], // Alle Abfallnummern


      date: '',

      kw_total: '',
      kw_sum: '',
      kw_total_qty: '',

      kw_date_start: '',
      kw_date_end: '',

      kw_text: 'KW 1 / 2022: <b>0 Tonnen</b>',

    }
  },

  async created() {
    this.kw_text = ""
    this.date = this.$moment(Date.now()).format('DD.MM.YYYY')


    await this.getAllWasteNo()
    await this.getAllArticle()

    // Lager Statistik berechnen

    this.buildChart()

    // console.log(this.getISOWeekDates("20"))

    // Lese Parameter aus
    await this.loadData()
    await this.reloadTotalWeight()

    // Befülle KW 53
    this.kw = []
    let n = 1;
    while (n < 54) {
      this.kw.push(n)
      n++;
    }

    // Setze Aktuelles Jahr und KW
    this.cyear  = Number(this.$moment(Date.now()).format("YYYY"))
    this.ckw    = Number(this.$moment(Date.now()).isoWeek())

  },

  methods: {


    async csvexport(){

      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT date, REPLACE(REPLACE(REPLACE(typ, 'buy', 'EINGANG'), 'disposal', 'VERSCHROTTUNG'), 'sell', 'VERKAUF'), wasteno, REPLACE(ROUND(SUM(weight), 3), '.', ','), REPLACE(ROUND(SUM(scrap_quantity_weight), 3), '.' , ','), REPLACE(ROUND(SUM(good_quantity_weight), 3), '.' , ',')  FROM `stock` WHERE wasteno <> '' AND date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD") + "' GROUP BY date_timestamp, typ, wasteno ORDER BY date_timestamp ASC" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      // console.log(result)


      var ws = XLSX.utils.json_to_sheet(result)
      XLSX.utils.sheet_add_aoa(ws, [["DATUM", "TYP", "ABFALLSCHLÜSSEL", "GESAMTGEWICHT", "SCHROTTANTEIL", "GUTANTEIL"]], { origin: "A1" });

      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'data')
      XLSX.writeFile(wb,'export.xlsx')

    },



    // Ermittel alle Abfallnummern
    async getAllWasteNo(){

      console.log("getAllWasteNo")

      // Einstellungen
      var param = {
        table: "article",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT wasteno  FROM `article` GROUP BY wasteno"

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      for (var i1 = 0; i1 < result.length; i1++) {


        if(result[i1]["wasteno"] != "0"){
          this.wasteno_list.push(result[i1]["wasteno"])
        }


      }

      console.log(this.wasteno_list)


    },


    // Ermittel alle Abfallnummern
    async getAllArticle(){

      console.log("getAllArticle")

      // Einstellungen
      var param = {
        table: "article",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT name  FROM `article` GROUP BY name ORDER BY name ASC"

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      for (var i1 = 0; i1 < result.length; i1++) {
          this.filter_article.push(result[i1]["name"])
      }

      console.log(this.wasteno_list)

    },





    async buildChart(){

      // Einstellungen
      var param = {
        table: "stock_day",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT count(*), sum(saldo_scrap), month  FROM `stock_day` WHERE wasteno = '160103' AND  date_timestamp >= '" + this.$moment(Date.now()).subtract(11,'months').endOf('month').format("YYYY-MM-DD") + "' AND date_timestamp <= '" + this.$moment(Date.now()).format("YYYY-MM-DD") + "' GROUP BY month ORDER BY date_timestamp ASC" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage

      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == 0){
        return
      }



      // Einstellungen
      var param1 = {
        table: "stock_day",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT count(*), sum(saldo_scrap), month  FROM `stock_day` WHERE wasteno = '191202' AND  date_timestamp >= '" + this.$moment(Date.now()).subtract(11,'months').endOf('month').format("YYYY-MM-DD") + "' AND date_timestamp <= '" + this.$moment(Date.now()).format("YYYY-MM-DD") + "' GROUP BY month ORDER BY date_timestamp ASC" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log(result1)

      if(result1.length < 12){

        result1.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result1.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result1.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result1.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})

      }

      // Einstellungen
      var param2 = {
        table: "stock_day",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT count(*), sum(saldo_scrap), month  FROM `stock_day` WHERE wasteno = '191203' AND  date_timestamp >= '" + this.$moment(Date.now()).subtract(11,'months').endOf('month').format("YYYY-MM-DD") + "' AND date_timestamp <= '" + this.$moment(Date.now()).format("YYYY-MM-DD") + "' GROUP BY month ORDER BY date_timestamp ASC" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log(result2)


      if(result2.length < 12){

        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})
        result2.unshift({'count(*)': '0', 'sum(saldo_scrap)': '0', 'month': ''})


      }


      this.chartData = {
        labels: [
          result[0]["month"],
          result[1]["month"],
          result[2]["month"],
          result[3]["month"],
          result[4]["month"],
          result[5]["month"],
          result[6]["month"],
          result[7]["month"],
          result[8]["month"],
          result[9]["month"],
          result[10]["month"],

        ],
            datasets: [

          {
            label: 'Gutreifen',
            backgroundColor: '#17e132',
            data: []
          },

          {
                label: 'Schrottreifen',
                backgroundColor: '#fd0e3d',
                data: [result[0]["sum(saldo_scrap)"] / result[0]["count(*)"],result[1]["sum(saldo_scrap)"] / result[1]["count(*)"], result[2]["sum(saldo_scrap)"] / result[2]["count(*)"], result[3]["sum(saldo_scrap)"] / result[3]["count(*)"], result[4]["sum(saldo_scrap)"] / result[4]["count(*)"], result[5]["sum(saldo_scrap)"] / result[5]["count(*)"], result[6]["sum(saldo_scrap)"] / result[6]["count(*)"], result[7]["sum(saldo_scrap)"] / result[7]["count(*)"], result[8]["sum(saldo_scrap)"] / result[8]["count(*)"], result[9]["sum(saldo_scrap)"] / result[9]["count(*)"], result[10]["sum(saldo_scrap)"] / result[10]["count(*)"]]
          },

          {
                label: 'Stahl',
                backgroundColor: '#a16a21',
                data: [result1[0]["sum(saldo_scrap)"] / result1[0]["count(*)"],result1[1]["sum(saldo_scrap)"] / result1[1]["count(*)"], result1[2]["sum(saldo_scrap)"] / result1[2]["count(*)"], result1[3]["sum(saldo_scrap)"] / result1[3]["count(*)"], result1[4]["sum(saldo_scrap)"] / result1[4]["count(*)"], result1[5]["sum(saldo_scrap)"] / result1[5]["count(*)"], result1[6]["sum(saldo_scrap)"] / result1[6]["count(*)"], result1[7]["sum(saldo_scrap)"] / result1[7]["count(*)"], result1[8]["sum(saldo_scrap)"] / result1[8]["count(*)"], result1[9]["sum(saldo_scrap)"] / result1[9]["count(*)"], result1[10]["sum(saldo_scrap)"] / result1[10]["count(*)"]]
          },
          {
                label: 'Aluminium',
                backgroundColor: '#68686e',
                data: [result2[0]["sum(saldo_scrap)"] / result2[0]["count(*)"],result2[1]["sum(saldo_scrap)"] / result2[1]["count(*)"], result2[2]["sum(saldo_scrap)"] / result2[2]["count(*)"], result2[3]["sum(saldo_scrap)"] / result2[3]["count(*)"], result2[4]["sum(saldo_scrap)"] / result2[4]["count(*)"], result2[5]["sum(saldo_scrap)"] / result2[5]["count(*)"], result2[6]["sum(saldo_scrap)"] / result2[6]["count(*)"], result2[7]["sum(saldo_scrap)"] / result2[7]["count(*)"], result2[8]["sum(saldo_scrap)"] / result2[8]["count(*)"], result2[9]["sum(saldo_scrap)"] / result2[9]["count(*)"], result2[10]["sum(saldo_scrap)"] / result2[10]["count(*)"]]
          }

        ]
      }



      // Remove MyComponent from the DOM
      this.renderComponentLineChart = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponentLineChart = true;


    },


    async calcStockNew(){

      // Einstellungen
      var param = {
        table: "stock_day",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "DELETE FROM stock_day"
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }


      await this.$func.queryAPI(param, data)


      this.calcStock()

      this.overlay = true

      await this.buildChart()

      this.overlay = false


    },

    async calcStock(){

      // Einstellungen
      var param = {
        table: "stock",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT date, date_timestamp, wasteno, SUM(weight), SUM(scrap_quantity_weight) FROM `stock` WHERE wasteno <> '' AND date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(Date.now()).format("YYYY-MM-DD") + "' GROUP BY date_timestamp, wasteno ORDER BY date_timestamp ASC" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage

      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      var total = 0
      var total_scrap_160103 = 0
      var total_scrap_191202 = 0
      var total_scrap_191203 = 0
      var total_scrap = 0



      for (var i1 = 0; i1 < result.length; i1++) {


          if(Math.sign(result[i1]["SUM(weight)"]) == -1){
            total = Math.abs(total) - Math.abs(result[i1]["SUM(weight)"])
          }else{
            total = Math.abs(total) + Math.abs(result[i1]["SUM(weight)"])
          }


          if(result[i1]["SUM(scrap_quantity_weight)"] != ""){

          if(Math.sign(result[i1]["SUM(scrap_quantity_weight)"]) == -1){

            if(result[i1]["wasteno"] == "160103"){
              total_scrap_160103 = total_scrap_160103 - Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_160103
            }
            if(result[i1]["wasteno"] == "191202"){
              total_scrap_191202 = total_scrap_191202 - Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_191202
            }
            if(result[i1]["wasteno"] == "191203"){
              total_scrap_191203 = total_scrap_191203 - Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_191203
            }

          }else{
            if(result[i1]["wasteno"] == "160103"){
              total_scrap_160103 = total_scrap_160103 + Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_160103
            }
            if(result[i1]["wasteno"] == "191202"){
              total_scrap_191202 = total_scrap_191202 + Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_191202
            }
            if(result[i1]["wasteno"] == "191203"){
              total_scrap_191203 = total_scrap_191203 + Math.abs(result[i1]["SUM(scrap_quantity_weight)"])
              total_scrap = total_scrap_191203
            }
          }
          }


        await this.writeStockDate(result[i1]["date"], result[i1]["date_timestamp"], Number(result[i1]["SUM(weight)"]).toFixed(4), Number(total).toFixed(4), Number(total_scrap).toFixed(4), Number(result[i1]["SUM(scrap_quantity_weight)"]).toFixed(4), result[i1]["wasteno"])


      }




    },

    async writeStockDate(date, date_timestamp, saldo, total, saldo_scrap, total_scrap, wasteno){

      var month
      // this.translateMonth(this.$moment(date).subtract(2,'months').endOf('month').format('MMMM'))
      month = this.translateMonth(this.$moment(date, "DD.MM.YYYY").format('MMMM'))


      // Einstellungen
      var param = {
        table: "stock_day",    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "INSERT INTO stock_day (date, date_timestamp, month, saldo, total, saldo_scrap, total_scrap, wasteno) VALUES ('" + date + "', '" + date_timestamp + "', '" + month + "', '0', '0', '" + saldo_scrap +  "', '" + total_scrap +  "', '" + wasteno +  "') ON DUPLICATE KEY UPDATE date = '" + date + "', wasteno = '" + wasteno + "'",        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }


      if (wasteno == "191203"){
        console.log(param.extension)
      }


      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      await this.$func.queryAPI(param, data)

    },






    translateMonth(englishname){

      var output

      switch(englishname) {

        case "January":
          output = "Januar"
          break;

        case "February":
          output = "Februar"
          break;

        case "March":
          output = "März"
          break;

        case "April":
          output = "April"
          break;

        case "May":
          output = "Mai"
          break;

        case "June":
          output = "Juni"
          break;

        case "July":
          output = "Juli"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "October":
          output ="Oktober"
          break;

        case "November":
          output = "November"
          break;

        case "December":
          output = "Dezember"
          break;
      }


      return output



    },

    translateMonthEN(germanname){

      var output

      switch(germanname) {

        case "Januar":
          output = "January"
          break;

        case "Februar":
          output = "February"
          break;

        case "März":
          output = "March"
          break;

        case "April":
          output = "April"
          break;

        case "Mai":
          output = "May"
          break;

        case "Juni":
          output = "June"
          break;

        case "Juli":
          output = "July"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "Oktober":
          output ="October"
          break;

        case "November":
          output = "November"
          break;

        case "Dezember":
          output = "December"
          break;
      }


      return output



    },








    async getKWData(){
      console.log("getKWData")


      var days = this.getISOWeekDates(this.ckw, this.cyear)

      this.kw_date_start = this.$moment(days[0], "YYYY-MM-DD").format("DD.MM.YYYY")
      this.kw_date_end = this.$moment(days[6], "YYYY-MM-DD").format("DD.MM.YYYY")



      for (var i1 = 0; i1 < days.length; i1++) {
        console.log(this.$moment(days[i1], "YYYY-MM-DD").format("DD.MM.YYYY"))

        this.setReportDays(this.$moment(days[i1], "YYYY-MM-DD").format("DD.MM.YYYY"), i1)
      }


      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date_timestamp >= '" + days[0] + "' AND date_timestamp <= '" + days[6] + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // console.log(result[0]["SUM(weight)"])
      this.kw_sum = Number(result[0]["SUM(weight)"]).toFixed(3)
      // console.log(this.kw_sum)




      // Einstellungen
      param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date_timestamp >= '2009-01-01' AND date_timestamp <= '" + days[6] + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      // console.log(result[0]["SUM(weight)"])
      this.kw_total = Number(result[0]["SUM(weight)"]).toFixed(3)

      this.kw_total_qty = Math.round(((result[0]["SUM(weight)"] * 1000).toFixed(3) / this.average_weight)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")


      // 'KW 1 / 2022: <b>0 Tonnen</b>'

      this.kw_text = "KW " + this.ckw + " / " + this.cyear + "<br> " + this.kw_date_start + " - " + this.kw_date_end + " <br><b> Gesamt: " + Number(this.kw_total).toLocaleString("de-DE") + " Tonnen</b><br>ca. " + Number(this.kw_total_qty).toLocaleString("de-DE") + " Stück<br> Zugang/Abgang: " + this.kw_sum + " Tonnen"

    },

    async createWochenreport(){

      var param = {
        vorlage_name: "wochenreport.png",
        pdf_autor: "Hüska GmbH",
        pdf_name: "temp.pdf",
        pdf_title: "Wochenreport",
        pdf_subject: "",
      }

      var data = {


        text: {
          0: {
            text: this.ckw + " / " + this.cyear,
            x: "50",
            y: "42",
          },
          1: {
            text: this.kw_date_start + " - " + this.kw_date_end,
            x: "50",
            y: "55",
          },
          2: {
            text: this.kw_total + " t (ca. " + this.kw_total_qty + " Stück)" ,
            x: "90",
            y: "93",
          },
          3: {
            text: this.kw_sum + " t",
            x: "90",
            y: "108",
          },
          4: {
            text: this.report_mo,
            x: "25",
            y: "160",
          },
          5: {
            text: this.report_di,
            x: "25",
            y: "170",
          },
          6: {
              text: this.report_mi,
              x: "25",
              y: "180",
            },
          7: {
            text: this.report_do,
            x: "25",
            y: "190",
          },
          8: {
            text: this.report_fr,
            x: "25",
            y: "200",
          },
          9: {
            text: this.report_sa,
            x: "25",
            y: "210",
          },
          10: {
            text: this.report_so,
            x: "25",
            y: "220",
          },
          11: {
            text: "160103",
            x: "75",
            y: "70",
          },

        },


        image: {
          0: {
            name: "",
            x: "5",
            y: "5",
            w: "1",
            h: "1",
          },

        },




      }






      var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));
      console.log(Vue.prototype.$api_pdf_endpoint + "?" + request)

      const config = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }

      try {

        await Vue.prototype.$axios
            .get(Vue.prototype.$api_pdf_endpoint + "?" + request, config)
            .then(response => (this.responseText = response))

        // console.log(this.responseText)

        let blob = new Blob([this.responseText.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)

        window.open(url) // Mostly the same, I was just experimenting with differen

        // return this.responseText["data"]

      }catch{

        return {"status": "404"}

      }

    },

    async getChartData(){
      console.log("getChartData")


      var days = this.getISOWeekDates(this.ckw, this.cyear)

      this.kw_date_start = this.$moment(days[0], "YYYY-MM-DD").format("DD.MM.YYYY")
      this.kw_date_end = this.$moment(days[6], "YYYY-MM-DD").format("DD.MM.YYYY")

      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date_timestamp >= '" + days[0] + "' AND date_timestamp <= '" + days[6] + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      // console.log(result[0]["SUM(weight)"])
      this.kw_sum = Number(result[0]["SUM(weight)"]).toFixed(3)
      // console.log(this.kw_sum)


      // Einstellungen
      param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date_timestamp >= '2009-01-01' AND date_timestamp <= '" + days[6] + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      // console.log(result[0]["SUM(weight)"])
      this.kw_total = Number(result[0]["SUM(weight)"]).toFixed(3)

      this.kw_total_qty = Math.round(((result[0]["SUM(weight)"] * 1000).toFixed(3) * this.average_weight)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")


      // 'KW 1 / 2022: <b>0 Tonnen</b>'

      this.kw_text = "KW " + this.ckw + " / " + this.cyear + "<br> " + this.kw_date_start + " - " + this.kw_date_end + " <br><b> Gesamt: " + this.kw_total + " Tonnen</b><br> Zugang/Abgang: " + this.kw_sum + " Tonnen"

    },

    async calcTotalWeight(){
      console.log("calcTotalWeight")

      // console.log(result[0]["SUM(weight)"])
      // console.log(Number(result[0]["SUM(weight)"]).toFixed(3))
      // this.total_qty = Math.round(((result[0]["SUM(weight)"] * 1000).toFixed(0) / this.average_weight)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")

      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD") + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      this.total_weight = Number(result[0]["SUM(weight)"]).toFixed(3)



      // Einstellungen
      param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(scrap_quantity_weight) FROM `stock` WHERE wasteno='191203' AND date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD") + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      data = {
      }

      result = await this.$func.queryAPI(param, data)

      this.total_weight_191203 = Number(result[0]["SUM(scrap_quantity_weight)"]).toFixed(3)


      // Einstellungen
      param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight), SUM(scrap_quantity_weight), SUM(good_quantity_weight) FROM `stock` WHERE wasteno='160103' AND date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD") + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      data = {
      }

      result = await this.$func.queryAPI(param, data)

      this.total_weight_160103 = Number(result[0]["SUM(scrap_quantity_weight)"]).toFixed(3)
      this.total_weight_160103_good = Number(result[0]["SUM(good_quantity_weight)"]).toFixed(3)



      // Einstellungen
      param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(scrap_quantity_weight) FROM `stock` WHERE wasteno='191202' AND date_timestamp >= '2009-01-01' AND date_timestamp <= '" + this.$moment(this.date, "DD.MM.YYYY").format("YYYY-MM-DD") + "'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      data = {
      }

      result = await this.$func.queryAPI(param, data)

      this.total_weight_191202 = Number(result[0]["SUM(scrap_quantity_weight)"]).toFixed(3)


    },

    async setReportDays(day, i){

      var zugang = 0
      var abgang = 0
      var saldo = 0

      // Einstellungen
      var param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date= '" + day + "' AND typ = 'buy'" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)
      zugang = Number(result[0]["SUM(weight)"]).toFixed(3)





      // Einstellungen
       param = {
        table: this.table,    // Tabellen Name
        statement: "custom",  // SELECT, INSERT, UPDATE, DELETE, CUSTOM
        type: "detail",       // detail, list (Bei SELECT) --> "detail" erfordert id, listfilter
        where: "true",
        selector: "",         // bei listflter -> id,name,test
        value: "",
        short: "false",       // Übermittlung nur von id
        debug: "false",       // Debug Ausgabe bei Select Abfrage
        // filter: "false",   // Bei true wird ein Feld gefiltert
        // filterfield: "",
        // filtervalue: "",
        // operator: "AND",   // AND für filterlist LIKE abfrage ()
        // Filter noch hinzufügen
        extension: "SELECT SUM(weight) FROM `stock` WHERE date= '" + day + "' AND (typ = 'sell' OR typ = 'disposal')" ,        // Erweiterung am Ende des SQL Befehls z.B. ORDER BY Colum ASC - Wird am Ende der SQL Abfrage hinzugefügt, bei CUSTOM entspricht das der gesamten Abfrage
      }

      // console.log(param.extension)

      // datetime >= "01-01-2009 00:00:00" and datetime <= now()

      // Daten -> Werden nur bei Insert oder Update gesendet
       data = {
      }

       result = await this.$func.queryAPI(param, data)

       abgang = Number(result[0]["SUM(weight)"]).toFixed(3)




        saldo = (Number(zugang) - Math.abs(abgang))

        saldo = Number(saldo).toFixed(3)


      switch (i){

        case 0:
          this.report_mo = "Mo. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
        break;
        case 1:
          this.report_di = "Di. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;
        case 2:
          this.report_mi = "Mi. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;
        case 3:
          this.report_do = "Do. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;
        case 4:
          this.report_fr = "Fr. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;
        case 5:
          this.report_sa = "Sa. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;
        case 6:
          this.report_so = "So. " + day + "            " +zugang + "                        " + abgang + "                        " + saldo
          break;

      }


    },

    async reloadTotalWeight(){

      await this.calcTotalWeight()

    },

    async filterReset(){


      this.extension = ""
      this.filter_date = ""
      this.filter_wasteno_selected = ""
      this.filter_article_selected = ""
      this.filter_typ_selected = ""



      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;

    },

    async loadDataFilter(){

      // console.log("this.filter_date")
      // console.log(this.filter_date)

      if(this.filter_date == "" && this.filter_wasteno_selected == "" && this.filter_article_selected == "" && this.filter_typ_selected == ""){
        this.extension = ""
      }else{
        this.extension = "date LIKE '%" + this.filter_date + "%' AND typ LIKE '%" + this.filter_typ_selected + "%' AND article_name LIKE '%" + this.filter_article_selected + "%' AND wasteno LIKE '%" + this.filter_wasteno_selected + "%'"

      }



      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;



    },

    async loadData() {
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      if(result.lenght == 0){
        this.average_weight = ""
        return
      }

      this.average_weight = result[0]["wert"]

    },

    // Ermittelt die Tage einer Kalenderwoche Mo-So.
     getISOWeekDates(isoWeekNum = 1, year = new Date().getFullYear()) {
    let d = this.$moment(String(year).padStart(4, '0') + 'W' +
      String(isoWeekNum).padStart(2,'0'));
    for (var dates=[], i=0; i < 7; i++) {
    //dates.push(d.format('ddd DD MMM YYYY'));
    dates.push(d.format('YYYY-MM-DD'));
    d.add(1, 'day');
    }
      return dates;
    }


  },

  watch: {

    filter_select(){
      console.log("this.filter_select: " + this.filter_select)
    },



    date(){

      console.log("this.date: " + this.date)

      this.reloadTotalWeight()
    }

  },


  computed: {
    headers () {
      return [
        { text: 'Datum', align: 'start', sortable: true, value: 'date_timestamp' },
        { text: 'Zeit', align: 'start', sortable: true, value: 'time' },
        { text: 'Typ', value: 'typ' },
        { text: 'Name', value: 'name' },
        { text: 'Artikel', value: 'article_name' },
        { text: 'Abfallschl.', value: 'wasteno' },
        { text: 'Bemerkungen', value: 'note' },
        { text: 'Gewicht in T', value: 'weight' },
        { text: 'Schrottanteil in T', value: 'scrap_quantity_weight' },
        { text: 'Gutanteil in T', value: 'good_quantity_weight' },
        { text: 'Mitarbeiter', value: 'worker' },
       // { text: 'Saldo', value: 'saldo' },
        { text: '', value: 'edit' },
      ]
    },
  },


}
</script>

<style scoped>

</style>
